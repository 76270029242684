/*!
 * 
 * antd v4.24.14
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
[class^="ant-"]::-ms-clear {
  display: none;
}

[class*="ant-"]::-ms-clear {
  display: none;
}

[class^="ant-"] input::-ms-clear {
  display: none;
}

[class*="ant-"] input::-ms-clear {
  display: none;
}

[class^="ant-"] input::-ms-reveal {
  display: none;
}

[class*="ant-"] input::-ms-reveal {
  display: none;
}

html, body {
  width: 100%;
  height: 100%;
}

:is(input::-ms-clear, input::-ms-reveal) {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
  line-height: 1.5715;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  color: #000000d9;
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1890ff;
  cursor: pointer;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  outline: none;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: #40a9ff;
}

a:active {
  color: #096dd9;
}

a:active, a:hover, a:focus {
  outline: 0;
  text-decoration: none;
}

a[disabled] {
  color: #00000040;
  cursor: not-allowed;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  color: #00000073;
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

:is([type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration) {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

::selection {
  color: #fff;
  background: #1890ff;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.anticon {
  color: inherit;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
  font-style: normal;
  line-height: 0;
  display: inline-flex;
}

.anticon > * {
  line-height: 1;
}

.anticon svg {
  display: inline-block;
}

.anticon:before {
  display: none;
}

.anticon .anticon-icon {
  display: block;
}

.anticon > .anticon {
  vertical-align: 0;
  line-height: 0;
}

.anticon[tabindex] {
  cursor: pointer;
}

.anticon-spin, .anticon-spin:before {
  animation: 1s linear infinite loadingCircle;
  display: inline-block;
}

.ant-fade-enter, .ant-fade-appear, .ant-fade-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-fade-enter.ant-fade-enter-active, .ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}

.ant-fade-leave.ant-fade-leave-active {
  pointer-events: none;
  animation-name: antFadeOut;
  animation-play-state: running;
}

.ant-fade-enter, .ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}

.ant-fade-leave {
  animation-timing-function: linear;
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ant-move-up-enter, .ant-move-up-appear, .ant-move-up-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-up-enter.ant-move-up-enter-active, .ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}

.ant-move-up-leave.ant-move-up-leave-active {
  pointer-events: none;
  animation-name: antMoveUpOut;
  animation-play-state: running;
}

.ant-move-up-enter, .ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-up-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

.ant-move-down-enter, .ant-move-down-appear, .ant-move-down-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-down-enter.ant-move-down-enter-active, .ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}

.ant-move-down-leave.ant-move-down-leave-active {
  pointer-events: none;
  animation-name: antMoveDownOut;
  animation-play-state: running;
}

.ant-move-down-enter, .ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-down-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

.ant-move-left-enter, .ant-move-left-appear, .ant-move-left-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-left-enter.ant-move-left-enter-active, .ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}

.ant-move-left-leave.ant-move-left-leave-active {
  pointer-events: none;
  animation-name: antMoveLeftOut;
  animation-play-state: running;
}

.ant-move-left-enter, .ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-left-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

.ant-move-right-enter, .ant-move-right-appear, .ant-move-right-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-move-right-enter.ant-move-right-enter-active, .ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}

.ant-move-right-leave.ant-move-right-leave-active {
  pointer-events: none;
  animation-name: antMoveRightOut;
  animation-play-state: running;
}

.ant-move-right-enter, .ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
}

.ant-move-right-leave {
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
}

@keyframes antMoveDownIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes antMoveDownOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes antMoveLeftIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes antMoveLeftOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes antMoveRightIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes antMoveRightOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes antMoveUpIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes antMoveUpOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

[ant-click-animating="true"], [ant-click-animating-without-extra-node="true"] {
  position: relative;
}

html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}

[ant-click-animating-without-extra-node="true"]:after, .ant-click-animating-node {
  border-radius: inherit;
  box-shadow: 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: .2;
  content: "";
  pointer-events: none;
  animation-name: fadeEffect, waveEffect;
  animation-duration: 2s, .4s;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1), cubic-bezier(.08, .82, .17, 1);
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: 0s, 0s;
  animation-fill-mode: forwards;
  display: block;
  position: absolute;
  inset: 0;
}

@keyframes waveEffect {
  100% {
    box-shadow: 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

.ant-slide-up-enter, .ant-slide-up-appear, .ant-slide-up-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-up-enter.ant-slide-up-enter-active, .ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}

.ant-slide-up-leave.ant-slide-up-leave-active {
  pointer-events: none;
  animation-name: antSlideUpOut;
  animation-play-state: running;
}

.ant-slide-up-enter, .ant-slide-up-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

.ant-slide-down-enter, .ant-slide-down-appear, .ant-slide-down-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-down-enter.ant-slide-down-enter-active, .ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}

.ant-slide-down-leave.ant-slide-down-leave-active {
  pointer-events: none;
  animation-name: antSlideDownOut;
  animation-play-state: running;
}

.ant-slide-down-enter, .ant-slide-down-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

.ant-slide-left-enter, .ant-slide-left-appear, .ant-slide-left-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-left-enter.ant-slide-left-enter-active, .ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}

.ant-slide-left-leave.ant-slide-left-leave-active {
  pointer-events: none;
  animation-name: antSlideLeftOut;
  animation-play-state: running;
}

.ant-slide-left-enter, .ant-slide-left-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

.ant-slide-right-enter, .ant-slide-right-appear, .ant-slide-right-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-slide-right-enter.ant-slide-right-enter-active, .ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}

.ant-slide-right-leave.ant-slide-right-leave-active {
  pointer-events: none;
  animation-name: antSlideRightOut;
  animation-play-state: running;
}

.ant-slide-right-enter, .ant-slide-right-appear {
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  transform: scale(0);
}

.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(.755, .05, .855, .06);
}

@keyframes antSlideUpIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleY(.8);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes antSlideUpOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleY(.8);
  }
}

@keyframes antSlideDownIn {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: scaleY(.8);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes antSlideDownOut {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: scaleY(.8);
  }
}

@keyframes antSlideLeftIn {
  0% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleX(.8);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes antSlideLeftOut {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 0 0;
    opacity: 0;
    transform: scaleX(.8);
  }
}

@keyframes antSlideRightIn {
  0% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: scaleX(.8);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes antSlideRightOut {
  0% {
    transform-origin: 100% 0;
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    transform-origin: 100% 0;
    opacity: 0;
    transform: scaleX(.8);
  }
}

.ant-zoom-enter, .ant-zoom-appear, .ant-zoom-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-enter.ant-zoom-enter-active, .ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}

.ant-zoom-leave.ant-zoom-leave-active {
  pointer-events: none;
  animation-name: antZoomOut;
  animation-play-state: running;
}

.ant-zoom-enter, .ant-zoom-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-enter-prepare, .ant-zoom-appear-prepare {
  transform: none;
}

.ant-zoom-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-big-enter, .ant-zoom-big-appear, .ant-zoom-big-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-big-enter.ant-zoom-big-enter-active, .ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}

.ant-zoom-big-leave.ant-zoom-big-leave-active {
  pointer-events: none;
  animation-name: antZoomBigOut;
  animation-play-state: running;
}

.ant-zoom-big-enter, .ant-zoom-big-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-big-enter-prepare, .ant-zoom-big-appear-prepare {
  transform: none;
}

.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-big-fast-enter, .ant-zoom-big-fast-appear, .ant-zoom-big-fast-leave {
  animation-duration: .1s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active, .ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}

.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  pointer-events: none;
  animation-name: antZoomBigOut;
  animation-play-state: running;
}

.ant-zoom-big-fast-enter, .ant-zoom-big-fast-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-big-fast-enter-prepare, .ant-zoom-big-fast-appear-prepare {
  transform: none;
}

.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-up-enter, .ant-zoom-up-appear, .ant-zoom-up-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-up-enter.ant-zoom-up-enter-active, .ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}

.ant-zoom-up-leave.ant-zoom-up-leave-active {
  pointer-events: none;
  animation-name: antZoomUpOut;
  animation-play-state: running;
}

.ant-zoom-up-enter, .ant-zoom-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-up-enter-prepare, .ant-zoom-up-appear-prepare {
  transform: none;
}

.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-down-enter, .ant-zoom-down-appear, .ant-zoom-down-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-down-enter.ant-zoom-down-enter-active, .ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}

.ant-zoom-down-leave.ant-zoom-down-leave-active {
  pointer-events: none;
  animation-name: antZoomDownOut;
  animation-play-state: running;
}

.ant-zoom-down-enter, .ant-zoom-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-down-enter-prepare, .ant-zoom-down-appear-prepare {
  transform: none;
}

.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-left-enter, .ant-zoom-left-appear, .ant-zoom-left-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-left-enter.ant-zoom-left-enter-active, .ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}

.ant-zoom-left-leave.ant-zoom-left-leave-active {
  pointer-events: none;
  animation-name: antZoomLeftOut;
  animation-play-state: running;
}

.ant-zoom-left-enter, .ant-zoom-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-left-enter-prepare, .ant-zoom-left-appear-prepare {
  transform: none;
}

.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

.ant-zoom-right-enter, .ant-zoom-right-appear, .ant-zoom-right-leave {
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-zoom-right-enter.ant-zoom-right-enter-active, .ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}

.ant-zoom-right-leave.ant-zoom-right-leave-active {
  pointer-events: none;
  animation-name: antZoomRightOut;
  animation-play-state: running;
}

.ant-zoom-right-enter, .ant-zoom-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  transform: scale(0);
}

.ant-zoom-right-enter-prepare, .ant-zoom-right-appear-prepare {
  transform: none;
}

.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
}

@keyframes antZoomIn {
  0% {
    opacity: 0;
    transform: scale(.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.2);
  }
}

@keyframes antZoomBigIn {
  0% {
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomUpIn {
  0% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 50% 0;
    transform: scale(1);
  }
}

@keyframes antZoomUpOut {
  0% {
    transform-origin: 50% 0;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 0;
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomLeftIn {
  0% {
    transform-origin: 0%;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 0%;
    transform: scale(1);
  }
}

@keyframes antZoomLeftOut {
  0% {
    transform-origin: 0%;
    transform: scale(1);
  }

  100% {
    transform-origin: 0%;
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomRightIn {
  0% {
    transform-origin: 100%;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 100%;
    transform: scale(1);
  }
}

@keyframes antZoomRightOut {
  0% {
    transform-origin: 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 100%;
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes antZoomDownIn {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: scale(.8);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes antZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }

  100% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: scale(.8);
  }
}

.ant-motion-collapse-legacy {
  overflow: hidden;
}

.ant-motion-collapse-legacy-active {
  transition: height .2s cubic-bezier(.645, .045, .355, 1), opacity .2s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-motion-collapse {
  overflow: hidden;
  transition: height .2s cubic-bezier(.645, .045, .355, 1), opacity .2s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-affix {
  z-index: 10;
  position: fixed;
}

.ant-alert {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  word-wrap: break-word;
  border-radius: 2px;
  align-items: center;
  margin: 0;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
  position: relative;
}

.ant-alert-content {
  flex: 1;
  min-width: 0;
}

.ant-alert-icon {
  margin-right: 8px;
}

.ant-alert-description {
  font-size: 14px;
  line-height: 22px;
  display: none;
}

.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}

.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}

.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}

.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}

.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}

.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}

.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}

.ant-alert-action {
  margin-left: 8px;
}

.ant-alert-close-icon {
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  margin-left: 8px;
  padding: 0;
  font-size: 12px;
  line-height: 12px;
  overflow: hidden;
}

.ant-alert-close-icon .anticon-close {
  color: #00000073;
  transition: color .3s;
}

.ant-alert-close-icon .anticon-close:hover {
  color: #000000bf;
}

.ant-alert-close-text {
  color: #00000073;
  transition: color .3s;
}

.ant-alert-close-text:hover {
  color: #000000bf;
}

.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}

.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}

.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}

.ant-alert-with-description .ant-alert-message {
  color: #000000d9;
  margin-bottom: 4px;
  font-size: 16px;
  display: block;
}

.ant-alert-message {
  color: #000000d9;
}

.ant-alert-with-description .ant-alert-description {
  display: block;
}

.ant-alert.ant-alert-motion-leave {
  opacity: 1;
  transition: max-height .3s cubic-bezier(.78, .14, .15, .86), opacity .3s cubic-bezier(.78, .14, .15, .86), padding-top .3s cubic-bezier(.78, .14, .15, .86), padding-bottom .3s cubic-bezier(.78, .14, .15, .86), margin-bottom .3s cubic-bezier(.78, .14, .15, .86);
  overflow: hidden;
}

.ant-alert.ant-alert-motion-leave-active {
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0 !important;
}

.ant-alert-banner {
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
}

.ant-alert.ant-alert-rtl {
  direction: rtl;
}

.ant-alert-rtl .ant-alert-icon {
  margin-left: 8px;
  margin-right: auto;
}

.ant-alert-rtl .ant-alert-action, .ant-alert-rtl .ant-alert-close-icon {
  margin-left: auto;
  margin-right: 8px;
}

.ant-alert-rtl.ant-alert-with-description {
  padding-left: 15px;
  padding-right: 24px;
}

.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-left: 15px;
  margin-right: auto;
}

.ant-anchor {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0 0 0 2px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-anchor-wrapper {
  background-color: #0000;
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
}

.ant-anchor-ink {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-anchor-ink:before {
  content: " ";
  background-color: #f0f0f0;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.ant-anchor-ink-ball {
  background-color: #fff;
  border: 2px solid #1890ff;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  transition: top .3s ease-in-out;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ant-anchor-ink-ball.ant-anchor-ink-ball-visible {
  display: inline-block;
}

.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}

.ant-anchor-link {
  padding: 4px 0 4px 16px;
}

.ant-anchor-link-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  transition: all .3s;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}

.ant-anchor-link-active > .ant-anchor-link-title {
  color: #1890ff;
}

.ant-anchor-link .ant-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ant-anchor-rtl {
  direction: rtl;
}

.ant-anchor-rtl.ant-anchor-wrapper {
  margin-left: 0;
  margin-right: -4px;
  padding-left: 0;
  padding-right: 4px;
}

.ant-anchor-rtl .ant-anchor-ink {
  left: auto;
  right: 0;
}

.ant-anchor-rtl .ant-anchor-ink-ball {
  left: 0;
  right: 50%;
  transform: translateX(50%);
}

.ant-anchor-rtl .ant-anchor-link {
  padding: 4px 16px 4px 0;
}

.ant-select-auto-complete {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}

.ant-select-single .ant-select-selector {
  display: flex;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  inset: 0 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all .3s, visibility;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none;
  transition: none;
}

.ant-select-single .ant-select-selector:after, .ant-select-single .ant-select-selector .ant-select-selection-item:after, .ant-select-single .ant-select-selector .ant-select-selection-placeholder:after {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
  line-height: 30px;
}

.ant-select-single.ant-select-customize-input .ant-select-selector:after {
  display: none;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  width: 100%;
  position: static;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  padding: 0 11px;
  position: absolute;
  left: 0;
  right: 0;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder:after {
  display: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector:after, .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  left: 7px;
  right: 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}

.ant-select-selection-overflow {
  flex-wrap: wrap;
  flex: auto;
  max-width: 100%;
  display: flex;
  position: relative;
}

.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}

.ant-select-multiple .ant-select-selector {
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  display: flex;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector {
  cursor: not-allowed;
  background: #f5f5f5;
}

.ant-select-multiple .ant-select-selector:after {
  visibility: hidden;
  content: " ";
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  display: inline-block;
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}

.ant-select-multiple .ant-select-selection-item {
  box-sizing: border-box;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 2px;
  margin-bottom: 2px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  flex: none;
  max-width: 100%;
  height: 24px;
  margin-inline-end: 4px;
  padding-inline: 8px 4px;
  line-height: 22px;
  transition: font-size .3s, line-height .3s, height .3s;
  display: flex;
  position: relative;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.ant-select-multiple .ant-select-selection-item-content {
  white-space: pre;
  text-overflow: ellipsis;
  margin-right: 4px;
  display: inline-block;
  overflow: hidden;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: #00000073;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  line-height: 0;
  line-height: inherit;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
}

.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}

.ant-select-multiple .ant-select-selection-item-remove:before {
  display: none;
}

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: #000000bf;
}

.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}

.ant-select-multiple .ant-select-selection-search {
  max-width: 100%;
  margin-inline-start: 7px;
  position: relative;
}

.ant-select-multiple .ant-select-selection-search-input, .ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 24px;
  transition: all .3s;
}

.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}

.ant-select-multiple .ant-select-selection-search-mirror {
  z-index: 999;
  white-space: pre;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-select-multiple .ant-select-selection-placeholder {
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 11px;
  right: 11px;
  transform: translateY(-50%);
}

.ant-select-multiple.ant-select-lg .ant-select-selector:after {
  line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input, .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}

.ant-select-multiple.ant-select-sm .ant-select-selector:after {
  line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input, .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px;
}

.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector, .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector, .ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-select-status-error.ant-select-has-feedback .ant-select-clear, .ant-select-status-warning.ant-select-has-feedback .ant-select-clear, .ant-select-status-success.ant-select-has-feedback .ant-select-clear, .ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}

.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value, .ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value, .ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value, .ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}

.ant-select {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
  position: relative;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  position: relative;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-select-selection-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  font-weight: normal;
  overflow: hidden;
}

@media (-ms-high-contrast: none) {
  .ant-select-selection-item {
    flex: auto;
  }

  .ant-select-selection-item ::-ms-backdrop {
    flex: auto;
  }
}

.ant-select-selection-placeholder {
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  flex: 1;
  overflow: hidden;
}

@media (-ms-high-contrast: none) {
  .ant-select-selection-placeholder {
    flex: auto;
  }

  .ant-select-selection-placeholder ::-ms-backdrop {
    flex: auto;
  }
}

.ant-select-arrow {
  color: #00000040;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  pointer-events: none;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 50%;
  right: 11px;
}

.ant-select-arrow > * {
  line-height: 1;
}

.ant-select-arrow svg {
  display: inline-block;
}

.ant-select-arrow:before {
  display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}

.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform .3s;
}

.ant-select-arrow .anticon > svg {
  vertical-align: top;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}

.ant-select-arrow > :not(:last-child) {
  margin-inline-end: 8px;
}

.ant-select-clear {
  z-index: 1;
  color: #00000040;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  opacity: 0;
  text-rendering: auto;
  background: #fff;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  transition: color .3s, opacity .15s;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 11px;
}

.ant-select-clear:before {
  display: block;
}

.ant-select-clear:hover {
  color: #00000073;
}

.ant-select:hover .ant-select-clear {
  opacity: 1;
}

.ant-select-dropdown {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  box-sizing: border-box;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  margin: 0;
  padding: 4px 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft, .ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft, .ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-empty {
  color: #00000040;
}

.ant-select-item-empty {
  color: #00000040;
  min-height: 32px;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  display: block;
  position: relative;
}

.ant-select-item {
  color: #000000d9;
  cursor: pointer;
  min-height: 32px;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transition: background .3s;
  display: block;
  position: relative;
}

.ant-select-item-group {
  color: #00000073;
  cursor: default;
  font-size: 12px;
}

.ant-select-item-option {
  display: flex;
}

.ant-select-item-option-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  overflow: hidden;
}

.ant-select-item-option-state {
  flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #000000d9;
  background-color: #e6f7ff;
  font-weight: 600;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #1890ff;
}

.ant-select-item-option-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}

.ant-select-item-option-grouped {
  padding-left: 24px;
}

.ant-select-lg {
  font-size: 16px;
}

.ant-select-borderless .ant-select-selector {
  box-shadow: none !important;
  background-color: #0000 !important;
  border-color: #0000 !important;
}

.ant-select.ant-select-in-form-item {
  width: 100%;
}

.ant-select-compact-item:not(.ant-select-compact-last-item) {
  margin-right: -1px;
}

.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
  margin-left: -1px;
  margin-right: 0;
}

.ant-select-compact-item:hover > *, .ant-select-compact-item:focus > *, .ant-select-compact-item:active > *, .ant-select-compact-item.ant-select-focused > * {
  z-index: 2;
}

.ant-select-compact-item[disabled] > * {
  z-index: 0;
}

.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
  border-radius: 0;
}

.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-first-item):not(.ant-select-compact-item-rtl) > .ant-select-selector, .ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl:not(.ant-select-compact-last-item) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl:not(.ant-select-compact-first-item) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-select-rtl {
  direction: rtl;
}

.ant-select-rtl .ant-select-arrow, .ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}

.ant-select-dropdown-rtl {
  direction: rtl;
}

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-left: 12px;
  padding-right: 24px;
}

.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-left: 24px;
  padding-right: 4px;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  text-align: right;
  margin-left: 4px;
  margin-right: 0;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  left: auto;
  right: 0;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  left: auto;
  right: 11px;
}

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  text-align: right;
  left: 9px;
  right: 0;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  left: 25px;
  right: 11px;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-left: 18px;
  padding-right: 0;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item, .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-left: 21px;
  padding-right: 0;
}

.ant-empty {
  text-align: center;
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}

.ant-empty-image img {
  height: 100%;
}

.ant-empty-image svg {
  height: 100%;
  margin: auto;
}

.ant-empty-footer {
  margin-top: 16px;
}

.ant-empty-normal {
  color: #00000040;
  margin: 32px 0;
}

.ant-empty-normal .ant-empty-image {
  height: 40px;
}

.ant-empty-small {
  color: #00000040;
  margin: 8px 0;
}

.ant-empty-small .ant-empty-image {
  height: 35px;
}

.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: .8;
}

.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}

.ant-empty-img-default-path-2 {
  fill: url("#linearGradient-1");
}

.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}

.ant-empty-img-default-path-4, .ant-empty-img-default-path-5 {
  fill: #dce0e6;
}

.ant-empty-img-default-g {
  fill: #fff;
}

.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}

.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
  fill: #fafafa;
}

.ant-empty-rtl {
  direction: rtl;
}

.ant-avatar {
  box-sizing: border-box;
  color: #fff;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 32px;
  list-style: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-avatar-image {
  background: none;
}

.ant-avatar .ant-image-img {
  display: block;
}

.ant-avatar-string {
  transform-origin: 0;
  position: absolute;
  left: 50%;
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}

.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-lg {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-avatar-lg-string {
  transform-origin: 0;
  position: absolute;
  left: 50%;
}

.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}

.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-sm {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-avatar-sm-string {
  transform-origin: 0;
  position: absolute;
  left: 50%;
}

.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}

.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-square {
  border-radius: 2px;
}

.ant-avatar > img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.ant-avatar-group {
  display: inline-flex;
}

.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}

.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}

.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-left: 0;
  margin-right: -8px;
}

.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-left: 0;
  margin-right: 3px;
}

.ant-popover {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1030;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  user-select: text;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-popover-content {
  position: relative;
}

.ant-popover:after {
  content: "";
  background: #ffffff03;
  position: absolute;
}

.ant-popover-hidden {
  display: none;
}

.ant-popover-placement-top, .ant-popover-placement-topLeft, .ant-popover-placement-topRight {
  padding-bottom: 15.3137px;
}

.ant-popover-placement-right, .ant-popover-placement-rightTop, .ant-popover-placement-rightBottom {
  padding-left: 15.3137px;
}

.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
  padding-top: 15.3137px;
}

.ant-popover-placement-left, .ant-popover-placement-leftTop, .ant-popover-placement-leftBottom {
  padding-right: 15.3137px;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  }
}

.ant-popover-title {
  color: #000000d9;
  border-bottom: 1px solid #f0f0f0;
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  font-weight: 500;
}

.ant-popover-inner-content {
  color: #000000d9;
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
}

.ant-popover-message {
  color: #000000d9;
  padding: 4px 0 12px;
  font-size: 14px;
  display: flex;
}

.ant-popover-message-icon {
  color: #faad14;
  margin-right: 8px;
  font-size: 14px;
  display: inline-block;
}

.ant-popover-buttons {
  text-align: right;
  margin-bottom: 4px;
}

.ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}

.ant-popover-arrow {
  pointer-events: none;
  background: none;
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  overflow: hidden;
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  content: "";
  pointer-events: auto;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0;
}

.ant-popover-arrow-content:before {
  background: var(--antd-arrow-background-color);
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background-position: -10px -10px;
  background-repeat: no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-popover-placement-top .ant-popover-arrow, .ant-popover-placement-topLeft .ant-popover-arrow, .ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}

.ant-popover-placement-top .ant-popover-arrow-content, .ant-popover-placement-topLeft .ant-popover-arrow-content, .ant-popover-placement-topRight .ant-popover-arrow-content {
  transform: translateY(-11px)rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%)translateX(-50%);
}

.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-right .ant-popover-arrow, .ant-popover-placement-rightTop .ant-popover-arrow, .ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}

.ant-popover-placement-right .ant-popover-arrow-content, .ant-popover-placement-rightTop .ant-popover-arrow-content, .ant-popover-placement-rightBottom .ant-popover-arrow-content {
  transform: translateX(11px)rotate(135deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%)translateY(-50%);
}

.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-placement-bottom .ant-popover-arrow, .ant-popover-placement-bottomLeft .ant-popover-arrow, .ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}

.ant-popover-placement-bottom .ant-popover-arrow-content, .ant-popover-placement-bottomLeft .ant-popover-arrow-content, .ant-popover-placement-bottomRight .ant-popover-arrow-content {
  transform: translateY(11px)rotate(-135deg);
  box-shadow: 2px 2px 5px #0000000f;
}

.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%)translateX(-50%);
}

.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-left .ant-popover-arrow, .ant-popover-placement-leftTop .ant-popover-arrow, .ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}

.ant-popover-placement-left .ant-popover-arrow-content, .ant-popover-placement-leftTop .ant-popover-arrow-content, .ant-popover-placement-leftBottom .ant-popover-arrow-content {
  transform: translateX(-11px)rotate(-45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%)translateY(-50%);
}

.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-pink .ant-popover-inner, .ant-popover-pink .ant-popover-arrow-content, .ant-popover-magenta .ant-popover-inner, .ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}

.ant-popover-red .ant-popover-inner, .ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}

.ant-popover-volcano .ant-popover-inner, .ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}

.ant-popover-orange .ant-popover-inner, .ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}

.ant-popover-yellow .ant-popover-inner, .ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}

.ant-popover-gold .ant-popover-inner, .ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}

.ant-popover-cyan .ant-popover-inner, .ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}

.ant-popover-lime .ant-popover-inner, .ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}

.ant-popover-green .ant-popover-inner, .ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}

.ant-popover-blue .ant-popover-inner, .ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}

.ant-popover-geekblue .ant-popover-inner, .ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}

.ant-popover-purple .ant-popover-inner, .ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}

.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}

.ant-popover-rtl .ant-popover-message-icon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-popover-rtl .ant-popover-message-title {
  padding-left: 16px;
}

.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}

.ant-popover-rtl .ant-popover-buttons button {
  margin-left: 0;
  margin-right: 8px;
}

.ant-back-top {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 10;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: fixed;
  bottom: 50px;
  right: 100px;
}

.ant-back-top:empty {
  display: none;
}

.ant-back-top-rtl {
  direction: rtl;
  left: 100px;
  right: auto;
}

.ant-back-top-content {
  color: #fff;
  text-align: center;
  background-color: #00000073;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  transition: all .3s;
  overflow: hidden;
}

.ant-back-top-content:hover {
  background-color: #000000d9;
  transition: all .3s;
}

.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}

@media screen and (width <= 768px) {
  .ant-back-top {
    right: 60px;
  }

  .ant-back-top-rtl {
    left: 60px;
    right: auto;
  }
}

@media screen and (width <= 480px) {
  .ant-back-top {
    right: 20px;
  }

  .ant-back-top-rtl {
    left: 20px;
    right: auto;
  }
}

.ant-badge {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  display: inline-block;
  position: relative;
}

.ant-badge-count {
  z-index: auto;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  box-shadow: 0 0 0 1px #fff;
}

.ant-badge-count a, .ant-badge-count a:hover {
  color: #fff;
}

.ant-badge-count-sm {
  border-radius: 7px;
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
}

.ant-badge-multiple-words {
  padding: 0 8px;
}

.ant-badge-dot {
  z-index: auto;
  background: #ff4d4f;
  border-radius: 100%;
  width: 6px;
  min-width: 6px;
  height: 6px;
  box-shadow: 0 0 0 1px #fff;
}

.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}

.ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component {
  transform-origin: 100% 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.ant-badge-count.anticon-spin, .ant-badge-dot.anticon-spin, .ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: 1s linear infinite antBadgeLoadingCircle;
}

.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}

.ant-badge-status-dot {
  vertical-align: middle;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  display: inline-block;
  position: relative;
  top: -1px;
}

.ant-badge-status-success {
  background-color: #52c41a;
}

.ant-badge-status-processing {
  background-color: #1890ff;
  position: relative;
}

.ant-badge-status-processing:after {
  content: "";
  border: 1px solid #1890ff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 1.2s ease-in-out infinite antStatusProcessing;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-badge-status-default {
  background-color: #d9d9d9;
}

.ant-badge-status-error {
  background-color: #ff4d4f;
}

.ant-badge-status-warning {
  background-color: #faad14;
}

.ant-badge-status-pink, .ant-badge-status-magenta {
  background: #eb2f96;
}

.ant-badge-status-red {
  background: #f5222d;
}

.ant-badge-status-volcano {
  background: #fa541c;
}

.ant-badge-status-orange {
  background: #fa8c16;
}

.ant-badge-status-yellow {
  background: #fadb14;
}

.ant-badge-status-gold {
  background: #faad14;
}

.ant-badge-status-cyan {
  background: #13c2c2;
}

.ant-badge-status-lime {
  background: #a0d911;
}

.ant-badge-status-green {
  background: #52c41a;
}

.ant-badge-status-blue {
  background: #1890ff;
}

.ant-badge-status-geekblue {
  background: #2f54eb;
}

.ant-badge-status-purple {
  background: #722ed1;
}

.ant-badge-status-text {
  color: #000000d9;
  margin-left: 8px;
  font-size: 14px;
}

.ant-badge-zoom-appear, .ant-badge-zoom-enter {
  animation: .3s cubic-bezier(.12, .4, .29, 1.46) both antZoomBadgeIn;
}

.ant-badge-zoom-leave {
  animation: .3s cubic-bezier(.71, -.46, .88, .6) both antZoomBadgeOut;
}

.ant-badge-not-a-wrapper .ant-badge-zoom-appear, .ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: .3s cubic-bezier(.12, .4, .29, 1.46) antNoWrapperZoomBadgeIn;
}

.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: .3s cubic-bezier(.71, -.46, .88, .6) antNoWrapperZoomBadgeOut;
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component, .ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component, .ant-badge-not-a-wrapper .ant-scroll-number {
  transform-origin: 50%;
  display: block;
  position: relative;
  top: auto;
}

@keyframes antStatusProcessing {
  0% {
    opacity: .5;
    transform: scale(.8);
  }

  100% {
    opacity: 0;
    transform: scale(2.4);
  }
}

.ant-scroll-number {
  direction: ltr;
  overflow: hidden;
}

.ant-scroll-number-only {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  height: 20px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  height: 20px;
  margin: 0;
}

.ant-scroll-number-symbol {
  vertical-align: top;
}

@keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    transform: scale(0)translate(50%, -50%);
  }

  100% {
    transform: scale(1)translate(50%, -50%);
  }
}

@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1)translate(50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(0)translate(50%, -50%);
  }
}

@keyframes antNoWrapperZoomBadgeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }

  100% {
    transform-origin: 50%;
    transform: translate(50%, -50%)rotate(360deg);
  }
}

.ant-ribbon-wrapper {
  position: relative;
}

.ant-ribbon {
  box-sizing: border-box;
  color: #fff;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  background-color: #1890ff;
  border-radius: 2px;
  height: 22px;
  margin: 0;
  padding: 0 8px;
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  position: absolute;
  top: 8px;
}

.ant-ribbon-text {
  color: #fff;
}

.ant-ribbon-corner {
  color: currentColor;
  transform-origin: top;
  border: 4px solid;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 100%;
  transform: scaleY(.75);
}

.ant-ribbon-corner:after {
  width: inherit;
  height: inherit;
  color: #00000040;
  border: inherit;
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
}

.ant-ribbon-color-pink, .ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}

.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}

.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}

.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}

.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}

.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}

.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}

.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}

.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}

.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}

.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}

.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}

.ant-ribbon.ant-ribbon-placement-end {
  border-bottom-right-radius: 0;
  right: -8px;
}

.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  border-color: currentColor #0000 #0000 currentColor;
  right: 0;
}

.ant-ribbon.ant-ribbon-placement-start {
  border-bottom-left-radius: 0;
  left: -8px;
}

.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  border-color: currentColor currentColor #0000 #0000;
  left: 0;
}

.ant-badge-rtl {
  direction: rtl;
}

.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count, .ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot, .ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  direction: ltr;
  transform-origin: 0 0;
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}

.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  transform-origin: 0 0;
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}

.ant-badge-rtl .ant-badge-status-text {
  margin-left: 0;
  margin-right: 8px;
}

.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-appear, .ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}

.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}

.ant-ribbon-rtl {
  direction: rtl;
}

.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
  left: -8px;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  border-color: currentColor currentColor #0000 #0000;
  left: 0;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner:after {
  border-color: currentColor currentColor #0000 #0000;
}

.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor #0000 #0000 currentColor;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner:after {
  border-color: currentColor #0000 #0000 currentColor;
}

@keyframes antZoomBadgeInRtl {
  0% {
    opacity: 0;
    transform: scale(0)translate(-50%, -50%);
  }

  100% {
    transform: scale(1)translate(-50%, -50%);
  }
}

@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1)translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(0)translate(-50%, -50%);
  }
}

.ant-breadcrumb {
  box-sizing: border-box;
  color: #00000073;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-breadcrumb .anticon {
  font-size: 14px;
}

.ant-breadcrumb ol {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.ant-breadcrumb a {
  color: #00000073;
  transition: color .3s;
}

.ant-breadcrumb a:hover, .ant-breadcrumb li:last-child, .ant-breadcrumb li:last-child a {
  color: #000000d9;
}

li:last-child > .ant-breadcrumb-separator {
  display: none;
}

.ant-breadcrumb-separator {
  color: #00000073;
  margin: 0 8px;
}

.ant-breadcrumb-link > .anticon + span, .ant-breadcrumb-link > .anticon + a, .ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}

.ant-breadcrumb-rtl {
  direction: rtl;
}

.ant-breadcrumb-rtl:before {
  content: "";
  display: table;
}

.ant-breadcrumb-rtl:after {
  clear: both;
  content: "";
  display: table;
}

.ant-breadcrumb-rtl > span {
  float: right;
}

.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span, .ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a, .ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-left: 0;
  margin-right: 4px;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: block;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.ant-dropdown:before {
  z-index: -9999;
  opacity: .0001;
  content: " ";
  position: absolute;
  inset: -4px 0 -4px -7px;
}

.ant-dropdown-wrap {
  position: relative;
}

.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}

.ant-dropdown-wrap .anticon-down:before {
  transition: transform .2s;
}

.ant-dropdown-wrap-open .anticon-down:before {
  transform: rotate(180deg);
}

.ant-dropdown-hidden, .ant-dropdown-menu-hidden, .ant-dropdown-menu-submenu-hidden {
  display: none;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft, .ant-dropdown-show-arrow.ant-dropdown-placement-top, .ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137px;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft, .ant-dropdown-show-arrow.ant-dropdown-placement-bottom, .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137px;
}

.ant-dropdown-arrow {
  z-index: 1;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  display: block;
  position: absolute;
}

.ant-dropdown-arrow:before {
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background: #fff -10px -10px no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-dropdown-placement-top > .ant-dropdown-arrow, .ant-dropdown-placement-topLeft > .ant-dropdown-arrow, .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  transform: rotate(45deg);
  box-shadow: 3px 3px 7px -3px #0000001a;
}

.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%)rotate(45deg);
}

.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-placement-bottom > .ant-dropdown-arrow, .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow, .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421px;
  transform: rotate(-135deg)translateY(-.5px);
  box-shadow: 2px 2px 5px -2px #0000001a;
}

.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%)rotate(-135deg)translateY(-.5px);
}

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-menu {
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  margin: 0;
  padding: 4px 0;
  list-style-type: none;
  position: relative;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-dropdown-menu-item-group-title {
  color: #00000073;
  padding: 5px 12px;
  transition: all .3s;
}

.ant-dropdown-menu-submenu-popup {
  z-index: 1050;
  box-shadow: none;
  transform-origin: 0 0;
  background: none;
  position: absolute;
}

.ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li {
  list-style: none;
}

.ant-dropdown-menu-submenu-popup ul {
  margin-left: .3em;
  margin-right: .3em;
}

.ant-dropdown-menu-item {
  align-items: center;
  display: flex;
  position: relative;
}

.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}

.ant-dropdown-menu-title-content {
  flex: auto;
}

.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all .3s;
}

.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}

.ant-dropdown-menu-title-content > a:after {
  content: "";
  position: absolute;
  inset: 0;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  clear: both;
  color: #000000d9;
  cursor: pointer;
  margin: 0;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transition: all .3s;
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
  color: #1890ff;
  background-color: #e6f7ff;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled:hover, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled:hover, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled:hover {
  color: #00000040;
  cursor: not-allowed;
  background-color: #fff;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled a, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled a, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled a, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}

.ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
  background-color: #f0f0f0;
  height: 1px;
  margin: 4px 0;
  line-height: 0;
  overflow: hidden;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon, .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon, .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  color: #00000073;
  font-size: 10px;
  font-style: normal;
  margin-right: 0 !important;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}

.ant-dropdown-menu-submenu-vertical {
  position: relative;
}

.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  transform-origin: 0 0;
  min-width: 100%;
  margin-left: 4px;
  position: absolute;
  top: 0;
  left: 100%;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #00000040;
  cursor: not-allowed;
  background-color: #fff;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}

.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft, .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft, .ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom, .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom, .ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight, .ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}

.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft, .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft, .ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top, .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top, .ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight, .ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}

.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft, .ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom, .ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}

.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft, .ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top, .ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}

.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
  vertical-align: baseline;
  font-size: 10px;
}

.ant-dropdown-button {
  white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group > .ant-btn-loading, .ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}

.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn:before {
  display: block;
}

.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-dropdown-menu-dark, .ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item, .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-dark .ant-dropdown-menu-item > a, .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a, .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after, .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after, .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow:after, .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow:after {
  color: #ffffffa6;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: none;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected, .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover, .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}

.ant-dropdown-rtl {
  direction: rtl;
}

.ant-dropdown-rtl.ant-dropdown:before {
  left: 0;
  right: -7px;
}

.ant-dropdown-menu.ant-dropdown-menu-rtl, .ant-dropdown-rtl .ant-dropdown-menu-item-group-title, .ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}

.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li, .ant-dropdown-rtl .ant-dropdown-menu-item, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child, .ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-left: 8px;
  margin-right: 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  left: 8px;
  right: auto;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon, .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  transform: scaleX(-1);
  margin-left: 0 !important;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-left: 24px;
  padding-right: 12px;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  margin-left: 0;
  margin-right: 4px;
  left: 0;
  right: 100%;
}

.ant-btn {
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  color: #000000d9;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
  position: relative;
  box-shadow: 0 2px #00000004;
}

.ant-btn > .anticon {
  line-height: 1;
}

.ant-btn, .ant-btn:active, .ant-btn:focus {
  outline: 0;
}

.ant-btn:not([disabled]):hover {
  text-decoration: none;
}

.ant-btn:not([disabled]):active {
  box-shadow: none;
  outline: 0;
}

.ant-btn[disabled] {
  cursor: not-allowed;
}

.ant-btn[disabled] > * {
  pointer-events: none;
}

.ant-btn-lg {
  border-radius: 2px;
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
}

.ant-btn-sm {
  border-radius: 2px;
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
}

.ant-btn > a:only-child {
  color: currentColor;
}

.ant-btn > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn:hover, .ant-btn:focus {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.ant-btn:hover > a:only-child, .ant-btn:focus > a:only-child {
  color: currentColor;
}

.ant-btn:hover > a:only-child:after, .ant-btn:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9;
}

.ant-btn:active > a:only-child {
  color: currentColor;
}

.ant-btn:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn[disabled] > a:only-child, .ant-btn[disabled]:hover > a:only-child, .ant-btn[disabled]:focus > a:only-child, .ant-btn[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn[disabled] > a:only-child:after, .ant-btn[disabled]:hover > a:only-child:after, .ant-btn[disabled]:focus > a:only-child:after, .ant-btn[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  background: #fff;
  text-decoration: none;
}

.ant-btn > span {
  display: inline-block;
}

.ant-btn-primary {
  color: #fff;
  text-shadow: 0 -1px #0000001f;
  background: #1890ff;
  border-color: #1890ff;
  box-shadow: 0 2px #0000000b;
}

.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-primary > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn-primary:hover > a:only-child, .ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-primary:hover > a:only-child:after, .ant-btn-primary:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-primary:active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}

.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-primary[disabled] > a:only-child, .ant-btn-primary[disabled]:hover > a:only-child, .ant-btn-primary[disabled]:focus > a:only-child, .ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary[disabled] > a:only-child:after, .ant-btn-primary[disabled]:hover > a:only-child:after, .ant-btn-primary[disabled]:focus > a:only-child:after, .ant-btn-primary[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-left-color: #40a9ff;
  border-right-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled], .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}

.ant-btn-ghost {
  color: #000000d9;
  background: none;
  border-color: #d9d9d9;
}

.ant-btn-ghost > a:only-child {
  color: currentColor;
}

.ant-btn-ghost > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-ghost:hover, .ant-btn-ghost:focus {
  color: #40a9ff;
  background: none;
  border-color: #40a9ff;
}

.ant-btn-ghost:hover > a:only-child, .ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:hover > a:only-child:after, .ant-btn-ghost:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-ghost:active {
  color: #096dd9;
  background: none;
  border-color: #096dd9;
}

.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-ghost[disabled], .ant-btn-ghost[disabled]:hover, .ant-btn-ghost[disabled]:focus, .ant-btn-ghost[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-ghost[disabled] > a:only-child, .ant-btn-ghost[disabled]:hover > a:only-child, .ant-btn-ghost[disabled]:focus > a:only-child, .ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost[disabled] > a:only-child:after, .ant-btn-ghost[disabled]:hover > a:only-child:after, .ant-btn-ghost[disabled]:focus > a:only-child:after, .ant-btn-ghost[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed {
  color: #000000d9;
  background: #fff;
  border-style: dashed;
  border-color: #d9d9d9;
}

.ant-btn-dashed > a:only-child {
  color: currentColor;
}

.ant-btn-dashed > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.ant-btn-dashed:hover > a:only-child, .ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:hover > a:only-child:after, .ant-btn-dashed:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed:active {
  color: #096dd9;
  background: #fff;
  border-color: #096dd9;
}

.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dashed[disabled], .ant-btn-dashed[disabled]:hover, .ant-btn-dashed[disabled]:focus, .ant-btn-dashed[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-dashed[disabled] > a:only-child, .ant-btn-dashed[disabled]:hover > a:only-child, .ant-btn-dashed[disabled]:focus > a:only-child, .ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed[disabled] > a:only-child:after, .ant-btn-dashed[disabled]:hover > a:only-child:after, .ant-btn-dashed[disabled]:focus > a:only-child:after, .ant-btn-dashed[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger {
  color: #fff;
  text-shadow: 0 -1px #0000001f;
  background: #ff4d4f;
  border-color: #ff4d4f;
  box-shadow: 0 2px #0000000b;
}

.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-danger > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger:hover, .ant-btn-danger:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-danger:hover > a:only-child, .ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-danger:hover > a:only-child:after, .ant-btn-danger:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-danger[disabled], .ant-btn-danger[disabled]:hover, .ant-btn-danger[disabled]:focus, .ant-btn-danger[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-danger[disabled] > a:only-child, .ant-btn-danger[disabled]:hover > a:only-child, .ant-btn-danger[disabled]:focus > a:only-child, .ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger[disabled] > a:only-child:after, .ant-btn-danger[disabled]:hover > a:only-child:after, .ant-btn-danger[disabled]:focus > a:only-child:after, .ant-btn-danger[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link {
  color: #1890ff;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-link > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link:hover, .ant-btn-link:focus {
  color: #40a9ff;
  background: none;
  border-color: #40a9ff;
}

.ant-btn-link:hover > a:only-child, .ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-link:hover > a:only-child:after, .ant-btn-link:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link:active {
  color: #096dd9;
  background: none;
  border-color: #096dd9;
}

.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-link:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-link:hover {
  background: none;
}

.ant-btn-link:hover, .ant-btn-link:focus, .ant-btn-link:active {
  border-color: #0000;
}

.ant-btn-link[disabled], .ant-btn-link[disabled]:hover, .ant-btn-link[disabled]:focus, .ant-btn-link[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-link[disabled] > a:only-child, .ant-btn-link[disabled]:hover > a:only-child, .ant-btn-link[disabled]:focus > a:only-child, .ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-link[disabled] > a:only-child:after, .ant-btn-link[disabled]:hover > a:only-child:after, .ant-btn-link[disabled]:focus > a:only-child:after, .ant-btn-link[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text {
  color: #000000d9;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-text > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text:hover > a:only-child, .ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-text:hover > a:only-child:after, .ant-btn-text:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-text:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-text:hover, .ant-btn-text:focus {
  color: #000000d9;
  background: #00000005;
  border-color: #0000;
}

.ant-btn-text:active {
  color: #000000d9;
  background: #00000007;
  border-color: #0000;
}

.ant-btn-text[disabled], .ant-btn-text[disabled]:hover, .ant-btn-text[disabled]:focus, .ant-btn-text[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-text[disabled] > a:only-child, .ant-btn-text[disabled]:hover > a:only-child, .ant-btn-text[disabled]:focus > a:only-child, .ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-text[disabled] > a:only-child:after, .ant-btn-text[disabled]:hover > a:only-child:after, .ant-btn-text[disabled]:focus > a:only-child:after, .ant-btn-text[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}

.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff7875;
  background: #fff;
  border-color: #ff7875;
}

.ant-btn-dangerous:hover > a:only-child, .ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:hover > a:only-child:after, .ant-btn-dangerous:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous:active {
  color: #d9363e;
  background: #fff;
  border-color: #d9363e;
}

.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous[disabled], .ant-btn-dangerous[disabled]:hover, .ant-btn-dangerous[disabled]:focus, .ant-btn-dangerous[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-dangerous[disabled] > a:only-child, .ant-btn-dangerous[disabled]:hover > a:only-child, .ant-btn-dangerous[disabled]:focus > a:only-child, .ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous[disabled] > a:only-child:after, .ant-btn-dangerous[disabled]:hover > a:only-child:after, .ant-btn-dangerous[disabled]:focus > a:only-child:after, .ant-btn-dangerous[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  text-shadow: 0 -1px #0000001f;
  background: #ff4d4f;
  border-color: #ff4d4f;
  box-shadow: 0 2px #0000000b;
}

.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary:hover, .ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child, .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-primary:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-primary[disabled], .ant-btn-dangerous.ant-btn-primary[disabled]:hover, .ant-btn-dangerous.ant-btn-primary[disabled]:focus, .ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child, .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child, .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child, .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child:after, .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child:after, .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link:hover, .ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child, .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-link:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-link[disabled], .ant-btn-dangerous.ant-btn-link[disabled]:hover, .ant-btn-dangerous.ant-btn-link[disabled]:focus, .ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child, .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child, .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child, .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child:after, .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child:after, .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text:hover, .ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  background: #00000005;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child, .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-text:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  background: #00000007;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-dangerous.ant-btn-text[disabled], .ant-btn-dangerous.ant-btn-text[disabled]:hover, .ant-btn-dangerous.ant-btn-text[disabled]:focus, .ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child, .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child, .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child, .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child:after, .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child:after, .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child:after, .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-icon-only {
  vertical-align: -3px;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
}

.ant-btn-icon-only > * {
  font-size: 16px;
}

.ant-btn-icon-only.ant-btn-lg {
  border-radius: 2px;
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-sm {
  border-radius: 2px;
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
}

.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}

.ant-btn-icon-only > .anticon {
  justify-content: center;
  display: flex;
}

.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}

a.ant-btn-icon-only {
  vertical-align: -1px;
}

a.ant-btn-icon-only > .anticon {
  display: inline;
}

.ant-btn-round {
  border-radius: 32px;
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
}

.ant-btn-round.ant-btn-lg {
  border-radius: 40px;
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
}

.ant-btn-round.ant-btn-sm {
  border-radius: 24px;
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
}

.ant-btn-round.ant-btn-icon-only {
  width: auto;
}

.ant-btn-circle {
  text-align: center;
  border-radius: 50%;
  min-width: 32px;
  padding-left: 0;
  padding-right: 0;
}

.ant-btn-circle.ant-btn-lg {
  border-radius: 50%;
  min-width: 40px;
}

.ant-btn-circle.ant-btn-sm {
  border-radius: 50%;
  min-width: 24px;
}

.ant-btn:before {
  z-index: 1;
  border-radius: inherit;
  opacity: .35;
  content: "";
  pointer-events: none;
  background: #fff;
  transition: opacity .2s;
  display: none;
  position: absolute;
  inset: -1px;
}

.ant-btn .anticon {
  transition: margin-left .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-btn .anticon.anticon-plus > svg, .ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}

.ant-btn.ant-btn-loading {
  cursor: default;
  position: relative;
}

.ant-btn.ant-btn-loading:before {
  display: block;
}

.ant-btn > .ant-btn-loading-icon {
  transition: width .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}

.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: 1s linear infinite loadingCircle;
}

.ant-btn-group {
  display: inline-flex;
  position: relative;
}

.ant-btn-group > .ant-btn, .ant-btn-group > span > .ant-btn {
  position: relative;
}

.ant-btn-group > .ant-btn:hover, .ant-btn-group > span > .ant-btn:hover, .ant-btn-group > .ant-btn:focus, .ant-btn-group > span > .ant-btn:focus, .ant-btn-group > .ant-btn:active, .ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}

.ant-btn-group > .ant-btn[disabled], .ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}

.ant-btn-group .ant-btn + .ant-btn, .ant-btn + .ant-btn-group, .ant-btn-group span + .ant-btn, .ant-btn-group .ant-btn + span, .ant-btn-group > span + span, .ant-btn-group + .ant-btn, .ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: #0000;
}

.ant-btn-group .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn:first-child, .ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}

.ant-btn-group > .ant-btn:only-child, .ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:only-child, .ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:first-child:not(:last-child), .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:last-child:not(:first-child), .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group > .ant-btn-group {
  float: left;
}

.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 8px;
}

.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 8px;
}

.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn, .ant-btn-rtl.ant-btn + .ant-btn-group, .ant-btn-rtl.ant-btn-group span + .ant-btn, .ant-btn-rtl.ant-btn-group .ant-btn + span, .ant-btn-rtl.ant-btn-group > span + span, .ant-btn-rtl.ant-btn-group + .ant-btn, .ant-btn-rtl.ant-btn-group + .ant-btn-group, .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn, .ant-btn-group-rtl.ant-btn + .ant-btn-group, .ant-btn-group-rtl.ant-btn-group span + .ant-btn, .ant-btn-group-rtl.ant-btn-group .ant-btn + span, .ant-btn-group-rtl.ant-btn-group > span + span, .ant-btn-group-rtl.ant-btn-group + .ant-btn, .ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-left: auto;
  margin-right: -1px;
}

.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child), .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child), .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}

.ant-btn:focus > span, .ant-btn:active > span {
  position: relative;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 8px;
}

.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}

.ant-btn.ant-btn-background-ghost, .ant-btn.ant-btn-background-ghost:hover, .ant-btn.ant-btn-background-ghost:active, .ant-btn.ant-btn-background-ghost:focus {
  background: none;
}

.ant-btn.ant-btn-background-ghost:hover, .ant-btn.ant-btn-background-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn.ant-btn-background-ghost:active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-btn.ant-btn-background-ghost[disabled] {
  color: #00000040;
  background: none;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  text-shadow: none;
  border-color: #1890ff;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child, .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-primary[disabled], .ant-btn-background-ghost.ant-btn-primary[disabled]:hover, .ant-btn-background-ghost.ant-btn-primary[disabled]:focus, .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  text-shadow: none;
  border-color: #ff4d4f;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger:hover, .ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child, .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-danger[disabled], .ant-btn-background-ghost.ant-btn-danger[disabled]:hover, .ant-btn-background-ghost.ant-btn-danger[disabled]:focus, .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  text-shadow: none;
  border-color: #ff4d4f;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover, .ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled], .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  text-shadow: none;
  border-color: #0000;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: #0000;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: #0000;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled], .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child:after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

.ant-btn-two-chinese-chars:first-letter {
  letter-spacing: .34em;
}

.ant-btn-two-chinese-chars > :not(.anticon) {
  letter-spacing: .34em;
  margin-right: -.34em;
}

.ant-btn.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

a.ant-btn {
  line-height: 30px;
  padding-top: .01px !important;
}

a.ant-btn-disabled {
  cursor: not-allowed;
}

a.ant-btn-disabled > * {
  pointer-events: none;
}

a.ant-btn-disabled, a.ant-btn-disabled:hover, a.ant-btn-disabled:focus, a.ant-btn-disabled:active {
  color: #00000040;
  text-shadow: none;
  box-shadow: none;
  background: none;
  border-color: #0000;
}

a.ant-btn-disabled > a:only-child, a.ant-btn-disabled:hover > a:only-child, a.ant-btn-disabled:focus > a:only-child, a.ant-btn-disabled:active > a:only-child {
  color: currentColor;
}

a.ant-btn-disabled > a:only-child:after, a.ant-btn-disabled:hover > a:only-child:after, a.ant-btn-disabled:focus > a:only-child:after, a.ant-btn-disabled:active > a:only-child:after {
  content: "";
  background: none;
  position: absolute;
  inset: 0;
}

a.ant-btn-lg {
  line-height: 38px;
}

a.ant-btn-sm {
  line-height: 22px;
}

.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: -1px;
}

.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
  margin-left: -1px;
}

.ant-btn-compact-item:hover, .ant-btn-compact-item:focus, .ant-btn-compact-item:active {
  z-index: 2;
}

.ant-btn-compact-item[disabled] {
  z-index: 0;
}

.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
  border-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl), .ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]) {
  position: relative;
}

.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]):after {
  content: " ";
  background-color: #40a9ff;
  width: 1px;
  height: calc(100% + 2px);
  display: inline-block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]) + .ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]):after {
  right: -1px;
}

.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}

.ant-btn-compact-vertical-item:hover, .ant-btn-compact-vertical-item:focus, .ant-btn-compact-vertical-item:active {
  z-index: 2;
}

.ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}

.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item:not(.ant-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item:not(.ant-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
  position: relative;
}

.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]):after {
  content: " ";
  background-color: #40a9ff;
  width: calc(100% + 2px);
  height: 1px;
  display: inline-block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.ant-btn-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child), .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #d9d9d9;
  border-right-color: #40a9ff;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled], .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #40a9ff;
  border-right-color: #d9d9d9;
}

.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-left: 8px;
  padding-right: 0;
}

.ant-btn-rtl.ant-btn > .anticon + span, .ant-btn-rtl.ant-btn > span + .anticon {
  margin-left: 0;
  margin-right: 8px;
}

.ant-menu-item-danger.ant-menu-item, .ant-menu-item-danger.ant-menu-item:hover, .ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}

.ant-menu-item-danger.ant-menu-item-selected, .ant-menu-item-danger.ant-menu-item-selected > a, .ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item:after {
  border-right-color: #ff4d4f;
}

.ant-menu-dark .ant-menu-item-danger.ant-menu-item, .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover, .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-menu {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  text-align: left;
  background: #fff;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 0;
  list-style: none;
  transition: background .3s, width .3s cubic-bezier(.2, 0, 0, 1);
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-menu:before {
  content: "";
  display: table;
}

.ant-menu:after {
  clear: both;
  content: "";
  display: table;
}

.ant-menu ul, .ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-menu-overflow {
  display: flex;
}

.ant-menu-overflow-item {
  flex: none;
}

.ant-menu-hidden, .ant-menu-submenu-hidden {
  display: none;
}

.ant-menu-item-group-title {
  color: #00000073;
  height: 1.5715px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
}

.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color .3s cubic-bezier(.645, .045, .355, 1), background .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-submenu, .ant-menu-submenu-inline {
  transition: border-color .3s cubic-bezier(.645, .045, .355, 1), background .3s cubic-bezier(.645, .045, .355, 1), padding .15s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-submenu-selected {
  color: #1890ff;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: #e6f7ff;
}

.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background .3s cubic-bezier(.645, .045, .355, 1), padding .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-title-content {
  transition: color .3s;
}

.ant-menu-item a {
  color: #000000d9;
}

.ant-menu-item a:hover {
  color: #1890ff;
}

.ant-menu-item a:before {
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.ant-menu-item > .ant-badge a {
  color: #000000d9;
}

.ant-menu-item > .ant-badge a:hover {
  color: #1890ff;
}

.ant-menu-item-divider {
  border: 0 solid #f0f0f0;
  border-top-width: 1px;
  line-height: 0;
  overflow: hidden;
}

.ant-menu-item-divider-dashed {
  border-style: dashed;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: #0000;
}

.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: #1890ff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}

.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}

.ant-menu-vertical.ant-menu-sub, .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub {
  border-right: 0;
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
}

.ant-menu-vertical.ant-menu-sub:not([class*="-active"]), .ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]), .ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]) {
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item, .ant-menu-vertical-left.ant-menu-sub .ant-menu-item, .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item:after, .ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after, .ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after {
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item, .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item, .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item, .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu, .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu, .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}

.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}

.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color .3s, background .3s;
}

.ant-menu-item, .ant-menu-submenu-title {
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
  padding: 0 20px;
  transition: border-color .3s, background .3s, padding .3s cubic-bezier(.645, .045, .355, 1);
  display: block;
  position: relative;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size .15s cubic-bezier(.215, .61, .355, 1), margin .3s cubic-bezier(.645, .045, .355, 1), color .3s;
}

.ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
  opacity: 1;
  margin-left: 10px;
  transition: opacity .3s cubic-bezier(.645, .045, .355, 1), margin .3s, color .3s;
}

.ant-menu-item .ant-menu-item-icon.svg, .ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -.125em;
}

.ant-menu-item.ant-menu-item-only-child > .anticon, .ant-menu-submenu-title.ant-menu-item-only-child > .anticon, .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon, .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}

.ant-menu-item:not(.ant-menu-item-disabled):focus-visible, .ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #bae7ff;
}

.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}

.ant-menu-submenu-popup {
  z-index: 1050;
  box-shadow: none;
  transform-origin: 0 0;
  background: none;
  border-radius: 2px;
  position: absolute;
}

.ant-menu-submenu-popup:before {
  z-index: -1;
  opacity: .0001;
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  inset: -7px 0 0;
}

.ant-menu-submenu-placement-rightTop:before {
  top: 0;
  left: -7px;
}

.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}

.ant-menu-submenu > .ant-menu-submenu-title:after {
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
  color: #000000d9;
  width: 10px;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after {
  content: "";
  background-color: currentColor;
  border-radius: 2px;
  width: 6px;
  height: 1.5px;
  transition: background .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1), color .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
}

.ant-menu-submenu-arrow:before {
  transform: rotate(45deg)translateY(-2.5px);
}

.ant-menu-submenu-arrow:after {
  transform: rotate(-45deg)translateY(2.5px);
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1890ff;
}

.ant-menu-inline-collapsed .ant-menu-submenu-arrow:before, .ant-menu-submenu-inline .ant-menu-submenu-arrow:before {
  transform: rotate(-45deg)translateX(2.5px);
}

.ant-menu-inline-collapsed .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline .ant-menu-submenu-arrow:after {
  transform: rotate(45deg)translateX(-2.5px);
}

.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  transform: rotate(-45deg)translateX(-2.5px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  transform: rotate(45deg)translateX(2.5px);
}

.ant-menu-vertical .ant-menu-submenu-selected, .ant-menu-vertical-left .ant-menu-submenu-selected, .ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1890ff;
}

.ant-menu-horizontal {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  line-height: 46px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #1890ff;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
  border-bottom: 2px solid #1890ff;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  top: 1px;
}

.ant-menu-horizontal > .ant-menu-item:after, .ant-menu-horizontal > .ant-menu-submenu:after {
  content: "";
  border-bottom: 2px solid #0000;
  transition: border-color .3s cubic-bezier(.645, .045, .355, 1);
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: #000000d9;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #1890ff;
}

.ant-menu-horizontal > .ant-menu-item a:before {
  bottom: -2px;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #1890ff;
}

.ant-menu-horizontal:after {
  clear: both;
  content: " ";
  height: 0;
  display: block;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item {
  position: relative;
}

.ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after {
  opacity: 0;
  content: "";
  border-right: 3px solid #1890ff;
  transition: transform .15s cubic-bezier(.215, .61, .355, 1), opacity .15s cubic-bezier(.215, .61, .355, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: scaleY(.0001);
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  text-overflow: ellipsis;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  line-height: 40px;
  overflow: hidden;
}

.ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu {
  padding-bottom: .02px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}

.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline {
  width: 100%;
}

.ant-menu-inline .ant-menu-selected:after, .ant-menu-inline .ant-menu-item-selected:after {
  opacity: 1;
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1);
  transform: scaleY(1);
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  align-items: center;
  transition: border-color .3s, background .3s, padding .1s cubic-bezier(.215, .61, .355, 1);
  display: flex;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  text-overflow: ellipsis;
  flex: auto;
  min-width: 0;
  overflow: hidden;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > *, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}

.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  text-overflow: clip;
  padding: 0 calc(50% - 8px);
  left: 0;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  opacity: 0;
  display: inline-block;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}

.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}

.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}

.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: #ffffffd9;
}

.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
}

.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.ant-menu-item-group-list .ant-menu-item, .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
  box-shadow: none;
}

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon, .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  text-align: center;
  font-size: 16px;
}

.ant-menu-sub.ant-menu-inline {
  box-shadow: none;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-type: disc;
  list-style-position: inside;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}

.ant-menu-item-disabled, .ant-menu-submenu-disabled {
  cursor: not-allowed;
  background: none;
  color: #00000040 !important;
}

.ant-menu-item-disabled:after, .ant-menu-submenu-disabled:after {
  border-color: #0000 !important;
}

.ant-menu-item-disabled a, .ant-menu-submenu-disabled a {
  pointer-events: none;
  color: #00000040 !important;
}

.ant-menu-item-disabled > .ant-menu-submenu-title, .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  cursor: not-allowed;
  color: #00000040 !important;
}

.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  background: #00000040 !important;
}

.ant-layout-header .ant-menu {
  line-height: inherit;
}

.ant-menu-inline-collapsed-tooltip a, .ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: #1890ff;
}

.ant-menu.ant-menu-root:focus-visible, .ant-menu-dark .ant-menu-item:focus-visible, .ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #096dd9;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: #ffffffa6;
  background: #001529;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: .45;
  transition: all .3s;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #fff;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: none;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #001529 #001529 currentColor;
  border-bottom-style: none;
  border-bottom-width: 0;
  margin-top: 0;
  padding: 0 20px;
  top: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #1890ff;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a:before {
  bottom: 0;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
  color: #ffffffa6;
}

.ant-menu-dark.ant-menu-inline, .ant-menu-dark.ant-menu-vertical, .ant-menu-dark.ant-menu-vertical-left, .ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-vertical .ant-menu-item, .ant-menu-dark.ant-menu-vertical-left .ant-menu-item, .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  border-right: 0;
  margin-left: 0;
  left: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item:after, .ant-menu-dark.ant-menu-vertical .ant-menu-item:after, .ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after, .ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: #0000;
}

.ant-menu-dark .ant-menu-item:hover > a, .ant-menu-dark .ant-menu-item-active > a, .ant-menu-dark .ant-menu-submenu-active > a, .ant-menu-dark .ant-menu-submenu-open > a, .ant-menu-dark .ant-menu-submenu-selected > a, .ant-menu-dark .ant-menu-submenu-title:hover > a, .ant-menu-dark .ant-menu-item:hover > span > a, .ant-menu-dark .ant-menu-item-active > span > a, .ant-menu-dark .ant-menu-submenu-active > span > a, .ant-menu-dark .ant-menu-submenu-open > span > a, .ant-menu-dark .ant-menu-submenu-selected > span > a, .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #fff;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: #0000;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff;
}

.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected:after {
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a, .ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span, .ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}

.ant-menu-dark .ant-menu-item-disabled, .ant-menu-dark .ant-menu-submenu-disabled, .ant-menu-dark .ant-menu-item-disabled > a, .ant-menu-dark .ant-menu-submenu-disabled > a, .ant-menu-dark .ant-menu-item-disabled > span > a, .ant-menu-dark .ant-menu-submenu-disabled > span > a {
  opacity: .8;
  color: #ffffff59 !important;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title, .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: #ffffff59 !important;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before, .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after, .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after {
  background: #ffffff59 !important;
}

.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}

.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline, .ant-menu-rtl.ant-menu-vertical {
  border-left: 1px solid #f0f0f0;
  border-right: none;
}

.ant-menu-rtl.ant-menu-dark.ant-menu-inline, .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}

.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item, .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu, .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu, .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-item .ant-menu-item-icon, .ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-rtl .ant-menu-item .anticon, .ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-left: 10px;
  margin-right: auto;
}

.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon, .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon, .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon, .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}

.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 16px;
  right: auto;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  transform: rotate(-45deg)translateY(-2px);
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  transform: rotate(45deg)translateY(2px);
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item:after, .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item:after, .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item:after, .ant-menu-rtl.ant-menu-inline .ant-menu-item:after {
  left: 0;
  right: auto;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item, .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item, .ant-menu-rtl.ant-menu-inline .ant-menu-item, .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title, .ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 34px;
  padding-right: 0;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-left: 34px;
  padding-right: 16px;
}

.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 8px);
}

.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item, .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}

.ant-menu-sub.ant-menu-inline {
  border: 0;
}

.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 0;
  padding-right: 32px;
}

.ant-tooltip {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1070;
  width: intrinsic;
  visibility: visible;
  width: max-content;
  max-width: 250px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: block;
  position: absolute;
}

.ant-tooltip-content {
  position: relative;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
  padding-bottom: 14.3137px;
}

.ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom {
  padding-left: 14.3137px;
}

.ant-tooltip-placement-bottom, .ant-tooltip-placement-bottomLeft, .ant-tooltip-placement-bottomRight {
  padding-top: 14.3137px;
}

.ant-tooltip-placement-left, .ant-tooltip-placement-leftTop, .ant-tooltip-placement-leftBottom {
  padding-right: 14.3137px;
}

.ant-tooltip-inner {
  color: #fff;
  text-align: left;
  word-wrap: break-word;
  background-color: #000000bf;
  border-radius: 2px;
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  text-decoration: none;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-tooltip-arrow {
  z-index: 2;
  pointer-events: none;
  background: none;
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  overflow: hidden;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, #000000a6, #000000bf);
  content: "";
  pointer-events: auto;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0;
}

.ant-tooltip-arrow-content:before {
  background: var(--antd-arrow-background-color);
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background-position: -10px -10px;
  background-repeat: no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content, .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content, .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  transform: translateY(-11px)rotate(45deg);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%)translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow, .ant-tooltip-placement-rightTop .ant-tooltip-arrow, .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content, .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content, .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  transform: translateX(11px)rotate(135deg);
  box-shadow: -3px 3px 7px #00000012;
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%)translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow, .ant-tooltip-placement-leftTop .ant-tooltip-arrow, .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content, .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content, .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  transform: translateX(-11px)rotate(315deg);
  box-shadow: 3px -3px 7px #00000012;
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%)translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  transform: translateY(11px)rotate(225deg);
  box-shadow: -3px -3px 7px #00000012;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%)translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-pink .ant-tooltip-arrow-content:before {
  background: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-arrow-content:before {
  background: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}

.ant-tooltip-red .ant-tooltip-arrow-content:before {
  background: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}

.ant-tooltip-volcano .ant-tooltip-arrow-content:before {
  background: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}

.ant-tooltip-orange .ant-tooltip-arrow-content:before {
  background: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}

.ant-tooltip-yellow .ant-tooltip-arrow-content:before {
  background: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}

.ant-tooltip-gold .ant-tooltip-arrow-content:before {
  background: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}

.ant-tooltip-cyan .ant-tooltip-arrow-content:before {
  background: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}

.ant-tooltip-lime .ant-tooltip-arrow-content:before {
  background: #a0d911;
}

.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}

.ant-tooltip-green .ant-tooltip-arrow-content:before {
  background: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}

.ant-tooltip-blue .ant-tooltip-arrow-content:before {
  background: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}

.ant-tooltip-geekblue .ant-tooltip-arrow-content:before {
  background: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}

.ant-tooltip-purple .ant-tooltip-arrow-content:before {
  background: #722ed1;
}

.ant-tooltip-rtl {
  direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}

.ant-space {
  display: inline-flex;
}

.ant-space-vertical {
  flex-direction: column;
}

.ant-space-align-center {
  align-items: center;
}

.ant-space-align-start {
  align-items: flex-start;
}

.ant-space-align-end {
  align-items: flex-end;
}

.ant-space-align-baseline {
  align-items: baseline;
}

.ant-space-item:empty {
  display: none;
}

.ant-space-compact {
  display: inline-flex;
}

.ant-space-compact-block {
  width: 100%;
  display: flex;
}

.ant-space-compact-vertical {
  flex-direction: column;
}

.ant-space-rtl, .ant-space-compact-rtl {
  direction: rtl;
}

.ant-picker-calendar {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-picker-calendar-header {
  justify-content: flex-end;
  padding: 12px 0;
  display: flex;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}

.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel, .ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}

.ant-picker-calendar-mini {
  border-radius: 2px;
}

.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}

.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}

.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}

.ant-picker-calendar-mini .ant-picker-cell:before {
  pointer-events: none;
}

.ant-picker-calendar-full .ant-picker-panel {
  text-align: right;
  background: #fff;
  border: 0;
  width: 100%;
  display: block;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th, .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today:before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e6f7ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #1890ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  transition: background .3s;
  display: block;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color .3s;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  color: #000000d9;
  text-align: left;
  width: auto;
  height: 86px;
  line-height: 1.5715;
  position: static;
  overflow-y: auto;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #1890ff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #000000d9;
}

@media only screen and (width <= 480px) {
  .ant-picker-calendar-header {
    display: block;
  }

  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }

  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    text-align: center;
    width: 50%;
  }
}

.ant-picker-calendar-rtl {
  direction: rtl;
}

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select, .ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 0;
  margin-right: 8px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}

.ant-picker-status-error.ant-picker, .ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-picker-status-error.ant-picker-focused, .ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff7875;
}

.ant-picker-status-warning.ant-picker, .ant-picker-status-warning.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}

.ant-picker-status-warning.ant-picker-focused, .ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffc53d;
}

.ant-picker {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  align-items: center;
  margin: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: border .3s, box-shadow .3s;
  display: inline-flex;
  position: relative;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-picker.ant-picker-disabled {
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #00000040;
}

.ant-picker.ant-picker-borderless {
  box-shadow: none !important;
  background-color: #0000 !important;
  border-color: #0000 !important;
}

.ant-picker-input {
  align-items: center;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.ant-picker-input > input {
  color: #000000d9;
  background: none;
  border: 0;
  border-radius: 2px;
  flex: auto;
  width: 100%;
  min-width: 1px;
  height: auto;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-picker-input > input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input > input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-picker-input > input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-picker-input > input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-picker-input > input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-picker-input > input:focus, .ant-picker-input > input-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-picker-input > input-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-picker-input > input[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-picker-input > input-borderless, .ant-picker-input > input-borderless:hover, .ant-picker-input > input-borderless:focus, .ant-picker-input > input-borderless-focused, .ant-picker-input > input-borderless-disabled, .ant-picker-input > input-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-picker-input > input {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-picker-input > input-sm {
  padding: 0 7px;
}

.ant-picker-input > input:focus {
  box-shadow: none;
}

.ant-picker-input > input[disabled] {
  background: none;
}

.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}

.ant-picker-large {
  padding: 6.5px 11px;
}

.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}

.ant-picker-small {
  padding: 0 7px;
}

.ant-picker-suffix {
  color: #00000040;
  pointer-events: none;
  flex: none;
  align-self: center;
  margin-left: 4px;
  line-height: 1;
  display: flex;
}

.ant-picker-suffix > * {
  vertical-align: top;
}

.ant-picker-suffix > :not(:last-child) {
  margin-right: 8px;
}

.ant-picker-clear {
  color: #00000040;
  cursor: pointer;
  opacity: 0;
  background: #fff;
  line-height: 1;
  transition: opacity .3s, color .3s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ant-picker-clear > * {
  vertical-align: top;
}

.ant-picker-clear:hover {
  color: #00000073;
}

.ant-picker-separator {
  color: #00000040;
  vertical-align: top;
  cursor: default;
  width: 1em;
  height: 16px;
  font-size: 16px;
  display: inline-block;
  position: relative;
}

.ant-picker-focused .ant-picker-separator {
  color: #00000073;
}

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}

.ant-picker-range {
  display: inline-flex;
  position: relative;
}

.ant-picker-range .ant-picker-clear {
  right: 11px;
}

.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-range .ant-picker-active-bar {
  opacity: 0;
  pointer-events: none;
  background: #1890ff;
  height: 2px;
  margin-left: 11px;
  transition: all .3s ease-out;
  bottom: -1px;
}

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}

.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}

.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}

.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}

.ant-picker-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.ant-picker-dropdown-hidden {
  display: none;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  display: block;
  top: 2.58562px;
  transform: rotate(-135deg)translateY(1px);
}

.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  display: block;
  bottom: 2.58562px;
  transform: rotate(45deg);
}

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft, .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft, .ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft, .ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft, .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft, .ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}

.ant-picker-dropdown-range {
  padding: 7.54247px 0;
}

.ant-picker-dropdown-range-hidden {
  display: none;
}

.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}

.ant-picker-ranges {
  text-align: left;
  margin-bottom: 0;
  padding: 4px 12px;
  line-height: 34px;
  list-style: none;
  overflow: hidden;
}

.ant-picker-ranges > li {
  display: inline-block;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #1890ff;
  cursor: pointer;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}

.ant-picker-range-wrapper {
  display: flex;
}

.ant-picker-range-arrow {
  z-index: 1;
  pointer-events: none;
  border-radius: 0 0 2px;
  width: 11.3137px;
  height: 11.3137px;
  margin-left: 16.5px;
  transition: left .3s ease-out;
  position: absolute;
  box-shadow: 2px 2px 6px -2px #0000001a;
}

.ant-picker-range-arrow:before {
  content: "";
  clip-path: inset(33%);
  -webkit-clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z");
  background: #fff -10px -10px no-repeat;
  width: 33.9411px;
  height: 33.9411px;
  position: absolute;
  top: -11.3137px;
  left: -11.3137px;
}

.ant-picker-panel-container {
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  transition: margin .3s;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-picker-panel-container .ant-picker-panels {
  direction: ltr;
  flex-wrap: nowrap;
  display: inline-flex;
}

.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: none;
  border-width: 0 0 1px;
  border-radius: 0;
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-content, .ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}

.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}

.ant-picker-compact-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  margin-right: -1px;
}

.ant-picker-compact-item:not(.ant-picker-compact-last-item).ant-picker-compact-item-rtl {
  margin-left: -1px;
}

.ant-picker-compact-item:hover, .ant-picker-compact-item:focus, .ant-picker-compact-item:active, .ant-picker-compact-item.ant-picker-focused {
  z-index: 2;
}

.ant-picker-compact-item[disabled] {
  z-index: 0;
}

.ant-picker-compact-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-last-item).ant-picker {
  border-radius: 0;
}

.ant-picker-compact-item.ant-picker.ant-picker-compact-first-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-picker-compact-item.ant-picker.ant-picker-compact-last-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-item-rtl), .ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-first-item:not(.ant-picker-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-last-item:not(.ant-picker-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-picker-panel {
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  flex-direction: column;
  display: inline-flex;
}

.ant-picker-panel-focused {
  border-color: #1890ff;
}

.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
  flex-direction: column;
  width: 280px;
  display: flex;
}

.ant-picker-header {
  color: #000000d9;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 8px;
  display: flex;
}

.ant-picker-header > * {
  flex: none;
}

.ant-picker-header button {
  color: #00000040;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  line-height: 40px;
  transition: color .3s;
}

.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}

.ant-picker-header > button:hover {
  color: #000000d9;
}

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}

.ant-picker-header-view button:hover {
  color: #1890ff;
}

.ant-picker-prev-icon, .ant-picker-next-icon, .ant-picker-super-prev-icon, .ant-picker-super-next-icon {
  width: 7px;
  height: 7px;
  display: inline-block;
  position: relative;
}

.ant-picker-prev-icon:before, .ant-picker-next-icon:before, .ant-picker-super-prev-icon:before, .ant-picker-super-next-icon:before {
  content: "";
  border: 0 solid;
  border-width: 1.5px 0 0 1.5px;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-picker-super-prev-icon:after, .ant-picker-super-next-icon:after {
  content: "";
  border: 0 solid;
  border-width: 1.5px 0 0 1.5px;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 4px;
}

.ant-picker-prev-icon, .ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}

.ant-picker-next-icon, .ant-picker-super-next-icon {
  transform: rotate(135deg);
}

.ant-picker-content {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.ant-picker-content th, .ant-picker-content td {
  min-width: 24px;
  font-weight: 400;
  position: relative;
}

.ant-picker-content th {
  color: #000000d9;
  height: 30px;
  line-height: 30px;
}

.ant-picker-cell {
  color: #00000040;
  cursor: pointer;
  padding: 3px 0;
}

.ant-picker-cell-in-view {
  color: #000000d9;
}

.ant-picker-cell:before {
  z-index: 1;
  content: "";
  height: 24px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  z-index: 1;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  position: absolute;
  inset: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #e6f7ff;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before {
  background: #e6f7ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:before {
  left: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:before {
  right: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
  z-index: 0;
  content: "";
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  height: 24px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ant-picker-cell-range-hover-start:after, .ant-picker-cell-range-hover-end:after, .ant-picker-cell-range-hover:after {
  left: 2px;
  right: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before {
  background: #cbe6ff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
  z-index: -1;
  content: "";
  background: #cbe6ff;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
  left: 0;
  right: -6px;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
  left: -6px;
  right: 0;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
  right: 50%;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
  left: 50%;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after, .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  left: 6px;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after, .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after {
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  right: 6px;
}

.ant-picker-cell-disabled {
  color: #00000040;
  pointer-events: none;
}

.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: none;
}

.ant-picker-cell-disabled:before {
  background: #0000000a;
}

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #00000040;
}

.ant-picker-decade-panel .ant-picker-content, .ant-picker-year-panel .ant-picker-content, .ant-picker-quarter-panel .ant-picker-content, .ant-picker-month-panel .ant-picker-content {
  height: 264px;
}

.ant-picker-decade-panel .ant-picker-cell-inner, .ant-picker-year-panel .ant-picker-cell-inner, .ant-picker-quarter-panel .ant-picker-cell-inner, .ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}

.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}

.ant-picker-footer {
  text-align: center;
  border-bottom: 1px solid #0000;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-picker-footer-extra {
  text-align: left;
  padding: 0 12px;
  line-height: 38px;
}

.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.ant-picker-now {
  text-align: left;
}

.ant-picker-today-btn {
  color: #1890ff;
}

.ant-picker-today-btn:hover {
  color: #40a9ff;
}

.ant-picker-today-btn:active {
  color: #096dd9;
}

.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}

.ant-picker-decade-panel .ant-picker-cell:before {
  display: none;
}

.ant-picker-year-panel .ant-picker-body, .ant-picker-quarter-panel .ant-picker-body, .ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}

.ant-picker-year-panel .ant-picker-cell-inner, .ant-picker-quarter-panel .ant-picker-cell-inner, .ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-start:after, .ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after, .ant-picker-month-panel .ant-picker-cell-range-hover-start:after {
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
  left: 14px;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start:after, .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after, .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start:after, .ant-picker-year-panel .ant-picker-cell-range-hover-end:after, .ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after, .ant-picker-month-panel .ant-picker-cell-range-hover-end:after {
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
  right: 14px;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end:after, .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after, .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end:after {
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
  left: 14px;
}

.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner, .ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: none !important;
}

.ant-picker-week-panel-row td {
  transition: background .3s;
}

.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}

.ant-picker-week-panel-row-selected td, .ant-picker-week-panel-row-selected:hover td {
  background: #1890ff;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week, .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: #ffffff80;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner:before, .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #fff;
}

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner, .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}

.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}

.ant-picker-datetime-panel {
  display: flex;
}

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}

.ant-picker-datetime-panel .ant-picker-date-panel, .ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity .3s;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel, .ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: .3;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel-active, .ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}

.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}

.ant-picker-time-panel .ant-picker-content {
  flex: auto;
  height: 224px;
  display: flex;
}

.ant-picker-time-panel-column {
  text-align: left;
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: background .3s;
  overflow-y: hidden;
}

.ant-picker-time-panel-column:after {
  content: "";
  height: 196px;
  display: block;
}

.ant-picker-datetime-panel .ant-picker-time-panel-column:after {
  height: 198px;
}

.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}

.ant-picker-time-panel-column-active {
  background: #e6f7ff33;
}

.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}

.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #000000d9;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  line-height: 28px;
  transition: background .3s;
  display: block;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6f7ff;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: #00000040;
  cursor: not-allowed;
  background: none;
}

_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell, :root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell, _:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell, :root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}

.ant-picker-rtl {
  direction: rtl;
}

.ant-picker-rtl .ant-picker-suffix {
  margin-left: 0;
  margin-right: 4px;
}

.ant-picker-rtl .ant-picker-clear {
  left: 0;
  right: auto;
}

.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}

.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-left: 0;
  margin-right: 8px;
}

.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  left: 11px;
  right: auto;
}

.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-left: 0;
  margin-right: 11px;
}

.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}

.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}

.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-left: 0;
  margin-right: 8px;
}

.ant-picker-panel-rtl {
  direction: rtl;
}

.ant-picker-panel-rtl .ant-picker-prev-icon, .ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}

.ant-picker-panel-rtl .ant-picker-next-icon, .ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}

.ant-picker-cell .ant-picker-cell-inner {
  z-index: 2;
  border-radius: 2px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  transition: background .3s, border .3s;
  display: inline-block;
  position: relative;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:before {
  left: 0;
  right: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:before {
  left: 50%;
  right: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end:before {
  left: 50%;
  right: 50%;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
  left: -6px;
  right: 0;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
  left: 0;
  right: -6px;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
  left: 50%;
  right: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
  left: 0;
  right: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
  border-left: none;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
  left: 0;
  right: 6px;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after {
  border-left: 1px dashed #7ec1ff;
  border-right: none;
  border-radius: 2px 0 0 2px;
  left: 6px;
  right: 0;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after, .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after, .ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover):after, .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child:after, .ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child:after {
  border-left: 1px dashed #7ec1ff;
  border-right: 1px dashed #7ec1ff;
  border-radius: 2px;
  left: 6px;
  right: 6px;
}

.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}

.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}

.ant-tag {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  opacity: 1;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  transition: all .3s;
  display: inline-block;
}

.ant-tag, .ant-tag a, .ant-tag a:hover {
  color: #000000d9;
}

.ant-tag > a:first-child:last-child {
  margin: 0 -8px;
  padding: 0 8px;
  display: inline-block;
}

.ant-tag-close-icon {
  color: #00000073;
  cursor: pointer;
  margin-left: 3px;
  font-size: 10px;
  transition: all .3s;
}

.ant-tag-close-icon:hover {
  color: #000000d9;
}

.ant-tag-has-color {
  border-color: #0000;
}

.ant-tag-has-color, .ant-tag-has-color a, .ant-tag-has-color a:hover, .ant-tag-has-color .anticon-close, .ant-tag-has-color .anticon-close:hover {
  color: #fff;
}

.ant-tag-checkable {
  cursor: pointer;
  background-color: #0000;
  border-color: #0000;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff;
}

.ant-tag-checkable:active, .ant-tag-checkable-checked {
  color: #fff;
}

.ant-tag-checkable-checked {
  background-color: #1890ff;
}

.ant-tag-checkable:active {
  background-color: #096dd9;
}

.ant-tag-hidden {
  display: none;
}

.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}

.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}

.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}

.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}

.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}

.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}

.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}

.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}

.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}

.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}

.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}

.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}

.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}

.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}

.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}

.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}

.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}

.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}

.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}

.ant-tag-warning {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}

.ant-tag > .anticon + span, .ant-tag > span + .anticon {
  margin-left: 7px;
}

.ant-tag.ant-tag-rtl {
  direction: rtl;
  text-align: right;
  margin-left: 8px;
  margin-right: 0;
}

.ant-tag-rtl .ant-tag-close-icon {
  margin-left: 0;
  margin-right: 3px;
}

.ant-tag-rtl.ant-tag > .anticon + span, .ant-tag-rtl.ant-tag > span + .anticon {
  margin-left: 0;
  margin-right: 7px;
}

.ant-radio-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-radio-group .ant-badge-count {
  z-index: 1;
}

.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}

.ant-radio-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  align-items: baseline;
  margin: 0 8px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-flex;
  position: relative;
}

.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}

.ant-radio-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type="radio"] {
  width: 14px;
  height: 14px;
}

.ant-radio {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
  position: relative;
  top: .2em;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out both antRadioEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-radio:hover:after, .ant-radio-wrapper:hover .ant-radio:after {
  visibility: visible;
}

.ant-radio-inner {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-radio-inner:after {
  opacity: 0;
  content: " ";
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  transition: all .3s cubic-bezier(.78, .14, .15, .86);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
}

.ant-radio-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  inset: 0;
}

.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}

.ant-radio-checked .ant-radio-inner:after {
  opacity: 1;
  transition: all .3s cubic-bezier(.78, .14, .15, .86);
  transform: scale(.5);
}

.ant-radio-disabled {
  cursor: not-allowed;
}

.ant-radio-disabled .ant-radio-inner {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.ant-radio-disabled .ant-radio-inner:after {
  background-color: #0003;
}

.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}

.ant-radio-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

span.ant-radio + * {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-radio-button-wrapper {
  color: #000000d9;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-width: 1.02px 1px 1px 0;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  font-size: 14px;
  line-height: 30px;
  transition: color .3s, background .3s, border-color .3s, box-shadow .3s;
  display: inline-block;
  position: relative;
}

.ant-radio-button-wrapper a {
  color: #000000d9;
}

.ant-radio-button-wrapper > .ant-radio-button {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}

.ant-radio-button-wrapper:not(:first-child):before {
  box-sizing: content-box;
  content: "";
  background-color: #d9d9d9;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  transition: background-color .3s;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}

.ant-radio-button-wrapper:hover {
  color: #1890ff;
  position: relative;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-button-wrapper .ant-radio-inner, .ant-radio-button-wrapper input[type="checkbox"], .ant-radio-button-wrapper input[type="radio"] {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  background-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
  background-color: #40a9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active:before {
  background-color: #096dd9;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #1890ff1f;
}

.ant-radio-button-wrapper-disabled {
  color: #00000040;
  cursor: not-allowed;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled:first-child, .ant-radio-button-wrapper-disabled:hover {
  color: #00000040;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #00000040;
  box-shadow: none;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
}

@keyframes antRadioEffect {
  0% {
    opacity: .5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}

.ant-radio-wrapper.ant-radio-wrapper-rtl {
  direction: rtl;
  margin-left: 8px;
  margin-right: 0;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-left-width: 1px;
  border-right-width: 0;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child):before {
  left: 0;
  right: -1px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #40a9ff;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

.ant-card {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-card-rtl {
  direction: rtl;
}

.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow .3s, border-color .3s;
}

.ant-card-hoverable:hover {
  border-color: #0000;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
}

.ant-card-bordered {
  border: 1px solid #f0f0f0;
}

.ant-card-head {
  color: #000000d9;
  background: none;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 500;
}

.ant-card-head:before {
  content: "";
  display: table;
}

.ant-card-head:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-head-wrapper {
  align-items: center;
  display: flex;
}

.ant-card-head-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  padding: 16px 0;
  display: inline-block;
  overflow: hidden;
}

.ant-card-head-title > .ant-typography, .ant-card-head-title > .ant-typography-edit-content {
  margin-top: 0;
  margin-bottom: 0;
  left: 0;
}

.ant-card-head .ant-tabs-top {
  clear: both;
  color: #000000d9;
  margin-bottom: -17px;
  font-size: 14px;
  font-weight: normal;
}

.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}

.ant-card-extra {
  color: #000000d9;
  margin-left: auto;
  padding: 16px 0;
  font-size: 14px;
  font-weight: normal;
}

.ant-card-rtl .ant-card-extra {
  margin-left: 0;
  margin-right: auto;
}

.ant-card-body {
  padding: 24px;
}

.ant-card-body:before {
  content: "";
  display: table;
}

.ant-card-body:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-contain-grid .ant-card-body {
  flex-wrap: wrap;
  display: flex;
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}

.ant-card-grid {
  border: 0;
  border-radius: 0;
  width: 33.33%;
  padding: 24px;
  transition: all .3s;
  box-shadow: 1px 0 #f0f0f0, 0 1px #f0f0f0, 1px 1px #f0f0f0, inset 1px 0 #f0f0f0, inset 0 1px #f0f0f0;
}

.ant-card-grid-hoverable:hover {
  z-index: 1;
  position: relative;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
}

.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}

.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}

.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
}

.ant-card-cover > * {
  width: 100%;
  display: block;
}

.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}

.ant-card-actions {
  background: #fff;
  border-top: 1px solid #f0f0f0;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.ant-card-actions:before {
  content: "";
  display: table;
}

.ant-card-actions:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-actions > li {
  color: #00000073;
  text-align: center;
  margin: 12px 0;
}

.ant-card-actions > li > span {
  cursor: pointer;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  display: block;
  position: relative;
}

.ant-card-actions > li > span:hover {
  color: #1890ff;
  transition: color .3s;
}

.ant-card-actions > li > span a:not(.ant-btn), .ant-card-actions > li > span > .anticon {
  color: #00000073;
  width: 100%;
  line-height: 22px;
  transition: color .3s;
  display: inline-block;
}

.ant-card-actions > li > span a:not(.ant-btn):hover, .ant-card-actions > li > span > .anticon:hover {
  color: #1890ff;
}

.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}

.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}

.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-left: 1px solid #f0f0f0;
  border-right: none;
}

.ant-card-type-inner .ant-card-head {
  background: #fafafa;
  padding: 0 24px;
}

.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}

.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}

.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}

.ant-card-meta {
  margin: -4px 0;
  display: flex;
}

.ant-card-meta:before {
  content: "";
  display: table;
}

.ant-card-meta:after {
  clear: both;
  content: "";
  display: table;
}

.ant-card-meta-avatar {
  padding-right: 16px;
}

.ant-card-rtl .ant-card-meta-avatar {
  padding-left: 16px;
  padding-right: 0;
}

.ant-card-meta-detail {
  flex: 1;
  overflow: hidden;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}

.ant-card-meta-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
}

.ant-card-meta-description {
  color: #00000073;
}

.ant-card-loading {
  overflow: hidden;
}

.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
  user-select: none;
}

.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}

.ant-card-small > .ant-card-body {
  padding: 12px;
}

.ant-skeleton {
  width: 100%;
  display: table;
}

.ant-skeleton-header {
  vertical-align: top;
  padding-right: 16px;
  display: table-cell;
}

.ant-skeleton-header .ant-skeleton-avatar {
  vertical-align: top;
  background: #bebebe33;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-content {
  vertical-align: top;
  width: 100%;
  display: table-cell;
}

.ant-skeleton-content .ant-skeleton-title {
  background: #bebebe33;
  border-radius: 2px;
  width: 100%;
  height: 16px;
}

.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}

.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  background: #bebebe33;
  border-radius: 2px;
  width: 100%;
  height: 16px;
  list-style: none;
}

.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}

.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title, .ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}

.ant-skeleton-active .ant-skeleton-title, .ant-skeleton-active .ant-skeleton-paragraph > li, .ant-skeleton-active .ant-skeleton-avatar, .ant-skeleton-active .ant-skeleton-button, .ant-skeleton-active .ant-skeleton-input, .ant-skeleton-active .ant-skeleton-image {
  z-index: 0;
  background: none;
  position: relative;
  overflow: hidden;
}

.ant-skeleton-active .ant-skeleton-title:after, .ant-skeleton-active .ant-skeleton-paragraph > li:after, .ant-skeleton-active .ant-skeleton-avatar:after, .ant-skeleton-active .ant-skeleton-button:after, .ant-skeleton-active .ant-skeleton-input:after, .ant-skeleton-active .ant-skeleton-image:after {
  content: "";
  background: linear-gradient(90deg, #bebebe33 25%, #8181813d 37%, #bebebe33 63%);
  animation: 1.4s infinite ant-skeleton-loading;
  position: absolute;
  inset: 0 -150%;
}

.ant-skeleton.ant-skeleton-block, .ant-skeleton.ant-skeleton-block .ant-skeleton-button, .ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}

.ant-skeleton-element {
  width: auto;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-button {
  vertical-align: top;
  background: #bebebe33;
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-square {
  width: 32px;
  min-width: 32px;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  border-radius: 50%;
  width: 32px;
  min-width: 32px;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}

.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}

.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar {
  vertical-align: top;
  background: #bebebe33;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-input {
  vertical-align: top;
  background: #bebebe33;
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}

.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-image {
  vertical-align: top;
  background: #bebebe33;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  line-height: 96px;
  display: flex;
}

.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}

.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  max-width: 192px;
  height: 48px;
  max-height: 192px;
  line-height: 48px;
}

.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}

@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }

  100% {
    transform: translateX(37.5%);
  }
}

.ant-skeleton-rtl {
  direction: rtl;
}

.ant-skeleton-rtl .ant-skeleton-header {
  padding-left: 16px;
  padding-right: 0;
}

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title, .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li, .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}

@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}

.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}

.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}

.ant-tabs-rtl {
  direction: rtl;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-left: 12px;
  margin-right: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-left: -4px;
  margin-right: 8px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-left: 0;
  margin-right: 2px;
}

.ant-tabs-dropdown-rtl {
  direction: rtl;
}

.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}

.ant-tabs-top, .ant-tabs-bottom {
  flex-direction: column;
}

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px;
}

.ant-tabs-top > .ant-tabs-nav:before, .ant-tabs-bottom > .ant-tabs-nav:before, .ant-tabs-top > div > .ant-tabs-nav:before, .ant-tabs-bottom > div > .ant-tabs-nav:before {
  content: "";
  border-bottom: 1px solid #f0f0f0;
  position: absolute;
  left: 0;
  right: 0;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width .3s, left .3s, right .3s;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  width: 30px;
  top: 0;
  bottom: 0;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px #00000014;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px #00000014;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before, .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after {
  opacity: 1;
}

.ant-tabs-top > .ant-tabs-nav:before, .ant-tabs-top > div > .ant-tabs-nav:before, .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav:before, .ant-tabs-bottom > div > .ant-tabs-nav:before, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}

.ant-tabs-bottom > .ant-tabs-content-holder, .ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-left > .ant-tabs-nav, .ant-tabs-right > .ant-tabs-nav, .ant-tabs-left > div > .ant-tabs-nav, .ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  text-align: center;
  padding: 8px 24px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  height: 30px;
  left: 0;
  right: 0;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px #00000014;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px #00000014;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before, .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after {
  opacity: 1;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height .3s, top .3s;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex-direction: column;
  flex: 1 0 auto;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}

.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder {
  border-left: 1px solid #f0f0f0;
  margin-left: -1px;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}

.ant-tabs-right > .ant-tabs-nav, .ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}

.ant-tabs-right > .ant-tabs-content-holder, .ant-tabs-right > div > .ant-tabs-content-holder {
  border-right: 1px solid #f0f0f0;
  order: 0;
  margin-right: -1px;
}

.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane, .ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}

.ant-tabs-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: block;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.ant-tabs-dropdown-hidden {
  display: none;
}

.ant-tabs-dropdown-menu {
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  list-style-type: none;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-tabs-dropdown-menu-item {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transition: all .3s;
  display: flex;
  overflow: hidden;
}

.ant-tabs-dropdown-menu-item > span {
  white-space: nowrap;
  flex: 1;
}

.ant-tabs-dropdown-menu-item-remove {
  color: #00000073;
  cursor: pointer;
  background: none;
  border: 0;
  flex: none;
  margin-left: 12px;
  font-size: 12px;
}

.ant-tabs-dropdown-menu-item-remove:hover {
  color: #40a9ff;
}

.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}

.ant-tabs-dropdown-menu-item-disabled, .ant-tabs-dropdown-menu-item-disabled:hover {
  color: #00000040;
  cursor: not-allowed;
  background: none;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: #fafafa;
  border: 1px solid #f0f0f0;
  margin: 0;
  padding: 8px 16px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  background: #fff;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}

.ant-tabs {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  flex: none;
  align-items: center;
  display: flex;
  position: relative;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  white-space: nowrap;
  flex: auto;
  align-self: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: translate(0);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap:before, .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap:after, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap:after {
  z-index: 1;
  opacity: 0;
  content: "";
  pointer-events: none;
  transition: opacity .3s;
  position: absolute;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  transition: transform .3s;
  display: flex;
  position: relative;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  align-self: stretch;
  display: flex;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  background: none;
  border: 0;
  padding: 8px 16px;
  position: relative;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more:after, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more:after {
  content: "";
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  cursor: pointer;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #40a9ff;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #096dd9;
}

.ant-tabs-extra-content {
  flex: none;
}

.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]), .ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]) {
  justify-content: center;
}

.ant-tabs-ink-bar {
  pointer-events: none;
  background: #1890ff;
  position: absolute;
}

.ant-tabs-tab {
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  display: inline-flex;
  position: relative;
}

.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
  color: #096dd9;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all .3s;
}

.ant-tabs-tab-remove {
  color: #00000073;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  flex: none;
  margin-left: 8px;
  margin-right: -4px;
  font-size: 12px;
  transition: all .3s;
}

.ant-tabs-tab-remove:hover {
  color: #000000d9;
}

.ant-tabs-tab:hover {
  color: #40a9ff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  text-shadow: 0 0 .25px;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus, .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus, .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active, .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: #00000040;
}

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-tab .anticon {
  margin-right: 12px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-content {
  width: 100%;
  position: relative;
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}

.ant-tabs-tabpane {
  outline: none;
}

.ant-tabs-tabpane-hidden {
  display: none;
}

.ant-tabs-switch-appear, .ant-tabs-switch-enter {
  transition: none;
}

.ant-tabs-switch-appear-start, .ant-tabs-switch-enter-start {
  opacity: 0;
}

.ant-tabs-switch-appear-active, .ant-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity .3s;
}

.ant-tabs-switch-leave {
  transition: none;
  position: absolute;
  inset: 0;
}

.ant-tabs-switch-leave-start {
  opacity: 1;
}

.ant-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity .3s;
}

.ant-carousel {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-carousel .slick-slider {
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.ant-carousel .slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-carousel .slick-list:focus {
  outline: none;
}

.ant-carousel .slick-list.dragging {
  cursor: pointer;
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}

.ant-carousel .slick-list .slick-slide input.ant-radio-input, .ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}

.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}

.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input, .ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}

.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}

.ant-carousel .slick-slider .slick-track, .ant-carousel .slick-slider .slick-list {
  touch-action: pan-y;
  transform: translate3d(0, 0, 0);
}

.ant-carousel .slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-carousel .slick-track:before, .ant-carousel .slick-track:after {
  content: "";
  display: table;
}

.ant-carousel .slick-track:after {
  clear: both;
}

.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}

.ant-carousel .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

.ant-carousel .slick-slide img {
  display: block;
}

.ant-carousel .slick-slide.slick-loading img {
  display: none;
}

.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}

.ant-carousel .slick-initialized .slick-slide {
  display: block;
}

.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}

.ant-carousel .slick-vertical .slick-slide {
  height: auto;
  display: block;
}

.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}

.ant-carousel .slick-prev, .ant-carousel .slick-next {
  color: #0000;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
}

.ant-carousel .slick-prev:hover, .ant-carousel .slick-next:hover, .ant-carousel .slick-prev:focus, .ant-carousel .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.ant-carousel .slick-prev:hover:before, .ant-carousel .slick-next:hover:before, .ant-carousel .slick-prev:focus:before, .ant-carousel .slick-next:focus:before {
  opacity: 1;
}

.ant-carousel .slick-prev.slick-disabled:before, .ant-carousel .slick-next.slick-disabled:before {
  opacity: .25;
}

.ant-carousel .slick-prev {
  left: -25px;
}

.ant-carousel .slick-prev:before {
  content: "←";
}

.ant-carousel .slick-next {
  right: -25px;
}

.ant-carousel .slick-next:before {
  content: "→";
}

.ant-carousel .slick-dots {
  z-index: 15;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}

.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}

.ant-carousel .slick-dots li {
  box-sizing: content-box;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  flex: 0 auto;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  transition: all .5s;
  display: inline-block;
  position: relative;
}

.ant-carousel .slick-dots li button {
  color: #0000;
  cursor: pointer;
  opacity: .3;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  width: 100%;
  height: 3px;
  padding: 0;
  font-size: 0;
  transition: all .5s;
  display: block;
  position: relative;
}

.ant-carousel .slick-dots li button:hover, .ant-carousel .slick-dots li button:focus {
  opacity: .75;
}

.ant-carousel .slick-dots li button:after {
  content: "";
  position: absolute;
  inset: -4px;
}

.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #fff;
}

.ant-carousel .slick-dots li.slick-active:hover, .ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}

.ant-carousel-vertical .slick-dots {
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.ant-carousel-vertical .slick-dots-left {
  left: 12px;
  right: auto;
}

.ant-carousel-vertical .slick-dots-right {
  left: auto;
  right: 12px;
}

.ant-carousel-vertical .slick-dots li {
  vertical-align: baseline;
  width: 3px;
  height: 16px;
  margin: 4px 0;
}

.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}

.ant-carousel-vertical .slick-dots li.slick-active, .ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}

.ant-carousel-rtl {
  direction: rtl;
}

.ant-carousel-rtl .ant-carousel .slick-track {
  left: auto;
  right: 0;
}

.ant-carousel-rtl .ant-carousel .slick-prev {
  left: auto;
  right: -25px;
}

.ant-carousel-rtl .ant-carousel .slick-prev:before {
  content: "→";
}

.ant-carousel-rtl .ant-carousel .slick-next {
  left: -25px;
  right: auto;
}

.ant-carousel-rtl .ant-carousel .slick-next:before {
  content: "←";
}

.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}

.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}

@keyframes antCheckboxEffect {
  0% {
    opacity: .5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

.ant-cascader-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner, .ant-cascader-checkbox:hover .ant-cascader-checkbox-inner, .ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #1890ff;
}

.ant-cascader-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-cascader-checkbox:hover:after, .ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox:after {
  visibility: visible;
}

.ant-cascader-checkbox-inner {
  direction: ltr;
  border-collapse: separate;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-cascader-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-cascader-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}

.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-cascader-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-cascader-checkbox-disabled:hover:after, .ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled:after {
  visibility: hidden;
}

.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-cascader-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}

.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-cascader-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-cascader-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}

.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}

.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-cascader {
  width: 184px;
}

.ant-cascader-checkbox {
  margin-right: 8px;
  top: 0;
}

.ant-cascader-menus {
  flex-wrap: nowrap;
  align-items: flex-start;
  display: flex;
}

.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}

.ant-cascader-menu {
  vertical-align: top;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-right: 1px solid #f0f0f0;
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: -4px 0;
  padding: 4px 0;
  list-style: none;
  overflow: auto;
}

.ant-cascader-menu-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  line-height: 22px;
  transition: all .3s;
  display: flex;
  overflow: hidden;
}

.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}

.ant-cascader-menu-item-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-cascader-menu-item-disabled:hover {
  background: none;
}

.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: #00000040;
  cursor: default;
  pointer-events: none;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #e6f7ff;
  font-weight: 600;
}

.ant-cascader-menu-item-content {
  flex: auto;
}

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon, .ant-cascader-menu-item-loading-icon {
  color: #00000073;
  margin-left: 4px;
  font-size: 10px;
}

.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon, .ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: #00000040;
}

.ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}

.ant-cascader-compact-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  margin-right: -1px;
}

.ant-cascader-compact-item:not(.ant-cascader-compact-last-item).ant-cascader-compact-item-rtl {
  margin-left: -1px;
}

.ant-cascader-compact-item:hover, .ant-cascader-compact-item:focus, .ant-cascader-compact-item:active {
  z-index: 2;
}

.ant-cascader-compact-item[disabled] {
  z-index: 0;
}

.ant-cascader-compact-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-last-item).ant-cascader {
  border-radius: 0;
}

.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-item-rtl), .ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-cascader-rtl .ant-cascader-menu-item-expand-icon, .ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-left: 0;
  margin-right: 4px;
}

.ant-cascader-rtl .ant-cascader-checkbox {
  margin-left: 8px;
  margin-right: 0;
  top: 0;
}

.ant-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff;
}

.ant-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-checkbox:hover:after, .ant-checkbox-wrapper:hover .ant-checkbox:after {
  visibility: visible;
}

.ant-checkbox-inner {
  direction: ltr;
  border-collapse: separate;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-checkbox-disabled {
  cursor: not-allowed;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-checkbox-disabled:hover:after, .ant-checkbox-wrapper:hover .ant-checkbox-disabled:after {
  visibility: hidden;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-checkbox-group-item {
  margin-right: 8px;
}

.ant-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-checkbox-rtl {
  direction: rtl;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-left: 8px;
  margin-right: 0;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}

.ant-row {
  flex-flow: wrap;
  min-width: 0;
  display: flex;
}

.ant-row:before, .ant-row:after {
  display: flex;
}

.ant-row-no-wrap {
  flex-wrap: nowrap;
}

.ant-row-start {
  justify-content: flex-start;
}

.ant-row-center {
  justify-content: center;
}

.ant-row-end {
  justify-content: flex-end;
}

.ant-row-space-between {
  justify-content: space-between;
}

.ant-row-space-around {
  justify-content: space-around;
}

.ant-row-space-evenly {
  justify-content: space-evenly;
}

.ant-row-top {
  align-items: flex-start;
}

.ant-row-middle {
  align-items: center;
}

.ant-row-bottom {
  align-items: flex-end;
}

.ant-col {
  max-width: 100%;
  min-height: 1px;
  position: relative;
}

.ant-col-24 {
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}

.ant-col-push-24 {
  left: 100%;
}

.ant-col-pull-24 {
  right: 100%;
}

.ant-col-offset-24 {
  margin-left: 100%;
}

.ant-col-order-24 {
  order: 24;
}

.ant-col-23 {
  flex: 0 0 95.8333%;
  max-width: 95.8333%;
  display: block;
}

.ant-col-push-23 {
  left: 95.8333%;
}

.ant-col-pull-23 {
  right: 95.8333%;
}

.ant-col-offset-23 {
  margin-left: 95.8333%;
}

.ant-col-order-23 {
  order: 23;
}

.ant-col-22 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
  display: block;
}

.ant-col-push-22 {
  left: 91.6667%;
}

.ant-col-pull-22 {
  right: 91.6667%;
}

.ant-col-offset-22 {
  margin-left: 91.6667%;
}

.ant-col-order-22 {
  order: 22;
}

.ant-col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
  display: block;
}

.ant-col-push-21 {
  left: 87.5%;
}

.ant-col-pull-21 {
  right: 87.5%;
}

.ant-col-offset-21 {
  margin-left: 87.5%;
}

.ant-col-order-21 {
  order: 21;
}

.ant-col-20 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
  display: block;
}

.ant-col-push-20 {
  left: 83.3333%;
}

.ant-col-pull-20 {
  right: 83.3333%;
}

.ant-col-offset-20 {
  margin-left: 83.3333%;
}

.ant-col-order-20 {
  order: 20;
}

.ant-col-19 {
  flex: 0 0 79.1667%;
  max-width: 79.1667%;
  display: block;
}

.ant-col-push-19 {
  left: 79.1667%;
}

.ant-col-pull-19 {
  right: 79.1667%;
}

.ant-col-offset-19 {
  margin-left: 79.1667%;
}

.ant-col-order-19 {
  order: 19;
}

.ant-col-18 {
  flex: 0 0 75%;
  max-width: 75%;
  display: block;
}

.ant-col-push-18 {
  left: 75%;
}

.ant-col-pull-18 {
  right: 75%;
}

.ant-col-offset-18 {
  margin-left: 75%;
}

.ant-col-order-18 {
  order: 18;
}

.ant-col-17 {
  flex: 0 0 70.8333%;
  max-width: 70.8333%;
  display: block;
}

.ant-col-push-17 {
  left: 70.8333%;
}

.ant-col-pull-17 {
  right: 70.8333%;
}

.ant-col-offset-17 {
  margin-left: 70.8333%;
}

.ant-col-order-17 {
  order: 17;
}

.ant-col-16 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
  display: block;
}

.ant-col-push-16 {
  left: 66.6667%;
}

.ant-col-pull-16 {
  right: 66.6667%;
}

.ant-col-offset-16 {
  margin-left: 66.6667%;
}

.ant-col-order-16 {
  order: 16;
}

.ant-col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
  display: block;
}

.ant-col-push-15 {
  left: 62.5%;
}

.ant-col-pull-15 {
  right: 62.5%;
}

.ant-col-offset-15 {
  margin-left: 62.5%;
}

.ant-col-order-15 {
  order: 15;
}

.ant-col-14 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
  display: block;
}

.ant-col-push-14 {
  left: 58.3333%;
}

.ant-col-pull-14 {
  right: 58.3333%;
}

.ant-col-offset-14 {
  margin-left: 58.3333%;
}

.ant-col-order-14 {
  order: 14;
}

.ant-col-13 {
  flex: 0 0 54.1667%;
  max-width: 54.1667%;
  display: block;
}

.ant-col-push-13 {
  left: 54.1667%;
}

.ant-col-pull-13 {
  right: 54.1667%;
}

.ant-col-offset-13 {
  margin-left: 54.1667%;
}

.ant-col-order-13 {
  order: 13;
}

.ant-col-12 {
  flex: 0 0 50%;
  max-width: 50%;
  display: block;
}

.ant-col-push-12 {
  left: 50%;
}

.ant-col-pull-12 {
  right: 50%;
}

.ant-col-offset-12 {
  margin-left: 50%;
}

.ant-col-order-12 {
  order: 12;
}

.ant-col-11 {
  flex: 0 0 45.8333%;
  max-width: 45.8333%;
  display: block;
}

.ant-col-push-11 {
  left: 45.8333%;
}

.ant-col-pull-11 {
  right: 45.8333%;
}

.ant-col-offset-11 {
  margin-left: 45.8333%;
}

.ant-col-order-11 {
  order: 11;
}

.ant-col-10 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
  display: block;
}

.ant-col-push-10 {
  left: 41.6667%;
}

.ant-col-pull-10 {
  right: 41.6667%;
}

.ant-col-offset-10 {
  margin-left: 41.6667%;
}

.ant-col-order-10 {
  order: 10;
}

.ant-col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
  display: block;
}

.ant-col-push-9 {
  left: 37.5%;
}

.ant-col-pull-9 {
  right: 37.5%;
}

.ant-col-offset-9 {
  margin-left: 37.5%;
}

.ant-col-order-9 {
  order: 9;
}

.ant-col-8 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  display: block;
}

.ant-col-push-8 {
  left: 33.3333%;
}

.ant-col-pull-8 {
  right: 33.3333%;
}

.ant-col-offset-8 {
  margin-left: 33.3333%;
}

.ant-col-order-8 {
  order: 8;
}

.ant-col-7 {
  flex: 0 0 29.1667%;
  max-width: 29.1667%;
  display: block;
}

.ant-col-push-7 {
  left: 29.1667%;
}

.ant-col-pull-7 {
  right: 29.1667%;
}

.ant-col-offset-7 {
  margin-left: 29.1667%;
}

.ant-col-order-7 {
  order: 7;
}

.ant-col-6 {
  flex: 0 0 25%;
  max-width: 25%;
  display: block;
}

.ant-col-push-6 {
  left: 25%;
}

.ant-col-pull-6 {
  right: 25%;
}

.ant-col-offset-6 {
  margin-left: 25%;
}

.ant-col-order-6 {
  order: 6;
}

.ant-col-5 {
  flex: 0 0 20.8333%;
  max-width: 20.8333%;
  display: block;
}

.ant-col-push-5 {
  left: 20.8333%;
}

.ant-col-pull-5 {
  right: 20.8333%;
}

.ant-col-offset-5 {
  margin-left: 20.8333%;
}

.ant-col-order-5 {
  order: 5;
}

.ant-col-4 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
  display: block;
}

.ant-col-push-4 {
  left: 16.6667%;
}

.ant-col-pull-4 {
  right: 16.6667%;
}

.ant-col-offset-4 {
  margin-left: 16.6667%;
}

.ant-col-order-4 {
  order: 4;
}

.ant-col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  display: block;
}

.ant-col-push-3 {
  left: 12.5%;
}

.ant-col-pull-3 {
  right: 12.5%;
}

.ant-col-offset-3 {
  margin-left: 12.5%;
}

.ant-col-order-3 {
  order: 3;
}

.ant-col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  display: block;
}

.ant-col-push-2 {
  left: 8.33333%;
}

.ant-col-pull-2 {
  right: 8.33333%;
}

.ant-col-offset-2 {
  margin-left: 8.33333%;
}

.ant-col-order-2 {
  order: 2;
}

.ant-col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%;
  display: block;
}

.ant-col-push-1 {
  left: 4.16667%;
}

.ant-col-pull-1 {
  right: 4.16667%;
}

.ant-col-offset-1 {
  margin-left: 4.16667%;
}

.ant-col-order-1 {
  order: 1;
}

.ant-col-0 {
  display: none;
}

.ant-col-offset-0 {
  margin-left: 0;
}

.ant-col-order-0 {
  order: 0;
}

.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-push-1.ant-col-rtl {
  left: auto;
  right: 4.16667%;
}

.ant-col-pull-1.ant-col-rtl {
  left: 4.16667%;
  right: auto;
}

.ant-col-offset-1.ant-col-rtl {
  margin-left: 0;
  margin-right: 4.16667%;
}

.ant-col-push-2.ant-col-rtl {
  left: auto;
  right: 8.33333%;
}

.ant-col-pull-2.ant-col-rtl {
  left: 8.33333%;
  right: auto;
}

.ant-col-offset-2.ant-col-rtl {
  margin-left: 0;
  margin-right: 8.33333%;
}

.ant-col-push-3.ant-col-rtl {
  left: auto;
  right: 12.5%;
}

.ant-col-pull-3.ant-col-rtl {
  left: 12.5%;
  right: auto;
}

.ant-col-offset-3.ant-col-rtl {
  margin-left: 0;
  margin-right: 12.5%;
}

.ant-col-push-4.ant-col-rtl {
  left: auto;
  right: 16.6667%;
}

.ant-col-pull-4.ant-col-rtl {
  left: 16.6667%;
  right: auto;
}

.ant-col-offset-4.ant-col-rtl {
  margin-left: 0;
  margin-right: 16.6667%;
}

.ant-col-push-5.ant-col-rtl {
  left: auto;
  right: 20.8333%;
}

.ant-col-pull-5.ant-col-rtl {
  left: 20.8333%;
  right: auto;
}

.ant-col-offset-5.ant-col-rtl {
  margin-left: 0;
  margin-right: 20.8333%;
}

.ant-col-push-6.ant-col-rtl {
  left: auto;
  right: 25%;
}

.ant-col-pull-6.ant-col-rtl {
  left: 25%;
  right: auto;
}

.ant-col-offset-6.ant-col-rtl {
  margin-left: 0;
  margin-right: 25%;
}

.ant-col-push-7.ant-col-rtl {
  left: auto;
  right: 29.1667%;
}

.ant-col-pull-7.ant-col-rtl {
  left: 29.1667%;
  right: auto;
}

.ant-col-offset-7.ant-col-rtl {
  margin-left: 0;
  margin-right: 29.1667%;
}

.ant-col-push-8.ant-col-rtl {
  left: auto;
  right: 33.3333%;
}

.ant-col-pull-8.ant-col-rtl {
  left: 33.3333%;
  right: auto;
}

.ant-col-offset-8.ant-col-rtl {
  margin-left: 0;
  margin-right: 33.3333%;
}

.ant-col-push-9.ant-col-rtl {
  left: auto;
  right: 37.5%;
}

.ant-col-pull-9.ant-col-rtl {
  left: 37.5%;
  right: auto;
}

.ant-col-offset-9.ant-col-rtl {
  margin-left: 0;
  margin-right: 37.5%;
}

.ant-col-push-10.ant-col-rtl {
  left: auto;
  right: 41.6667%;
}

.ant-col-pull-10.ant-col-rtl {
  left: 41.6667%;
  right: auto;
}

.ant-col-offset-10.ant-col-rtl {
  margin-left: 0;
  margin-right: 41.6667%;
}

.ant-col-push-11.ant-col-rtl {
  left: auto;
  right: 45.8333%;
}

.ant-col-pull-11.ant-col-rtl {
  left: 45.8333%;
  right: auto;
}

.ant-col-offset-11.ant-col-rtl {
  margin-left: 0;
  margin-right: 45.8333%;
}

.ant-col-push-12.ant-col-rtl {
  left: auto;
  right: 50%;
}

.ant-col-pull-12.ant-col-rtl {
  left: 50%;
  right: auto;
}

.ant-col-offset-12.ant-col-rtl {
  margin-left: 0;
  margin-right: 50%;
}

.ant-col-push-13.ant-col-rtl {
  left: auto;
  right: 54.1667%;
}

.ant-col-pull-13.ant-col-rtl {
  left: 54.1667%;
  right: auto;
}

.ant-col-offset-13.ant-col-rtl {
  margin-left: 0;
  margin-right: 54.1667%;
}

.ant-col-push-14.ant-col-rtl {
  left: auto;
  right: 58.3333%;
}

.ant-col-pull-14.ant-col-rtl {
  left: 58.3333%;
  right: auto;
}

.ant-col-offset-14.ant-col-rtl {
  margin-left: 0;
  margin-right: 58.3333%;
}

.ant-col-push-15.ant-col-rtl {
  left: auto;
  right: 62.5%;
}

.ant-col-pull-15.ant-col-rtl {
  left: 62.5%;
  right: auto;
}

.ant-col-offset-15.ant-col-rtl {
  margin-left: 0;
  margin-right: 62.5%;
}

.ant-col-push-16.ant-col-rtl {
  left: auto;
  right: 66.6667%;
}

.ant-col-pull-16.ant-col-rtl {
  left: 66.6667%;
  right: auto;
}

.ant-col-offset-16.ant-col-rtl {
  margin-left: 0;
  margin-right: 66.6667%;
}

.ant-col-push-17.ant-col-rtl {
  left: auto;
  right: 70.8333%;
}

.ant-col-pull-17.ant-col-rtl {
  left: 70.8333%;
  right: auto;
}

.ant-col-offset-17.ant-col-rtl {
  margin-left: 0;
  margin-right: 70.8333%;
}

.ant-col-push-18.ant-col-rtl {
  left: auto;
  right: 75%;
}

.ant-col-pull-18.ant-col-rtl {
  left: 75%;
  right: auto;
}

.ant-col-offset-18.ant-col-rtl {
  margin-left: 0;
  margin-right: 75%;
}

.ant-col-push-19.ant-col-rtl {
  left: auto;
  right: 79.1667%;
}

.ant-col-pull-19.ant-col-rtl {
  left: 79.1667%;
  right: auto;
}

.ant-col-offset-19.ant-col-rtl {
  margin-left: 0;
  margin-right: 79.1667%;
}

.ant-col-push-20.ant-col-rtl {
  left: auto;
  right: 83.3333%;
}

.ant-col-pull-20.ant-col-rtl {
  left: 83.3333%;
  right: auto;
}

.ant-col-offset-20.ant-col-rtl {
  margin-left: 0;
  margin-right: 83.3333%;
}

.ant-col-push-21.ant-col-rtl {
  left: auto;
  right: 87.5%;
}

.ant-col-pull-21.ant-col-rtl {
  left: 87.5%;
  right: auto;
}

.ant-col-offset-21.ant-col-rtl {
  margin-left: 0;
  margin-right: 87.5%;
}

.ant-col-push-22.ant-col-rtl {
  left: auto;
  right: 91.6667%;
}

.ant-col-pull-22.ant-col-rtl {
  left: 91.6667%;
  right: auto;
}

.ant-col-offset-22.ant-col-rtl {
  margin-left: 0;
  margin-right: 91.6667%;
}

.ant-col-push-23.ant-col-rtl {
  left: auto;
  right: 95.8333%;
}

.ant-col-pull-23.ant-col-rtl {
  left: 95.8333%;
  right: auto;
}

.ant-col-offset-23.ant-col-rtl {
  margin-left: 0;
  margin-right: 95.8333%;
}

.ant-col-push-24.ant-col-rtl {
  left: auto;
  right: 100%;
}

.ant-col-pull-24.ant-col-rtl {
  left: 100%;
  right: auto;
}

.ant-col-offset-24.ant-col-rtl {
  margin-left: 0;
  margin-right: 100%;
}

.ant-col-xs-24 {
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}

.ant-col-xs-push-24 {
  left: 100%;
}

.ant-col-xs-pull-24 {
  right: 100%;
}

.ant-col-xs-offset-24 {
  margin-left: 100%;
}

.ant-col-xs-order-24 {
  order: 24;
}

.ant-col-xs-23 {
  flex: 0 0 95.8333%;
  max-width: 95.8333%;
  display: block;
}

.ant-col-xs-push-23 {
  left: 95.8333%;
}

.ant-col-xs-pull-23 {
  right: 95.8333%;
}

.ant-col-xs-offset-23 {
  margin-left: 95.8333%;
}

.ant-col-xs-order-23 {
  order: 23;
}

.ant-col-xs-22 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
  display: block;
}

.ant-col-xs-push-22 {
  left: 91.6667%;
}

.ant-col-xs-pull-22 {
  right: 91.6667%;
}

.ant-col-xs-offset-22 {
  margin-left: 91.6667%;
}

.ant-col-xs-order-22 {
  order: 22;
}

.ant-col-xs-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
  display: block;
}

.ant-col-xs-push-21 {
  left: 87.5%;
}

.ant-col-xs-pull-21 {
  right: 87.5%;
}

.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}

.ant-col-xs-order-21 {
  order: 21;
}

.ant-col-xs-20 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
  display: block;
}

.ant-col-xs-push-20 {
  left: 83.3333%;
}

.ant-col-xs-pull-20 {
  right: 83.3333%;
}

.ant-col-xs-offset-20 {
  margin-left: 83.3333%;
}

.ant-col-xs-order-20 {
  order: 20;
}

.ant-col-xs-19 {
  flex: 0 0 79.1667%;
  max-width: 79.1667%;
  display: block;
}

.ant-col-xs-push-19 {
  left: 79.1667%;
}

.ant-col-xs-pull-19 {
  right: 79.1667%;
}

.ant-col-xs-offset-19 {
  margin-left: 79.1667%;
}

.ant-col-xs-order-19 {
  order: 19;
}

.ant-col-xs-18 {
  flex: 0 0 75%;
  max-width: 75%;
  display: block;
}

.ant-col-xs-push-18 {
  left: 75%;
}

.ant-col-xs-pull-18 {
  right: 75%;
}

.ant-col-xs-offset-18 {
  margin-left: 75%;
}

.ant-col-xs-order-18 {
  order: 18;
}

.ant-col-xs-17 {
  flex: 0 0 70.8333%;
  max-width: 70.8333%;
  display: block;
}

.ant-col-xs-push-17 {
  left: 70.8333%;
}

.ant-col-xs-pull-17 {
  right: 70.8333%;
}

.ant-col-xs-offset-17 {
  margin-left: 70.8333%;
}

.ant-col-xs-order-17 {
  order: 17;
}

.ant-col-xs-16 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
  display: block;
}

.ant-col-xs-push-16 {
  left: 66.6667%;
}

.ant-col-xs-pull-16 {
  right: 66.6667%;
}

.ant-col-xs-offset-16 {
  margin-left: 66.6667%;
}

.ant-col-xs-order-16 {
  order: 16;
}

.ant-col-xs-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
  display: block;
}

.ant-col-xs-push-15 {
  left: 62.5%;
}

.ant-col-xs-pull-15 {
  right: 62.5%;
}

.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}

.ant-col-xs-order-15 {
  order: 15;
}

.ant-col-xs-14 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
  display: block;
}

.ant-col-xs-push-14 {
  left: 58.3333%;
}

.ant-col-xs-pull-14 {
  right: 58.3333%;
}

.ant-col-xs-offset-14 {
  margin-left: 58.3333%;
}

.ant-col-xs-order-14 {
  order: 14;
}

.ant-col-xs-13 {
  flex: 0 0 54.1667%;
  max-width: 54.1667%;
  display: block;
}

.ant-col-xs-push-13 {
  left: 54.1667%;
}

.ant-col-xs-pull-13 {
  right: 54.1667%;
}

.ant-col-xs-offset-13 {
  margin-left: 54.1667%;
}

.ant-col-xs-order-13 {
  order: 13;
}

.ant-col-xs-12 {
  flex: 0 0 50%;
  max-width: 50%;
  display: block;
}

.ant-col-xs-push-12 {
  left: 50%;
}

.ant-col-xs-pull-12 {
  right: 50%;
}

.ant-col-xs-offset-12 {
  margin-left: 50%;
}

.ant-col-xs-order-12 {
  order: 12;
}

.ant-col-xs-11 {
  flex: 0 0 45.8333%;
  max-width: 45.8333%;
  display: block;
}

.ant-col-xs-push-11 {
  left: 45.8333%;
}

.ant-col-xs-pull-11 {
  right: 45.8333%;
}

.ant-col-xs-offset-11 {
  margin-left: 45.8333%;
}

.ant-col-xs-order-11 {
  order: 11;
}

.ant-col-xs-10 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
  display: block;
}

.ant-col-xs-push-10 {
  left: 41.6667%;
}

.ant-col-xs-pull-10 {
  right: 41.6667%;
}

.ant-col-xs-offset-10 {
  margin-left: 41.6667%;
}

.ant-col-xs-order-10 {
  order: 10;
}

.ant-col-xs-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
  display: block;
}

.ant-col-xs-push-9 {
  left: 37.5%;
}

.ant-col-xs-pull-9 {
  right: 37.5%;
}

.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}

.ant-col-xs-order-9 {
  order: 9;
}

.ant-col-xs-8 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  display: block;
}

.ant-col-xs-push-8 {
  left: 33.3333%;
}

.ant-col-xs-pull-8 {
  right: 33.3333%;
}

.ant-col-xs-offset-8 {
  margin-left: 33.3333%;
}

.ant-col-xs-order-8 {
  order: 8;
}

.ant-col-xs-7 {
  flex: 0 0 29.1667%;
  max-width: 29.1667%;
  display: block;
}

.ant-col-xs-push-7 {
  left: 29.1667%;
}

.ant-col-xs-pull-7 {
  right: 29.1667%;
}

.ant-col-xs-offset-7 {
  margin-left: 29.1667%;
}

.ant-col-xs-order-7 {
  order: 7;
}

.ant-col-xs-6 {
  flex: 0 0 25%;
  max-width: 25%;
  display: block;
}

.ant-col-xs-push-6 {
  left: 25%;
}

.ant-col-xs-pull-6 {
  right: 25%;
}

.ant-col-xs-offset-6 {
  margin-left: 25%;
}

.ant-col-xs-order-6 {
  order: 6;
}

.ant-col-xs-5 {
  flex: 0 0 20.8333%;
  max-width: 20.8333%;
  display: block;
}

.ant-col-xs-push-5 {
  left: 20.8333%;
}

.ant-col-xs-pull-5 {
  right: 20.8333%;
}

.ant-col-xs-offset-5 {
  margin-left: 20.8333%;
}

.ant-col-xs-order-5 {
  order: 5;
}

.ant-col-xs-4 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
  display: block;
}

.ant-col-xs-push-4 {
  left: 16.6667%;
}

.ant-col-xs-pull-4 {
  right: 16.6667%;
}

.ant-col-xs-offset-4 {
  margin-left: 16.6667%;
}

.ant-col-xs-order-4 {
  order: 4;
}

.ant-col-xs-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  display: block;
}

.ant-col-xs-push-3 {
  left: 12.5%;
}

.ant-col-xs-pull-3 {
  right: 12.5%;
}

.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}

.ant-col-xs-order-3 {
  order: 3;
}

.ant-col-xs-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  display: block;
}

.ant-col-xs-push-2 {
  left: 8.33333%;
}

.ant-col-xs-pull-2 {
  right: 8.33333%;
}

.ant-col-xs-offset-2 {
  margin-left: 8.33333%;
}

.ant-col-xs-order-2 {
  order: 2;
}

.ant-col-xs-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%;
  display: block;
}

.ant-col-xs-push-1 {
  left: 4.16667%;
}

.ant-col-xs-pull-1 {
  right: 4.16667%;
}

.ant-col-xs-offset-1 {
  margin-left: 4.16667%;
}

.ant-col-xs-order-1 {
  order: 1;
}

.ant-col-xs-0 {
  display: none;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-xs-push-0 {
  left: auto;
}

.ant-col-xs-pull-0 {
  right: auto;
}

.ant-col-xs-offset-0 {
  margin-left: 0;
}

.ant-col-xs-order-0 {
  order: 0;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-xs-push-1.ant-col-rtl {
  left: auto;
  right: 4.16667%;
}

.ant-col-xs-pull-1.ant-col-rtl {
  left: 4.16667%;
  right: auto;
}

.ant-col-xs-offset-1.ant-col-rtl {
  margin-left: 0;
  margin-right: 4.16667%;
}

.ant-col-xs-push-2.ant-col-rtl {
  left: auto;
  right: 8.33333%;
}

.ant-col-xs-pull-2.ant-col-rtl {
  left: 8.33333%;
  right: auto;
}

.ant-col-xs-offset-2.ant-col-rtl {
  margin-left: 0;
  margin-right: 8.33333%;
}

.ant-col-xs-push-3.ant-col-rtl {
  left: auto;
  right: 12.5%;
}

.ant-col-xs-pull-3.ant-col-rtl {
  left: 12.5%;
  right: auto;
}

.ant-col-xs-offset-3.ant-col-rtl {
  margin-left: 0;
  margin-right: 12.5%;
}

.ant-col-xs-push-4.ant-col-rtl {
  left: auto;
  right: 16.6667%;
}

.ant-col-xs-pull-4.ant-col-rtl {
  left: 16.6667%;
  right: auto;
}

.ant-col-xs-offset-4.ant-col-rtl {
  margin-left: 0;
  margin-right: 16.6667%;
}

.ant-col-xs-push-5.ant-col-rtl {
  left: auto;
  right: 20.8333%;
}

.ant-col-xs-pull-5.ant-col-rtl {
  left: 20.8333%;
  right: auto;
}

.ant-col-xs-offset-5.ant-col-rtl {
  margin-left: 0;
  margin-right: 20.8333%;
}

.ant-col-xs-push-6.ant-col-rtl {
  left: auto;
  right: 25%;
}

.ant-col-xs-pull-6.ant-col-rtl {
  left: 25%;
  right: auto;
}

.ant-col-xs-offset-6.ant-col-rtl {
  margin-left: 0;
  margin-right: 25%;
}

.ant-col-xs-push-7.ant-col-rtl {
  left: auto;
  right: 29.1667%;
}

.ant-col-xs-pull-7.ant-col-rtl {
  left: 29.1667%;
  right: auto;
}

.ant-col-xs-offset-7.ant-col-rtl {
  margin-left: 0;
  margin-right: 29.1667%;
}

.ant-col-xs-push-8.ant-col-rtl {
  left: auto;
  right: 33.3333%;
}

.ant-col-xs-pull-8.ant-col-rtl {
  left: 33.3333%;
  right: auto;
}

.ant-col-xs-offset-8.ant-col-rtl {
  margin-left: 0;
  margin-right: 33.3333%;
}

.ant-col-xs-push-9.ant-col-rtl {
  left: auto;
  right: 37.5%;
}

.ant-col-xs-pull-9.ant-col-rtl {
  left: 37.5%;
  right: auto;
}

.ant-col-xs-offset-9.ant-col-rtl {
  margin-left: 0;
  margin-right: 37.5%;
}

.ant-col-xs-push-10.ant-col-rtl {
  left: auto;
  right: 41.6667%;
}

.ant-col-xs-pull-10.ant-col-rtl {
  left: 41.6667%;
  right: auto;
}

.ant-col-xs-offset-10.ant-col-rtl {
  margin-left: 0;
  margin-right: 41.6667%;
}

.ant-col-xs-push-11.ant-col-rtl {
  left: auto;
  right: 45.8333%;
}

.ant-col-xs-pull-11.ant-col-rtl {
  left: 45.8333%;
  right: auto;
}

.ant-col-xs-offset-11.ant-col-rtl {
  margin-left: 0;
  margin-right: 45.8333%;
}

.ant-col-xs-push-12.ant-col-rtl {
  left: auto;
  right: 50%;
}

.ant-col-xs-pull-12.ant-col-rtl {
  left: 50%;
  right: auto;
}

.ant-col-xs-offset-12.ant-col-rtl {
  margin-left: 0;
  margin-right: 50%;
}

.ant-col-xs-push-13.ant-col-rtl {
  left: auto;
  right: 54.1667%;
}

.ant-col-xs-pull-13.ant-col-rtl {
  left: 54.1667%;
  right: auto;
}

.ant-col-xs-offset-13.ant-col-rtl {
  margin-left: 0;
  margin-right: 54.1667%;
}

.ant-col-xs-push-14.ant-col-rtl {
  left: auto;
  right: 58.3333%;
}

.ant-col-xs-pull-14.ant-col-rtl {
  left: 58.3333%;
  right: auto;
}

.ant-col-xs-offset-14.ant-col-rtl {
  margin-left: 0;
  margin-right: 58.3333%;
}

.ant-col-xs-push-15.ant-col-rtl {
  left: auto;
  right: 62.5%;
}

.ant-col-xs-pull-15.ant-col-rtl {
  left: 62.5%;
  right: auto;
}

.ant-col-xs-offset-15.ant-col-rtl {
  margin-left: 0;
  margin-right: 62.5%;
}

.ant-col-xs-push-16.ant-col-rtl {
  left: auto;
  right: 66.6667%;
}

.ant-col-xs-pull-16.ant-col-rtl {
  left: 66.6667%;
  right: auto;
}

.ant-col-xs-offset-16.ant-col-rtl {
  margin-left: 0;
  margin-right: 66.6667%;
}

.ant-col-xs-push-17.ant-col-rtl {
  left: auto;
  right: 70.8333%;
}

.ant-col-xs-pull-17.ant-col-rtl {
  left: 70.8333%;
  right: auto;
}

.ant-col-xs-offset-17.ant-col-rtl {
  margin-left: 0;
  margin-right: 70.8333%;
}

.ant-col-xs-push-18.ant-col-rtl {
  left: auto;
  right: 75%;
}

.ant-col-xs-pull-18.ant-col-rtl {
  left: 75%;
  right: auto;
}

.ant-col-xs-offset-18.ant-col-rtl {
  margin-left: 0;
  margin-right: 75%;
}

.ant-col-xs-push-19.ant-col-rtl {
  left: auto;
  right: 79.1667%;
}

.ant-col-xs-pull-19.ant-col-rtl {
  left: 79.1667%;
  right: auto;
}

.ant-col-xs-offset-19.ant-col-rtl {
  margin-left: 0;
  margin-right: 79.1667%;
}

.ant-col-xs-push-20.ant-col-rtl {
  left: auto;
  right: 83.3333%;
}

.ant-col-xs-pull-20.ant-col-rtl {
  left: 83.3333%;
  right: auto;
}

.ant-col-xs-offset-20.ant-col-rtl {
  margin-left: 0;
  margin-right: 83.3333%;
}

.ant-col-xs-push-21.ant-col-rtl {
  left: auto;
  right: 87.5%;
}

.ant-col-xs-pull-21.ant-col-rtl {
  left: 87.5%;
  right: auto;
}

.ant-col-xs-offset-21.ant-col-rtl {
  margin-left: 0;
  margin-right: 87.5%;
}

.ant-col-xs-push-22.ant-col-rtl {
  left: auto;
  right: 91.6667%;
}

.ant-col-xs-pull-22.ant-col-rtl {
  left: 91.6667%;
  right: auto;
}

.ant-col-xs-offset-22.ant-col-rtl {
  margin-left: 0;
  margin-right: 91.6667%;
}

.ant-col-xs-push-23.ant-col-rtl {
  left: auto;
  right: 95.8333%;
}

.ant-col-xs-pull-23.ant-col-rtl {
  left: 95.8333%;
  right: auto;
}

.ant-col-xs-offset-23.ant-col-rtl {
  margin-left: 0;
  margin-right: 95.8333%;
}

.ant-col-xs-push-24.ant-col-rtl {
  left: auto;
  right: 100%;
}

.ant-col-xs-pull-24.ant-col-rtl {
  left: 100%;
  right: auto;
}

.ant-col-xs-offset-24.ant-col-rtl {
  margin-left: 0;
  margin-right: 100%;
}

@media (width >= 576px) {
  .ant-col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-sm-push-24 {
    left: 100%;
  }

  .ant-col-sm-pull-24 {
    right: 100%;
  }

  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }

  .ant-col-sm-order-24 {
    order: 24;
  }

  .ant-col-sm-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-sm-push-23 {
    left: 95.8333%;
  }

  .ant-col-sm-pull-23 {
    right: 95.8333%;
  }

  .ant-col-sm-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-sm-order-23 {
    order: 23;
  }

  .ant-col-sm-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-sm-push-22 {
    left: 91.6667%;
  }

  .ant-col-sm-pull-22 {
    right: 91.6667%;
  }

  .ant-col-sm-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-sm-order-22 {
    order: 22;
  }

  .ant-col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-sm-push-21 {
    left: 87.5%;
  }

  .ant-col-sm-pull-21 {
    right: 87.5%;
  }

  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-sm-order-21 {
    order: 21;
  }

  .ant-col-sm-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-sm-push-20 {
    left: 83.3333%;
  }

  .ant-col-sm-pull-20 {
    right: 83.3333%;
  }

  .ant-col-sm-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-sm-order-20 {
    order: 20;
  }

  .ant-col-sm-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-sm-push-19 {
    left: 79.1667%;
  }

  .ant-col-sm-pull-19 {
    right: 79.1667%;
  }

  .ant-col-sm-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-sm-order-19 {
    order: 19;
  }

  .ant-col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-sm-push-18 {
    left: 75%;
  }

  .ant-col-sm-pull-18 {
    right: 75%;
  }

  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }

  .ant-col-sm-order-18 {
    order: 18;
  }

  .ant-col-sm-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-sm-push-17 {
    left: 70.8333%;
  }

  .ant-col-sm-pull-17 {
    right: 70.8333%;
  }

  .ant-col-sm-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-sm-order-17 {
    order: 17;
  }

  .ant-col-sm-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-sm-push-16 {
    left: 66.6667%;
  }

  .ant-col-sm-pull-16 {
    right: 66.6667%;
  }

  .ant-col-sm-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-sm-order-16 {
    order: 16;
  }

  .ant-col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-sm-push-15 {
    left: 62.5%;
  }

  .ant-col-sm-pull-15 {
    right: 62.5%;
  }

  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-sm-order-15 {
    order: 15;
  }

  .ant-col-sm-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-sm-push-14 {
    left: 58.3333%;
  }

  .ant-col-sm-pull-14 {
    right: 58.3333%;
  }

  .ant-col-sm-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-sm-order-14 {
    order: 14;
  }

  .ant-col-sm-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-sm-push-13 {
    left: 54.1667%;
  }

  .ant-col-sm-pull-13 {
    right: 54.1667%;
  }

  .ant-col-sm-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-sm-order-13 {
    order: 13;
  }

  .ant-col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-sm-push-12 {
    left: 50%;
  }

  .ant-col-sm-pull-12 {
    right: 50%;
  }

  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }

  .ant-col-sm-order-12 {
    order: 12;
  }

  .ant-col-sm-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-sm-push-11 {
    left: 45.8333%;
  }

  .ant-col-sm-pull-11 {
    right: 45.8333%;
  }

  .ant-col-sm-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-sm-order-11 {
    order: 11;
  }

  .ant-col-sm-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-sm-push-10 {
    left: 41.6667%;
  }

  .ant-col-sm-pull-10 {
    right: 41.6667%;
  }

  .ant-col-sm-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-sm-order-10 {
    order: 10;
  }

  .ant-col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-sm-push-9 {
    left: 37.5%;
  }

  .ant-col-sm-pull-9 {
    right: 37.5%;
  }

  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-sm-order-9 {
    order: 9;
  }

  .ant-col-sm-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-sm-push-8 {
    left: 33.3333%;
  }

  .ant-col-sm-pull-8 {
    right: 33.3333%;
  }

  .ant-col-sm-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-sm-order-8 {
    order: 8;
  }

  .ant-col-sm-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-sm-push-7 {
    left: 29.1667%;
  }

  .ant-col-sm-pull-7 {
    right: 29.1667%;
  }

  .ant-col-sm-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-sm-order-7 {
    order: 7;
  }

  .ant-col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-sm-push-6 {
    left: 25%;
  }

  .ant-col-sm-pull-6 {
    right: 25%;
  }

  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }

  .ant-col-sm-order-6 {
    order: 6;
  }

  .ant-col-sm-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-sm-push-5 {
    left: 20.8333%;
  }

  .ant-col-sm-pull-5 {
    right: 20.8333%;
  }

  .ant-col-sm-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-sm-order-5 {
    order: 5;
  }

  .ant-col-sm-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-sm-push-4 {
    left: 16.6667%;
  }

  .ant-col-sm-pull-4 {
    right: 16.6667%;
  }

  .ant-col-sm-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-sm-order-4 {
    order: 4;
  }

  .ant-col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-sm-push-3 {
    left: 12.5%;
  }

  .ant-col-sm-pull-3 {
    right: 12.5%;
  }

  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-sm-order-3 {
    order: 3;
  }

  .ant-col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-sm-push-2 {
    left: 8.33333%;
  }

  .ant-col-sm-pull-2 {
    right: 8.33333%;
  }

  .ant-col-sm-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-sm-order-2 {
    order: 2;
  }

  .ant-col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-sm-push-1 {
    left: 4.16667%;
  }

  .ant-col-sm-pull-1 {
    right: 4.16667%;
  }

  .ant-col-sm-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-sm-order-1 {
    order: 1;
  }

  .ant-col-sm-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-sm-push-0 {
    left: auto;
  }

  .ant-col-sm-pull-0 {
    right: auto;
  }

  .ant-col-sm-offset-0 {
    margin-left: 0;
  }

  .ant-col-sm-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-sm-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-sm-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-sm-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-sm-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-sm-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-sm-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-sm-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-sm-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-sm-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-sm-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-sm-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-sm-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-sm-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-sm-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-sm-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-sm-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-sm-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-sm-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-sm-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-sm-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-sm-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-sm-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-sm-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-sm-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-sm-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-sm-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-sm-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-sm-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-sm-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-sm-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-sm-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-sm-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-sm-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-sm-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-sm-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-sm-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-sm-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-sm-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-sm-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-sm-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-sm-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-sm-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-sm-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-sm-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-sm-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-sm-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-sm-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-sm-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-sm-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-sm-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-sm-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-sm-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-sm-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-sm-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-sm-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-sm-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-sm-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-sm-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-sm-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-sm-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-sm-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-sm-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-sm-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-sm-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-sm-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-sm-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-sm-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-sm-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-sm-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-sm-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-sm-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-sm-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 768px) {
  .ant-col-md-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-md-push-24 {
    left: 100%;
  }

  .ant-col-md-pull-24 {
    right: 100%;
  }

  .ant-col-md-offset-24 {
    margin-left: 100%;
  }

  .ant-col-md-order-24 {
    order: 24;
  }

  .ant-col-md-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-md-push-23 {
    left: 95.8333%;
  }

  .ant-col-md-pull-23 {
    right: 95.8333%;
  }

  .ant-col-md-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-md-order-23 {
    order: 23;
  }

  .ant-col-md-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-md-push-22 {
    left: 91.6667%;
  }

  .ant-col-md-pull-22 {
    right: 91.6667%;
  }

  .ant-col-md-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-md-order-22 {
    order: 22;
  }

  .ant-col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-md-push-21 {
    left: 87.5%;
  }

  .ant-col-md-pull-21 {
    right: 87.5%;
  }

  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-md-order-21 {
    order: 21;
  }

  .ant-col-md-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-md-push-20 {
    left: 83.3333%;
  }

  .ant-col-md-pull-20 {
    right: 83.3333%;
  }

  .ant-col-md-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-md-order-20 {
    order: 20;
  }

  .ant-col-md-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-md-push-19 {
    left: 79.1667%;
  }

  .ant-col-md-pull-19 {
    right: 79.1667%;
  }

  .ant-col-md-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-md-order-19 {
    order: 19;
  }

  .ant-col-md-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-md-push-18 {
    left: 75%;
  }

  .ant-col-md-pull-18 {
    right: 75%;
  }

  .ant-col-md-offset-18 {
    margin-left: 75%;
  }

  .ant-col-md-order-18 {
    order: 18;
  }

  .ant-col-md-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-md-push-17 {
    left: 70.8333%;
  }

  .ant-col-md-pull-17 {
    right: 70.8333%;
  }

  .ant-col-md-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-md-order-17 {
    order: 17;
  }

  .ant-col-md-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-md-push-16 {
    left: 66.6667%;
  }

  .ant-col-md-pull-16 {
    right: 66.6667%;
  }

  .ant-col-md-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-md-order-16 {
    order: 16;
  }

  .ant-col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-md-push-15 {
    left: 62.5%;
  }

  .ant-col-md-pull-15 {
    right: 62.5%;
  }

  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-md-order-15 {
    order: 15;
  }

  .ant-col-md-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-md-push-14 {
    left: 58.3333%;
  }

  .ant-col-md-pull-14 {
    right: 58.3333%;
  }

  .ant-col-md-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-md-order-14 {
    order: 14;
  }

  .ant-col-md-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-md-push-13 {
    left: 54.1667%;
  }

  .ant-col-md-pull-13 {
    right: 54.1667%;
  }

  .ant-col-md-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-md-order-13 {
    order: 13;
  }

  .ant-col-md-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-md-push-12 {
    left: 50%;
  }

  .ant-col-md-pull-12 {
    right: 50%;
  }

  .ant-col-md-offset-12 {
    margin-left: 50%;
  }

  .ant-col-md-order-12 {
    order: 12;
  }

  .ant-col-md-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-md-push-11 {
    left: 45.8333%;
  }

  .ant-col-md-pull-11 {
    right: 45.8333%;
  }

  .ant-col-md-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-md-order-11 {
    order: 11;
  }

  .ant-col-md-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-md-push-10 {
    left: 41.6667%;
  }

  .ant-col-md-pull-10 {
    right: 41.6667%;
  }

  .ant-col-md-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-md-order-10 {
    order: 10;
  }

  .ant-col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-md-push-9 {
    left: 37.5%;
  }

  .ant-col-md-pull-9 {
    right: 37.5%;
  }

  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-md-order-9 {
    order: 9;
  }

  .ant-col-md-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-md-push-8 {
    left: 33.3333%;
  }

  .ant-col-md-pull-8 {
    right: 33.3333%;
  }

  .ant-col-md-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-md-order-8 {
    order: 8;
  }

  .ant-col-md-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-md-push-7 {
    left: 29.1667%;
  }

  .ant-col-md-pull-7 {
    right: 29.1667%;
  }

  .ant-col-md-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-md-order-7 {
    order: 7;
  }

  .ant-col-md-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-md-push-6 {
    left: 25%;
  }

  .ant-col-md-pull-6 {
    right: 25%;
  }

  .ant-col-md-offset-6 {
    margin-left: 25%;
  }

  .ant-col-md-order-6 {
    order: 6;
  }

  .ant-col-md-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-md-push-5 {
    left: 20.8333%;
  }

  .ant-col-md-pull-5 {
    right: 20.8333%;
  }

  .ant-col-md-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-md-order-5 {
    order: 5;
  }

  .ant-col-md-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-md-push-4 {
    left: 16.6667%;
  }

  .ant-col-md-pull-4 {
    right: 16.6667%;
  }

  .ant-col-md-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-md-order-4 {
    order: 4;
  }

  .ant-col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-md-push-3 {
    left: 12.5%;
  }

  .ant-col-md-pull-3 {
    right: 12.5%;
  }

  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-md-order-3 {
    order: 3;
  }

  .ant-col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-md-push-2 {
    left: 8.33333%;
  }

  .ant-col-md-pull-2 {
    right: 8.33333%;
  }

  .ant-col-md-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-md-order-2 {
    order: 2;
  }

  .ant-col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-md-push-1 {
    left: 4.16667%;
  }

  .ant-col-md-pull-1 {
    right: 4.16667%;
  }

  .ant-col-md-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-md-order-1 {
    order: 1;
  }

  .ant-col-md-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-md-push-0 {
    left: auto;
  }

  .ant-col-md-pull-0 {
    right: auto;
  }

  .ant-col-md-offset-0 {
    margin-left: 0;
  }

  .ant-col-md-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-md-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-md-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-md-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-md-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-md-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-md-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-md-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-md-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-md-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-md-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-md-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-md-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-md-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-md-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-md-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-md-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-md-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-md-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-md-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-md-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-md-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-md-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-md-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-md-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-md-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-md-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-md-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-md-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-md-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-md-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-md-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-md-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-md-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-md-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-md-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-md-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-md-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-md-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-md-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-md-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-md-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-md-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-md-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-md-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-md-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-md-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-md-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-md-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-md-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-md-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-md-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-md-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-md-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-md-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-md-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-md-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-md-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-md-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-md-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-md-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-md-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-md-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-md-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-md-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-md-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-md-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-md-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-md-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-md-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-md-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-md-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-md-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 992px) {
  .ant-col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-lg-push-24 {
    left: 100%;
  }

  .ant-col-lg-pull-24 {
    right: 100%;
  }

  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }

  .ant-col-lg-order-24 {
    order: 24;
  }

  .ant-col-lg-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-lg-push-23 {
    left: 95.8333%;
  }

  .ant-col-lg-pull-23 {
    right: 95.8333%;
  }

  .ant-col-lg-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-lg-order-23 {
    order: 23;
  }

  .ant-col-lg-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-lg-push-22 {
    left: 91.6667%;
  }

  .ant-col-lg-pull-22 {
    right: 91.6667%;
  }

  .ant-col-lg-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-lg-order-22 {
    order: 22;
  }

  .ant-col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-lg-push-21 {
    left: 87.5%;
  }

  .ant-col-lg-pull-21 {
    right: 87.5%;
  }

  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-lg-order-21 {
    order: 21;
  }

  .ant-col-lg-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-lg-push-20 {
    left: 83.3333%;
  }

  .ant-col-lg-pull-20 {
    right: 83.3333%;
  }

  .ant-col-lg-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-lg-order-20 {
    order: 20;
  }

  .ant-col-lg-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-lg-push-19 {
    left: 79.1667%;
  }

  .ant-col-lg-pull-19 {
    right: 79.1667%;
  }

  .ant-col-lg-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-lg-order-19 {
    order: 19;
  }

  .ant-col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-lg-push-18 {
    left: 75%;
  }

  .ant-col-lg-pull-18 {
    right: 75%;
  }

  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }

  .ant-col-lg-order-18 {
    order: 18;
  }

  .ant-col-lg-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-lg-push-17 {
    left: 70.8333%;
  }

  .ant-col-lg-pull-17 {
    right: 70.8333%;
  }

  .ant-col-lg-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-lg-order-17 {
    order: 17;
  }

  .ant-col-lg-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-lg-push-16 {
    left: 66.6667%;
  }

  .ant-col-lg-pull-16 {
    right: 66.6667%;
  }

  .ant-col-lg-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-lg-order-16 {
    order: 16;
  }

  .ant-col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-lg-push-15 {
    left: 62.5%;
  }

  .ant-col-lg-pull-15 {
    right: 62.5%;
  }

  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-lg-order-15 {
    order: 15;
  }

  .ant-col-lg-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-lg-push-14 {
    left: 58.3333%;
  }

  .ant-col-lg-pull-14 {
    right: 58.3333%;
  }

  .ant-col-lg-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-lg-order-14 {
    order: 14;
  }

  .ant-col-lg-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-lg-push-13 {
    left: 54.1667%;
  }

  .ant-col-lg-pull-13 {
    right: 54.1667%;
  }

  .ant-col-lg-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-lg-order-13 {
    order: 13;
  }

  .ant-col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-lg-push-12 {
    left: 50%;
  }

  .ant-col-lg-pull-12 {
    right: 50%;
  }

  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }

  .ant-col-lg-order-12 {
    order: 12;
  }

  .ant-col-lg-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-lg-push-11 {
    left: 45.8333%;
  }

  .ant-col-lg-pull-11 {
    right: 45.8333%;
  }

  .ant-col-lg-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-lg-order-11 {
    order: 11;
  }

  .ant-col-lg-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-lg-push-10 {
    left: 41.6667%;
  }

  .ant-col-lg-pull-10 {
    right: 41.6667%;
  }

  .ant-col-lg-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-lg-order-10 {
    order: 10;
  }

  .ant-col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-lg-push-9 {
    left: 37.5%;
  }

  .ant-col-lg-pull-9 {
    right: 37.5%;
  }

  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-lg-order-9 {
    order: 9;
  }

  .ant-col-lg-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-lg-push-8 {
    left: 33.3333%;
  }

  .ant-col-lg-pull-8 {
    right: 33.3333%;
  }

  .ant-col-lg-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-lg-order-8 {
    order: 8;
  }

  .ant-col-lg-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-lg-push-7 {
    left: 29.1667%;
  }

  .ant-col-lg-pull-7 {
    right: 29.1667%;
  }

  .ant-col-lg-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-lg-order-7 {
    order: 7;
  }

  .ant-col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-lg-push-6 {
    left: 25%;
  }

  .ant-col-lg-pull-6 {
    right: 25%;
  }

  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }

  .ant-col-lg-order-6 {
    order: 6;
  }

  .ant-col-lg-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-lg-push-5 {
    left: 20.8333%;
  }

  .ant-col-lg-pull-5 {
    right: 20.8333%;
  }

  .ant-col-lg-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-lg-order-5 {
    order: 5;
  }

  .ant-col-lg-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-lg-push-4 {
    left: 16.6667%;
  }

  .ant-col-lg-pull-4 {
    right: 16.6667%;
  }

  .ant-col-lg-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-lg-order-4 {
    order: 4;
  }

  .ant-col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-lg-push-3 {
    left: 12.5%;
  }

  .ant-col-lg-pull-3 {
    right: 12.5%;
  }

  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-lg-order-3 {
    order: 3;
  }

  .ant-col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-lg-push-2 {
    left: 8.33333%;
  }

  .ant-col-lg-pull-2 {
    right: 8.33333%;
  }

  .ant-col-lg-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-lg-order-2 {
    order: 2;
  }

  .ant-col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-lg-push-1 {
    left: 4.16667%;
  }

  .ant-col-lg-pull-1 {
    right: 4.16667%;
  }

  .ant-col-lg-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-lg-order-1 {
    order: 1;
  }

  .ant-col-lg-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-lg-push-0 {
    left: auto;
  }

  .ant-col-lg-pull-0 {
    right: auto;
  }

  .ant-col-lg-offset-0 {
    margin-left: 0;
  }

  .ant-col-lg-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-lg-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-lg-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-lg-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-lg-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-lg-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-lg-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-lg-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-lg-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-lg-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-lg-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-lg-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-lg-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-lg-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-lg-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-lg-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-lg-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-lg-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-lg-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-lg-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-lg-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-lg-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-lg-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-lg-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-lg-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-lg-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-lg-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-lg-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-lg-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-lg-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-lg-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-lg-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-lg-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-lg-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-lg-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-lg-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-lg-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-lg-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-lg-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-lg-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-lg-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-lg-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-lg-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-lg-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-lg-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-lg-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-lg-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-lg-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-lg-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-lg-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-lg-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-lg-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-lg-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-lg-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-lg-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-lg-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-lg-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-lg-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-lg-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-lg-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-lg-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-lg-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-lg-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-lg-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-lg-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-lg-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-lg-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-lg-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-lg-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-lg-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-lg-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-lg-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-lg-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 1200px) {
  .ant-col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-xl-push-24 {
    left: 100%;
  }

  .ant-col-xl-pull-24 {
    right: 100%;
  }

  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xl-order-24 {
    order: 24;
  }

  .ant-col-xl-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-xl-push-23 {
    left: 95.8333%;
  }

  .ant-col-xl-pull-23 {
    right: 95.8333%;
  }

  .ant-col-xl-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-xl-order-23 {
    order: 23;
  }

  .ant-col-xl-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-xl-push-22 {
    left: 91.6667%;
  }

  .ant-col-xl-pull-22 {
    right: 91.6667%;
  }

  .ant-col-xl-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-xl-order-22 {
    order: 22;
  }

  .ant-col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-xl-push-21 {
    left: 87.5%;
  }

  .ant-col-xl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xl-order-21 {
    order: 21;
  }

  .ant-col-xl-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-xl-push-20 {
    left: 83.3333%;
  }

  .ant-col-xl-pull-20 {
    right: 83.3333%;
  }

  .ant-col-xl-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-xl-order-20 {
    order: 20;
  }

  .ant-col-xl-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-xl-push-19 {
    left: 79.1667%;
  }

  .ant-col-xl-pull-19 {
    right: 79.1667%;
  }

  .ant-col-xl-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-xl-order-19 {
    order: 19;
  }

  .ant-col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-xl-push-18 {
    left: 75%;
  }

  .ant-col-xl-pull-18 {
    right: 75%;
  }

  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xl-order-18 {
    order: 18;
  }

  .ant-col-xl-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-xl-push-17 {
    left: 70.8333%;
  }

  .ant-col-xl-pull-17 {
    right: 70.8333%;
  }

  .ant-col-xl-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-xl-order-17 {
    order: 17;
  }

  .ant-col-xl-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-xl-push-16 {
    left: 66.6667%;
  }

  .ant-col-xl-pull-16 {
    right: 66.6667%;
  }

  .ant-col-xl-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-xl-order-16 {
    order: 16;
  }

  .ant-col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-xl-push-15 {
    left: 62.5%;
  }

  .ant-col-xl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xl-order-15 {
    order: 15;
  }

  .ant-col-xl-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-xl-push-14 {
    left: 58.3333%;
  }

  .ant-col-xl-pull-14 {
    right: 58.3333%;
  }

  .ant-col-xl-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-xl-order-14 {
    order: 14;
  }

  .ant-col-xl-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-xl-push-13 {
    left: 54.1667%;
  }

  .ant-col-xl-pull-13 {
    right: 54.1667%;
  }

  .ant-col-xl-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-xl-order-13 {
    order: 13;
  }

  .ant-col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-xl-push-12 {
    left: 50%;
  }

  .ant-col-xl-pull-12 {
    right: 50%;
  }

  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xl-order-12 {
    order: 12;
  }

  .ant-col-xl-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-xl-push-11 {
    left: 45.8333%;
  }

  .ant-col-xl-pull-11 {
    right: 45.8333%;
  }

  .ant-col-xl-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-xl-order-11 {
    order: 11;
  }

  .ant-col-xl-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-xl-push-10 {
    left: 41.6667%;
  }

  .ant-col-xl-pull-10 {
    right: 41.6667%;
  }

  .ant-col-xl-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-xl-order-10 {
    order: 10;
  }

  .ant-col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-xl-push-9 {
    left: 37.5%;
  }

  .ant-col-xl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xl-order-9 {
    order: 9;
  }

  .ant-col-xl-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-xl-push-8 {
    left: 33.3333%;
  }

  .ant-col-xl-pull-8 {
    right: 33.3333%;
  }

  .ant-col-xl-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-xl-order-8 {
    order: 8;
  }

  .ant-col-xl-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-xl-push-7 {
    left: 29.1667%;
  }

  .ant-col-xl-pull-7 {
    right: 29.1667%;
  }

  .ant-col-xl-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-xl-order-7 {
    order: 7;
  }

  .ant-col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-xl-push-6 {
    left: 25%;
  }

  .ant-col-xl-pull-6 {
    right: 25%;
  }

  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xl-order-6 {
    order: 6;
  }

  .ant-col-xl-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-xl-push-5 {
    left: 20.8333%;
  }

  .ant-col-xl-pull-5 {
    right: 20.8333%;
  }

  .ant-col-xl-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-xl-order-5 {
    order: 5;
  }

  .ant-col-xl-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-xl-push-4 {
    left: 16.6667%;
  }

  .ant-col-xl-pull-4 {
    right: 16.6667%;
  }

  .ant-col-xl-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-xl-order-4 {
    order: 4;
  }

  .ant-col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-xl-push-3 {
    left: 12.5%;
  }

  .ant-col-xl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xl-order-3 {
    order: 3;
  }

  .ant-col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-xl-push-2 {
    left: 8.33333%;
  }

  .ant-col-xl-pull-2 {
    right: 8.33333%;
  }

  .ant-col-xl-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-xl-order-2 {
    order: 2;
  }

  .ant-col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-xl-push-1 {
    left: 4.16667%;
  }

  .ant-col-xl-pull-1 {
    right: 4.16667%;
  }

  .ant-col-xl-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-xl-order-1 {
    order: 1;
  }

  .ant-col-xl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xl-push-0 {
    left: auto;
  }

  .ant-col-xl-pull-0 {
    right: auto;
  }

  .ant-col-xl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xl-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-xl-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-xl-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-xl-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-xl-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-xl-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-xl-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-xl-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-xl-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-xl-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-xl-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-xl-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-xl-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-xl-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-xl-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-xl-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-xl-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-xl-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-xl-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-xl-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-xl-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-xl-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-xl-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-xl-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-xl-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-xl-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-xl-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-xl-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-xl-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-xl-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-xl-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-xl-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-xl-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-xl-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-xl-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-xl-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-xl-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-xl-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-xl-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-xl-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-xl-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-xl-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-xl-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-xl-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-xl-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-xl-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-xl-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-xl-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-xl-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-xl-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-xl-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-xl-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-xl-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-xl-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-xl-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-xl-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-xl-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-xl-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-xl-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-xl-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-xl-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-xl-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-xl-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-xl-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-xl-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-xl-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-xl-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-xl-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-xl-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-xl-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-xl-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-xl-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

@media (width >= 1600px) {
  .ant-col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }

  .ant-col-xxl-push-24 {
    left: 100%;
  }

  .ant-col-xxl-pull-24 {
    right: 100%;
  }

  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xxl-order-24 {
    order: 24;
  }

  .ant-col-xxl-23 {
    flex: 0 0 95.8333%;
    max-width: 95.8333%;
    display: block;
  }

  .ant-col-xxl-push-23 {
    left: 95.8333%;
  }

  .ant-col-xxl-pull-23 {
    right: 95.8333%;
  }

  .ant-col-xxl-offset-23 {
    margin-left: 95.8333%;
  }

  .ant-col-xxl-order-23 {
    order: 23;
  }

  .ant-col-xxl-22 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
    display: block;
  }

  .ant-col-xxl-push-22 {
    left: 91.6667%;
  }

  .ant-col-xxl-pull-22 {
    right: 91.6667%;
  }

  .ant-col-xxl-offset-22 {
    margin-left: 91.6667%;
  }

  .ant-col-xxl-order-22 {
    order: 22;
  }

  .ant-col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    display: block;
  }

  .ant-col-xxl-push-21 {
    left: 87.5%;
  }

  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xxl-order-21 {
    order: 21;
  }

  .ant-col-xxl-20 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
    display: block;
  }

  .ant-col-xxl-push-20 {
    left: 83.3333%;
  }

  .ant-col-xxl-pull-20 {
    right: 83.3333%;
  }

  .ant-col-xxl-offset-20 {
    margin-left: 83.3333%;
  }

  .ant-col-xxl-order-20 {
    order: 20;
  }

  .ant-col-xxl-19 {
    flex: 0 0 79.1667%;
    max-width: 79.1667%;
    display: block;
  }

  .ant-col-xxl-push-19 {
    left: 79.1667%;
  }

  .ant-col-xxl-pull-19 {
    right: 79.1667%;
  }

  .ant-col-xxl-offset-19 {
    margin-left: 79.1667%;
  }

  .ant-col-xxl-order-19 {
    order: 19;
  }

  .ant-col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%;
    display: block;
  }

  .ant-col-xxl-push-18 {
    left: 75%;
  }

  .ant-col-xxl-pull-18 {
    right: 75%;
  }

  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xxl-order-18 {
    order: 18;
  }

  .ant-col-xxl-17 {
    flex: 0 0 70.8333%;
    max-width: 70.8333%;
    display: block;
  }

  .ant-col-xxl-push-17 {
    left: 70.8333%;
  }

  .ant-col-xxl-pull-17 {
    right: 70.8333%;
  }

  .ant-col-xxl-offset-17 {
    margin-left: 70.8333%;
  }

  .ant-col-xxl-order-17 {
    order: 17;
  }

  .ant-col-xxl-16 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    display: block;
  }

  .ant-col-xxl-push-16 {
    left: 66.6667%;
  }

  .ant-col-xxl-pull-16 {
    right: 66.6667%;
  }

  .ant-col-xxl-offset-16 {
    margin-left: 66.6667%;
  }

  .ant-col-xxl-order-16 {
    order: 16;
  }

  .ant-col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    display: block;
  }

  .ant-col-xxl-push-15 {
    left: 62.5%;
  }

  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xxl-order-15 {
    order: 15;
  }

  .ant-col-xxl-14 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
    display: block;
  }

  .ant-col-xxl-push-14 {
    left: 58.3333%;
  }

  .ant-col-xxl-pull-14 {
    right: 58.3333%;
  }

  .ant-col-xxl-offset-14 {
    margin-left: 58.3333%;
  }

  .ant-col-xxl-order-14 {
    order: 14;
  }

  .ant-col-xxl-13 {
    flex: 0 0 54.1667%;
    max-width: 54.1667%;
    display: block;
  }

  .ant-col-xxl-push-13 {
    left: 54.1667%;
  }

  .ant-col-xxl-pull-13 {
    right: 54.1667%;
  }

  .ant-col-xxl-offset-13 {
    margin-left: 54.1667%;
  }

  .ant-col-xxl-order-13 {
    order: 13;
  }

  .ant-col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
  }

  .ant-col-xxl-push-12 {
    left: 50%;
  }

  .ant-col-xxl-pull-12 {
    right: 50%;
  }

  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xxl-order-12 {
    order: 12;
  }

  .ant-col-xxl-11 {
    flex: 0 0 45.8333%;
    max-width: 45.8333%;
    display: block;
  }

  .ant-col-xxl-push-11 {
    left: 45.8333%;
  }

  .ant-col-xxl-pull-11 {
    right: 45.8333%;
  }

  .ant-col-xxl-offset-11 {
    margin-left: 45.8333%;
  }

  .ant-col-xxl-order-11 {
    order: 11;
  }

  .ant-col-xxl-10 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    display: block;
  }

  .ant-col-xxl-push-10 {
    left: 41.6667%;
  }

  .ant-col-xxl-pull-10 {
    right: 41.6667%;
  }

  .ant-col-xxl-offset-10 {
    margin-left: 41.6667%;
  }

  .ant-col-xxl-order-10 {
    order: 10;
  }

  .ant-col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    display: block;
  }

  .ant-col-xxl-push-9 {
    left: 37.5%;
  }

  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xxl-order-9 {
    order: 9;
  }

  .ant-col-xxl-8 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    display: block;
  }

  .ant-col-xxl-push-8 {
    left: 33.3333%;
  }

  .ant-col-xxl-pull-8 {
    right: 33.3333%;
  }

  .ant-col-xxl-offset-8 {
    margin-left: 33.3333%;
  }

  .ant-col-xxl-order-8 {
    order: 8;
  }

  .ant-col-xxl-7 {
    flex: 0 0 29.1667%;
    max-width: 29.1667%;
    display: block;
  }

  .ant-col-xxl-push-7 {
    left: 29.1667%;
  }

  .ant-col-xxl-pull-7 {
    right: 29.1667%;
  }

  .ant-col-xxl-offset-7 {
    margin-left: 29.1667%;
  }

  .ant-col-xxl-order-7 {
    order: 7;
  }

  .ant-col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }

  .ant-col-xxl-push-6 {
    left: 25%;
  }

  .ant-col-xxl-pull-6 {
    right: 25%;
  }

  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xxl-order-6 {
    order: 6;
  }

  .ant-col-xxl-5 {
    flex: 0 0 20.8333%;
    max-width: 20.8333%;
    display: block;
  }

  .ant-col-xxl-push-5 {
    left: 20.8333%;
  }

  .ant-col-xxl-pull-5 {
    right: 20.8333%;
  }

  .ant-col-xxl-offset-5 {
    margin-left: 20.8333%;
  }

  .ant-col-xxl-order-5 {
    order: 5;
  }

  .ant-col-xxl-4 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    display: block;
  }

  .ant-col-xxl-push-4 {
    left: 16.6667%;
  }

  .ant-col-xxl-pull-4 {
    right: 16.6667%;
  }

  .ant-col-xxl-offset-4 {
    margin-left: 16.6667%;
  }

  .ant-col-xxl-order-4 {
    order: 4;
  }

  .ant-col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    display: block;
  }

  .ant-col-xxl-push-3 {
    left: 12.5%;
  }

  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xxl-order-3 {
    order: 3;
  }

  .ant-col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    display: block;
  }

  .ant-col-xxl-push-2 {
    left: 8.33333%;
  }

  .ant-col-xxl-pull-2 {
    right: 8.33333%;
  }

  .ant-col-xxl-offset-2 {
    margin-left: 8.33333%;
  }

  .ant-col-xxl-order-2 {
    order: 2;
  }

  .ant-col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    display: block;
  }

  .ant-col-xxl-push-1 {
    left: 4.16667%;
  }

  .ant-col-xxl-pull-1 {
    right: 4.16667%;
  }

  .ant-col-xxl-offset-1 {
    margin-left: 4.16667%;
  }

  .ant-col-xxl-order-1 {
    order: 1;
  }

  .ant-col-xxl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xxl-push-0 {
    left: auto;
  }

  .ant-col-xxl-pull-0 {
    right: auto;
  }

  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xxl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xxl-push-1.ant-col-rtl {
    left: auto;
    right: 4.16667%;
  }

  .ant-col-xxl-pull-1.ant-col-rtl {
    left: 4.16667%;
    right: auto;
  }

  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-left: 0;
    margin-right: 4.16667%;
  }

  .ant-col-xxl-push-2.ant-col-rtl {
    left: auto;
    right: 8.33333%;
  }

  .ant-col-xxl-pull-2.ant-col-rtl {
    left: 8.33333%;
    right: auto;
  }

  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-left: 0;
    margin-right: 8.33333%;
  }

  .ant-col-xxl-push-3.ant-col-rtl {
    left: auto;
    right: 12.5%;
  }

  .ant-col-xxl-pull-3.ant-col-rtl {
    left: 12.5%;
    right: auto;
  }

  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-left: 0;
    margin-right: 12.5%;
  }

  .ant-col-xxl-push-4.ant-col-rtl {
    left: auto;
    right: 16.6667%;
  }

  .ant-col-xxl-pull-4.ant-col-rtl {
    left: 16.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-left: 0;
    margin-right: 16.6667%;
  }

  .ant-col-xxl-push-5.ant-col-rtl {
    left: auto;
    right: 20.8333%;
  }

  .ant-col-xxl-pull-5.ant-col-rtl {
    left: 20.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-left: 0;
    margin-right: 20.8333%;
  }

  .ant-col-xxl-push-6.ant-col-rtl {
    left: auto;
    right: 25%;
  }

  .ant-col-xxl-pull-6.ant-col-rtl {
    left: 25%;
    right: auto;
  }

  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-left: 0;
    margin-right: 25%;
  }

  .ant-col-xxl-push-7.ant-col-rtl {
    left: auto;
    right: 29.1667%;
  }

  .ant-col-xxl-pull-7.ant-col-rtl {
    left: 29.1667%;
    right: auto;
  }

  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-left: 0;
    margin-right: 29.1667%;
  }

  .ant-col-xxl-push-8.ant-col-rtl {
    left: auto;
    right: 33.3333%;
  }

  .ant-col-xxl-pull-8.ant-col-rtl {
    left: 33.3333%;
    right: auto;
  }

  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-left: 0;
    margin-right: 33.3333%;
  }

  .ant-col-xxl-push-9.ant-col-rtl {
    left: auto;
    right: 37.5%;
  }

  .ant-col-xxl-pull-9.ant-col-rtl {
    left: 37.5%;
    right: auto;
  }

  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-left: 0;
    margin-right: 37.5%;
  }

  .ant-col-xxl-push-10.ant-col-rtl {
    left: auto;
    right: 41.6667%;
  }

  .ant-col-xxl-pull-10.ant-col-rtl {
    left: 41.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-left: 0;
    margin-right: 41.6667%;
  }

  .ant-col-xxl-push-11.ant-col-rtl {
    left: auto;
    right: 45.8333%;
  }

  .ant-col-xxl-pull-11.ant-col-rtl {
    left: 45.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-left: 0;
    margin-right: 45.8333%;
  }

  .ant-col-xxl-push-12.ant-col-rtl {
    left: auto;
    right: 50%;
  }

  .ant-col-xxl-pull-12.ant-col-rtl {
    left: 50%;
    right: auto;
  }

  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-left: 0;
    margin-right: 50%;
  }

  .ant-col-xxl-push-13.ant-col-rtl {
    left: auto;
    right: 54.1667%;
  }

  .ant-col-xxl-pull-13.ant-col-rtl {
    left: 54.1667%;
    right: auto;
  }

  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-left: 0;
    margin-right: 54.1667%;
  }

  .ant-col-xxl-push-14.ant-col-rtl {
    left: auto;
    right: 58.3333%;
  }

  .ant-col-xxl-pull-14.ant-col-rtl {
    left: 58.3333%;
    right: auto;
  }

  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-left: 0;
    margin-right: 58.3333%;
  }

  .ant-col-xxl-push-15.ant-col-rtl {
    left: auto;
    right: 62.5%;
  }

  .ant-col-xxl-pull-15.ant-col-rtl {
    left: 62.5%;
    right: auto;
  }

  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-left: 0;
    margin-right: 62.5%;
  }

  .ant-col-xxl-push-16.ant-col-rtl {
    left: auto;
    right: 66.6667%;
  }

  .ant-col-xxl-pull-16.ant-col-rtl {
    left: 66.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-left: 0;
    margin-right: 66.6667%;
  }

  .ant-col-xxl-push-17.ant-col-rtl {
    left: auto;
    right: 70.8333%;
  }

  .ant-col-xxl-pull-17.ant-col-rtl {
    left: 70.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-left: 0;
    margin-right: 70.8333%;
  }

  .ant-col-xxl-push-18.ant-col-rtl {
    left: auto;
    right: 75%;
  }

  .ant-col-xxl-pull-18.ant-col-rtl {
    left: 75%;
    right: auto;
  }

  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-left: 0;
    margin-right: 75%;
  }

  .ant-col-xxl-push-19.ant-col-rtl {
    left: auto;
    right: 79.1667%;
  }

  .ant-col-xxl-pull-19.ant-col-rtl {
    left: 79.1667%;
    right: auto;
  }

  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-left: 0;
    margin-right: 79.1667%;
  }

  .ant-col-xxl-push-20.ant-col-rtl {
    left: auto;
    right: 83.3333%;
  }

  .ant-col-xxl-pull-20.ant-col-rtl {
    left: 83.3333%;
    right: auto;
  }

  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-left: 0;
    margin-right: 83.3333%;
  }

  .ant-col-xxl-push-21.ant-col-rtl {
    left: auto;
    right: 87.5%;
  }

  .ant-col-xxl-pull-21.ant-col-rtl {
    left: 87.5%;
    right: auto;
  }

  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-left: 0;
    margin-right: 87.5%;
  }

  .ant-col-xxl-push-22.ant-col-rtl {
    left: auto;
    right: 91.6667%;
  }

  .ant-col-xxl-pull-22.ant-col-rtl {
    left: 91.6667%;
    right: auto;
  }

  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-left: 0;
    margin-right: 91.6667%;
  }

  .ant-col-xxl-push-23.ant-col-rtl {
    left: auto;
    right: 95.8333%;
  }

  .ant-col-xxl-pull-23.ant-col-rtl {
    left: 95.8333%;
    right: auto;
  }

  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-left: 0;
    margin-right: 95.8333%;
  }

  .ant-col-xxl-push-24.ant-col-rtl {
    left: auto;
    right: 100%;
  }

  .ant-col-xxl-pull-24.ant-col-rtl {
    left: 100%;
    right: auto;
  }

  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-left: 0;
    margin-right: 100%;
  }
}

.ant-row-rtl {
  direction: rtl;
}

.ant-collapse {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #000000d9;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  line-height: 1.5715;
  transition: all .3s, visibility;
  display: flex;
  position: relative;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  vertical-align: -1px;
  margin-right: 12px;
  font-size: 12px;
  display: inline-block;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform .24s;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
  flex: none;
}

.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}

.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 40px 12px 16px;
  position: relative;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin: 0;
  position: absolute;
  top: 50%;
  left: auto;
  right: 16px;
  transform: translateY(-50%);
}

.ant-collapse-content {
  color: #000000d9;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}

.ant-collapse-content-hidden {
  display: none;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}

.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.ant-collapse-borderless > .ant-collapse-item:last-child, .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}

.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: #0000;
  border-top: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}

.ant-collapse-ghost {
  background-color: #0000;
  border: 0;
}

.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: #0000;
  border-top: 0;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header, .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: #00000040;
  cursor: not-allowed;
}

.ant-collapse-rtl {
  direction: rtl;
}

.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px 12px 40px;
  position: relative;
}

.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 16px;
  right: auto;
  transform: translateY(-50%);
}

.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 40px 12px 16px;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-left: 12px;
  margin-right: 0;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: 0;
  margin-right: auto;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 0;
  padding-right: 12px;
}

.ant-comment {
  background-color: inherit;
  position: relative;
}

.ant-comment-inner {
  padding: 16px 0;
  display: flex;
}

.ant-comment-avatar {
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
}

.ant-comment-avatar img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.ant-comment-content {
  word-wrap: break-word;
  flex: auto;
  min-width: 1px;
  font-size: 14px;
  position: relative;
}

.ant-comment-content-author {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
  display: flex;
}

.ant-comment-content-author > a, .ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}

.ant-comment-content-author-name {
  color: #00000073;
  font-size: 14px;
  transition: color .3s;
}

.ant-comment-content-author-name > *, .ant-comment-content-author-name > :hover {
  color: #00000073;
}

.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}

.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}

.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}

.ant-comment-actions > li {
  color: #00000073;
  display: inline-block;
}

.ant-comment-actions > li > span {
  color: #00000073;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 10px;
  font-size: 12px;
  transition: color .3s;
}

.ant-comment-actions > li > span:hover {
  color: #595959;
}

.ant-comment-nested {
  margin-left: 44px;
}

.ant-comment-rtl {
  direction: rtl;
}

.ant-comment-rtl .ant-comment-avatar {
  margin-left: 12px;
  margin-right: 0;
}

.ant-comment-rtl .ant-comment-content-author > a, .ant-comment-rtl .ant-comment-content-author > span {
  padding-left: 8px;
  padding-right: 0;
}

.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}

.ant-comment-rtl .ant-comment-actions > li > span {
  margin-left: 10px;
  margin-right: 0;
}

.ant-comment-rtl .ant-comment-nested {
  margin-left: 0;
  margin-right: 44px;
}

.ant-descriptions-header {
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.ant-descriptions-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5715;
  overflow: hidden;
}

.ant-descriptions-extra {
  color: #000000d9;
  margin-left: auto;
  font-size: 14px;
}

.ant-descriptions-view {
  border-radius: 2px;
  width: 100%;
}

.ant-descriptions-view table {
  table-layout: fixed;
  width: 100%;
}

.ant-descriptions-row > th, .ant-descriptions-row > td {
  padding-bottom: 16px;
}

.ant-descriptions-row:last-child {
  border-bottom: none;
}

.ant-descriptions-item-label {
  color: #000000d9;
  text-align: start;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5715;
}

.ant-descriptions-item-label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -.5px;
}

.ant-descriptions-item-label.ant-descriptions-item-no-colon:after {
  content: " ";
}

.ant-descriptions-item-no-label:after {
  content: "";
  margin: 0;
}

.ant-descriptions-item-content {
  color: #000000d9;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1;
  font-size: 14px;
  line-height: 1.5715;
  display: table-cell;
}

.ant-descriptions-item {
  vertical-align: top;
  padding-bottom: 0;
}

.ant-descriptions-item-container {
  display: flex;
}

.ant-descriptions-item-container .ant-descriptions-item-label, .ant-descriptions-item-container .ant-descriptions-item-content {
  align-items: baseline;
  display: inline-flex;
}

.ant-descriptions-middle .ant-descriptions-row > th, .ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}

.ant-descriptions-small .ant-descriptions-row > th, .ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}

.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: 1px solid #f0f0f0;
  padding: 16px 24px;
}

.ant-descriptions-bordered .ant-descriptions-item-label:last-child, .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}

.ant-descriptions-bordered .ant-descriptions-item-label:after {
  display: none;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}

.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}

.ant-descriptions-rtl {
  direction: rtl;
}

.ant-descriptions-rtl .ant-descriptions-item-label:after {
  margin: 0 2px 0 8px;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-left: 1px solid #f0f0f0;
  border-right: none;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child, .ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}

.ant-divider {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  border-top: 1px solid #0000000f;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-divider-vertical {
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid #0000000f;
  height: .9em;
  margin: 0 8px;
  display: inline-block;
  position: relative;
  top: -.06em;
}

.ant-divider-horizontal {
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
  display: flex;
}

.ant-divider-horizontal.ant-divider-with-text {
  color: #000000d9;
  white-space: nowrap;
  text-align: center;
  border-top: 0 #0000000f;
  align-items: center;
  margin: 16px 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.ant-divider-horizontal.ant-divider-with-text:before, .ant-divider-horizontal.ant-divider-with-text:after {
  border-top: 1px solid #0000;
  border-top-color: inherit;
  content: "";
  border-bottom: 0;
  width: 50%;
  position: relative;
  transform: translateY(50%);
}

.ant-divider-horizontal.ant-divider-with-text-left:before {
  width: 5%;
}

.ant-divider-horizontal.ant-divider-with-text-left:after, .ant-divider-horizontal.ant-divider-with-text-right:before {
  width: 95%;
}

.ant-divider-horizontal.ant-divider-with-text-right:after {
  width: 5%;
}

.ant-divider-inner-text {
  padding: 0 1em;
  display: inline-block;
}

.ant-divider-dashed {
  background: none;
  border: 0 dashed #0000000f;
  border-top-width: 1px;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:before, .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:after {
  border-style: dashed none none;
}

.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}

.ant-divider-plain.ant-divider-with-text {
  color: #000000d9;
  font-size: 14px;
  font-weight: normal;
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left:before {
  width: 0;
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left:after {
  width: 100%;
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}

.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right:before {
  width: 100%;
}

.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right:after {
  width: 0;
}

.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}

.ant-divider-rtl {
  direction: rtl;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:before {
  width: 95%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:after, .ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:before {
  width: 5%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:after {
  width: 95%;
}

.ant-drawer {
  z-index: 1000;
  pointer-events: none;
  position: fixed;
  inset: 0;
}

.ant-drawer-inline {
  position: absolute;
}

.ant-drawer-mask {
  z-index: 1000;
  pointer-events: auto;
  background: #00000073;
  position: absolute;
  inset: 0;
}

.ant-drawer-content-wrapper {
  z-index: 1000;
  transition: all .3s;
  position: absolute;
}

.ant-drawer-content-wrapper-hidden {
  display: none;
}

.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px #00000014, 9px 0 28px #0000000d, 12px 0 48px 16px #00000008;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d, -12px 0 48px 16px #00000008;
}

.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 6px 16px -8px #00000014, 0 9px 28px #0000000d, 0 12px 48px 16px #00000008;
}

.ant-drawer-bottom > .ant-drawer-content-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -6px 16px -8px #00000014, 0 -9px 28px #0000000d, 0 -12px 48px 16px #00000008;
}

.ant-drawer-content {
  pointer-events: auto;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ant-drawer-wrapper-body {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.ant-drawer-header {
  border-bottom: 1px solid #f0f0f0;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
}

.ant-drawer-header-title {
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
  display: flex;
}

.ant-drawer-extra {
  flex: none;
}

.ant-drawer-close {
  color: #00000073;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  text-rendering: auto;
  background: none;
  border: 0;
  outline: 0;
  margin-right: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color .3s;
  display: inline-block;
}

.ant-drawer-close:focus, .ant-drawer-close:hover {
  color: #000000bf;
  text-decoration: none;
}

.ant-drawer-title {
  color: #000000d9;
  flex: 1;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}

.ant-drawer-footer {
  border-top: 1px solid #f0f0f0;
  flex-shrink: 0;
  padding: 10px 16px;
}

.panel-motion-enter-start, .panel-motion-appear-start, .panel-motion-leave-start {
  transition: none;
}

.panel-motion-enter-active, .panel-motion-appear-active, .panel-motion-leave-active, .ant-drawer-mask-motion-enter-active, .ant-drawer-mask-motion-appear-active, .ant-drawer-mask-motion-leave-active {
  transition: all .3s;
}

.ant-drawer-mask-motion-enter, .ant-drawer-mask-motion-appear {
  opacity: 0;
}

.ant-drawer-mask-motion-enter-active, .ant-drawer-mask-motion-appear-active, .ant-drawer-mask-motion-leave {
  opacity: 1;
}

.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}

.ant-drawer-panel-motion-left-enter-start, .ant-drawer-panel-motion-left-appear-start, .ant-drawer-panel-motion-left-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-left-enter-active, .ant-drawer-panel-motion-left-appear-active, .ant-drawer-panel-motion-left-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-left-enter-start, .ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}

.ant-drawer-panel-motion-left-enter-active, .ant-drawer-panel-motion-left-appear-active, .ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}

.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}

.ant-drawer-panel-motion-right-enter-start, .ant-drawer-panel-motion-right-appear-start, .ant-drawer-panel-motion-right-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-right-enter-active, .ant-drawer-panel-motion-right-appear-active, .ant-drawer-panel-motion-right-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-right-enter-start, .ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}

.ant-drawer-panel-motion-right-enter-active, .ant-drawer-panel-motion-right-appear-active, .ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}

.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}

.ant-drawer-panel-motion-top-enter-start, .ant-drawer-panel-motion-top-appear-start, .ant-drawer-panel-motion-top-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-top-enter-active, .ant-drawer-panel-motion-top-appear-active, .ant-drawer-panel-motion-top-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-top-enter-start, .ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}

.ant-drawer-panel-motion-top-enter-active, .ant-drawer-panel-motion-top-appear-active, .ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}

.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}

.ant-drawer-panel-motion-bottom-enter-start, .ant-drawer-panel-motion-bottom-appear-start, .ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-bottom-enter-active, .ant-drawer-panel-motion-bottom-appear-active, .ant-drawer-panel-motion-bottom-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-bottom-enter-start, .ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}

.ant-drawer-panel-motion-bottom-enter-active, .ant-drawer-panel-motion-bottom-appear-active, .ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}

.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}

.ant-drawer-rtl {
  direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
  margin-left: 12px;
  margin-right: 0;
}

.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}

.ant-form-inline {
  flex-wrap: wrap;
  display: flex;
}

.ant-form-inline .ant-form-item {
  flex-wrap: nowrap;
  flex: none;
  margin-bottom: 0;
  margin-right: 16px;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}

.ant-form-inline .ant-form-item > .ant-form-item-label, .ant-form-inline .ant-form-item > .ant-form-item-control {
  vertical-align: top;
  display: inline-block;
}

.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}

.ant-form-inline .ant-form-item .ant-form-text, .ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}

.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}

.ant-form-horizontal .ant-form-item-label[class$="-24"] + .ant-form-item-control, .ant-form-horizontal .ant-form-item-label[class*="-24 "] + .ant-form-item-control {
  min-width: unset;
}

.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}

.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}

.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  white-space: initial;
  text-align: left;
  padding: 0 0 8px;
  line-height: 1.5715;
}

.ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}

.ant-form-vertical .ant-form-item-label > label:after, .ant-col-24.ant-form-item-label > label:after, .ant-col-xl-24.ant-form-item-label > label:after {
  display: none;
}

.ant-form-rtl.ant-form-vertical .ant-form-item-label, .ant-form-rtl.ant-col-24.ant-form-item-label, .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}

@media (width <= 575px) {
  .ant-form-item .ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }

  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }

  .ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xs-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xs-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 767px) {
  .ant-col-sm-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-sm-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 991px) {
  .ant-col-md-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-md-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-lg-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}

@media (width <= 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    white-space: initial;
    text-align: left;
    padding: 0 0 8px;
    line-height: 1.5715;
  }

  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xl-24.ant-form-item-label > label:after {
    display: none;
  }

  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}

.ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-form-item-explain-warning {
  color: #faad14;
}

.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}

.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}

.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}

.ant-form {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-form legend {
  color: #00000073;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  display: block;
}

.ant-form label {
  font-size: 14px;
}

.ant-form input[type="search"] {
  box-sizing: border-box;
}

.ant-form input[type="radio"], .ant-form input[type="checkbox"] {
  line-height: normal;
}

.ant-form input[type="file"] {
  display: block;
}

.ant-form input[type="range"] {
  width: 100%;
  display: block;
}

.ant-form select[multiple], .ant-form select[size] {
  height: auto;
}

.ant-form input[type="file"]:focus, .ant-form input[type="radio"]:focus, .ant-form input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ant-form output {
  color: #000000d9;
  padding-top: 15px;
  font-size: 14px;
  line-height: 1.5715;
  display: block;
}

.ant-form .ant-form-text {
  padding-right: 8px;
  display: inline-block;
}

.ant-form-small .ant-form-item-label > label {
  height: 24px;
}

.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}

.ant-form-large .ant-form-item-label > label {
  height: 40px;
}

.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}

.ant-form-item {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  vertical-align: top;
  margin: 0 0 24px;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-form-item-with-help {
  transition: none;
}

.ant-form-item-hidden, .ant-form-item-hidden.ant-row {
  display: none;
}

.ant-form-item-label {
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  flex-grow: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-form-item-label-left {
  text-align: left;
}

.ant-form-item-label-wrap {
  overflow: unset;
  white-space: unset;
  line-height: 1.3215em;
}

.ant-form-item-label > label {
  color: #000000d9;
  align-items: center;
  max-width: 100%;
  height: 32px;
  font-size: 14px;
  display: inline-flex;
  position: relative;
}

.ant-form-item-label > label > .anticon {
  vertical-align: top;
  font-size: 14px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  color: #ff4d4f;
  content: "*";
  margin-right: 4px;
  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
}

.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none;
}

.ant-form-item-label > label .ant-form-item-optional {
  color: #00000073;
  margin-left: 4px;
  display: inline-block;
}

.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}

.ant-form-item-label > label .ant-form-item-tooltip {
  color: #00000073;
  cursor: help;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}

.ant-form-item-label > label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -.5px;
}

.ant-form-item-label > label.ant-form-item-no-colon:after {
  content: " ";
}

.ant-form-item-control {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.ant-form-item-control:first-child:not([class^="ant-col-"]):not([class*=" ant-col-"]) {
  width: 100%;
}

.ant-form-item-control-input {
  align-items: center;
  min-height: 32px;
  display: flex;
  position: relative;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item-explain, .ant-form-item-extra {
  clear: both;
  color: #00000073;
  font-size: 14px;
  line-height: 1.5715;
  transition: color .3s cubic-bezier(.215, .61, .355, 1);
}

.ant-form-item-explain-connected {
  width: 100%;
}

.ant-form-item-extra {
  min-height: 24px;
}

.ant-form-item-with-help .ant-form-item-explain {
  opacity: 1;
  height: auto;
}

.ant-form-item-feedback-icon {
  text-align: center;
  visibility: visible;
  pointer-events: none;
  font-size: 14px;
  animation: .3s cubic-bezier(.12, .4, .29, 1.46) zoomIn;
}

.ant-form-item-feedback-icon-success {
  color: #52c41a;
}

.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}

.ant-form-item-feedback-icon-warning {
  color: #faad14;
}

.ant-form-item-feedback-icon-validating {
  color: #1890ff;
}

.ant-show-help {
  transition: opacity .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-show-help-appear, .ant-show-help-enter {
  opacity: 0;
}

.ant-show-help-appear-active, .ant-show-help-enter-active, .ant-show-help-leave {
  opacity: 1;
}

.ant-show-help-leave-active {
  opacity: 0;
}

.ant-show-help-item {
  overflow: hidden;
  transition: height .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-show-help-item-appear, .ant-show-help-item-enter {
  opacity: 0;
  transform: translateY(-5px);
}

.ant-show-help-item-appear-active, .ant-show-help-item-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.ant-show-help-item-leave {
  transition: height .2s cubic-bezier(.645, .045, .355, 1), opacity .2s cubic-bezier(.645, .045, .355, 1), transform .2s cubic-bezier(.645, .045, .355, 1) !important;
}

.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}

@keyframes diffZoomIn1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes diffZoomIn2 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes diffZoomIn3 {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ant-form-rtl {
  direction: rtl;
}

.ant-form-rtl .ant-form-item-label {
  text-align: left;
}

.ant-form-rtl .ant-form-item-label > label.ant-form-item-required:before {
  margin-left: 4px;
  margin-right: 0;
}

.ant-form-rtl .ant-form-item-label > label:after {
  margin: 0 2px 0 8px;
}

.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-left: 0;
  margin-right: 4px;
}

.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-left: 24px;
  padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-left: 18px;
  padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input, .ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  left: 28px;
  right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow, .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  left: 32px;
  right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value, .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-left: 42px;
  padding-right: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-left: 19px;
  margin-right: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  left: 32px;
  right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker, .ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-left: 29.2px;
  padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-left: 25.2px;
  padding-right: 7px;
}

.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  left: 0;
  right: auto;
}

.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-left: 16px;
  margin-right: 0;
}

.ant-image {
  display: inline-block;
  position: relative;
}

.ant-image-img {
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%;
}

.ant-image-mask {
  color: #fff;
  cursor: pointer;
  opacity: 0;
  background: #00000080;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  inset: 0;
}

.ant-image-mask-info {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4px;
  overflow: hidden;
}

.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}

.ant-image-mask:hover {
  opacity: 1;
}

.ant-image-placeholder {
  position: absolute;
  inset: 0;
}

.ant-image-preview {
  pointer-events: none;
  text-align: center;
  height: 100%;
}

.ant-image-preview.ant-zoom-enter, .ant-image-preview.ant-zoom-appear {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  animation-duration: .3s;
  transform: none;
}

.ant-image-preview-mask {
  z-index: 1000;
  background-color: #00000073;
  height: 100%;
  position: fixed;
  inset: 0;
}

.ant-image-preview-mask-hidden {
  display: none;
}

.ant-image-preview-wrap {
  outline: 0;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.ant-image-preview-body {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.ant-image-preview-img {
  vertical-align: middle;
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
  max-width: 100%;
  max-height: 100%;
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
  transform: scale3d(1, 1, 1);
}

.ant-image-preview-img-wrapper {
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
  position: absolute;
  inset: 0;
}

.ant-image-preview-img-wrapper:before {
  content: "";
  width: 1px;
  height: 50%;
  margin-right: -1px;
  display: inline-block;
}

.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}

.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}

.ant-image-preview-wrap {
  z-index: 1080;
}

.ant-image-preview-operations-wrapper {
  z-index: 1081;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.ant-image-preview-operations {
  box-sizing: border-box;
  color: #ffffffd9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  pointer-events: auto;
  background: #0000001a;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
}

.ant-image-preview-operations-operation {
  cursor: pointer;
  margin-left: 12px;
  padding: 12px;
  transition: all .3s;
}

.ant-image-preview-operations-operation:hover {
  background: #0003;
}

.ant-image-preview-operations-operation-disabled {
  color: #ffffff40;
  pointer-events: none;
}

.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}

.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ant-image-preview-operations-icon {
  font-size: 18px;
}

.ant-image-preview-switch-left, .ant-image-preview-switch-right {
  z-index: 1081;
  color: #ffffffd9;
  cursor: pointer;
  pointer-events: auto;
  background: #0000001a;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.ant-image-preview-switch-left:hover, .ant-image-preview-switch-right:hover {
  background: #0003;
}

.ant-image-preview-switch-left-disabled, .ant-image-preview-switch-right-disabled, .ant-image-preview-switch-left-disabled:hover, .ant-image-preview-switch-right-disabled:hover {
  color: #ffffff40;
  cursor: not-allowed;
  background: #0000001a;
}

.ant-image-preview-switch-left-disabled > .anticon, .ant-image-preview-switch-right-disabled > .anticon, .ant-image-preview-switch-left-disabled:hover > .anticon, .ant-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}

.ant-image-preview-switch-left > .anticon, .ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}

.ant-image-preview-switch-left {
  left: 8px;
}

.ant-image-preview-switch-right {
  right: 8px;
}

.ant-input-number-affix-wrapper {
  color: #000000d9;
  padding: 0;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 90px;
  min-width: 0;
  padding-inline-start: 11px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-flex;
  position: relative;
}

.ant-input-number-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-number-affix-wrapper-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper-borderless, .ant-input-number-affix-wrapper-borderless:hover, .ant-input-number-affix-wrapper-borderless:focus, .ant-input-number-affix-wrapper-borderless-focused, .ant-input-number-affix-wrapper-borderless-disabled, .ant-input-number-affix-wrapper-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input-number-affix-wrapper {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-number-affix-wrapper-sm {
  padding: 0 7px;
}

.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  z-index: 1;
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-number-affix-wrapper-focused, .ant-input-number-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: none;
}

.ant-input-number-affix-wrapper > div.ant-input-number {
  border: none;
  outline: none;
  width: 100%;
}

.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}

.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}

.ant-input-number-affix-wrapper:before {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}

.ant-input-number-prefix, .ant-input-number-suffix {
  pointer-events: none;
  flex: none;
  align-items: center;
  display: flex;
}

.ant-input-number-prefix {
  margin-inline-end: 4px;
}

.ant-input-number-suffix {
  z-index: 1;
  height: 100%;
  margin-left: 4px;
  margin-right: 11px;
  position: absolute;
  top: 0;
  right: 0;
}

.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number, .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus, .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}

.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number, .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus, .ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}

.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}

.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}

.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #faad14;
  border-color: #faad14;
}

.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  padding: 4px 11px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 90px;
  min-width: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number:focus, .ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-number[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-borderless, .ant-input-number-borderless:hover, .ant-input-number-borderless:focus, .ant-input-number-borderless-focused, .ant-input-number-borderless-disabled, .ant-input-number-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input-number {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-number-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: table;
  position: relative;
}

.ant-input-number-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.ant-input-number-group > [class*="col-"] {
  padding-right: 8px;
}

.ant-input-number-group > [class*="col-"]:last-child {
  padding-right: 0;
}

.ant-input-number-group-addon, .ant-input-number-group-wrap, .ant-input-number-group > .ant-input-number {
  display: table-cell;
}

.ant-input-number-group-addon:not(:first-child):not(:last-child), .ant-input-number-group-wrap:not(:first-child):not(:last-child), .ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-number-group-addon, .ant-input-number-group-wrap {
  white-space: nowrap;
  vertical-align: middle;
  width: 1px;
}

.ant-input-number-group-wrap > * {
  display: block !important;
}

.ant-input-number-group .ant-input-number {
  float: left;
  text-align: inherit;
  width: 100%;
  margin-bottom: 0;
}

.ant-input-number-group .ant-input-number:focus, .ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}

.ant-input-number-group-addon {
  color: #000000d9;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0 11px;
  font-size: 14px;
  font-weight: normal;
  transition: all .3s;
  position: relative;
}

.ant-input-number-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  box-shadow: none;
  border: 1px solid #0000;
}

.ant-input-number-group-addon .ant-select-open .ant-select-selector, .ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff;
}

.ant-input-number-group-addon .ant-cascader-picker {
  background-color: #0000;
  margin: -9px -12px;
}

.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  box-shadow: none;
  border: 0;
}

.ant-input-number-group > .ant-input-number:first-child, .ant-input-number-group-addon:first-child, .ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector, .ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-group-addon:first-child {
  border-right: 0;
}

.ant-input-number-group-addon:last-child {
  border-left: 0;
}

.ant-input-number-group > .ant-input-number:last-child, .ant-input-number-group-addon:last-child, .ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector, .ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-group-lg .ant-input-number, .ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-number-group-sm .ant-input-number, .ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 0 7px;
}

.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child), .ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}

.ant-input-number-group.ant-input-number-group-compact:before {
  content: "";
  display: table;
}

.ant-input-number-group.ant-input-number-group-compact:after {
  clear: both;
  content: "";
  display: table;
}

.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child), .ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child), .ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}

.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover, .ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover, .ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover, .ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus, .ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus, .ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > * {
  float: none;
  vertical-align: top;
  border-radius: 0;
  display: inline-block;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper, .ant-input-number-group.ant-input-number-group-compact > .ant-input-number-number-affix-wrapper, .ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-number-group.ant-input-number-group-compact > :not(:last-child) {
  border-right-width: 1px;
  margin-right: -1px;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover, .ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus, .ant-input-number-group.ant-input-number-group-compact > .ant-select-focused, .ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > :first-child, .ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-number-group.ant-input-number-group-compact > :last-child, .ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper, .ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}

.ant-input-number-group > .ant-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}

.ant-input-number-group > .ant-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}

.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-left: 0;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-left: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 2px 0 0 2px;
}

.ant-input-number-group-wrapper {
  text-align: start;
  vertical-align: top;
  display: inline-block;
}

.ant-input-number-handler {
  color: #00000073;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  width: 100%;
  height: 50%;
  font-weight: bold;
  line-height: 0;
  transition: all .1s linear;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-input-number-handler:active {
  background: #f4f4f4;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff;
}

.ant-input-number-handler-up-inner, .ant-input-number-handler-down-inner {
  color: #00000073;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  width: 12px;
  height: 12px;
  font-style: normal;
  line-height: 12px;
  transition: all .1s linear;
  display: inline-flex;
  position: absolute;
  right: 4px;
}

.ant-input-number-handler-up-inner > *, .ant-input-number-handler-down-inner > * {
  line-height: 1;
}

.ant-input-number-handler-up-inner svg, .ant-input-number-handler-down-inner svg {
  display: inline-block;
}

.ant-input-number-handler-up-inner:before, .ant-input-number-handler-down-inner:before {
  display: none;
}

.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon, .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon, .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon, .ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}

.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity .24s linear .24s;
}

.ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-number-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}

.ant-input-number-disabled .ant-input-number-handler-wrap, .ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-input {
  text-align: left;
  background-color: #0000;
  border: 0;
  border-radius: 2px;
  outline: 0;
  width: 100%;
  height: 30px;
  padding: 0 11px;
  transition: all .3s linear;
  appearance: textfield !important;
}

.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}

:is(.ant-input-number-input[type="number"]::-webkit-inner-spin-button, .ant-input-number-input[type="number"]::-webkit-outer-spin-button) {
  appearance: none;
  margin: 0;
}

.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}

.ant-input-number-lg input {
  height: 38px;
}

.ant-input-number-sm {
  padding: 0;
}

.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}

.ant-input-number-handler-wrap {
  opacity: 0;
  background: #fff;
  border-radius: 0 2px 2px 0;
  width: 22px;
  height: 100%;
  transition: opacity .24s linear .1s;
  position: absolute;
  top: 0;
  right: 0;
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  justify-content: center;
  align-items: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
  display: flex;
}

.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}

.ant-input-number:hover .ant-input-number-handler-wrap, .ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-input-number-handler-up {
  cursor: pointer;
  border-top-right-radius: 2px;
}

.ant-input-number-handler-up-inner {
  text-align: center;
  margin-top: -5px;
  top: 50%;
}

.ant-input-number-handler-up:hover {
  height: 60% !important;
}

.ant-input-number-handler-down {
  cursor: pointer;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  top: 0;
}

.ant-input-number-handler-down-inner {
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.ant-input-number-handler-down:hover {
  height: 60% !important;
}

.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}

.ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down, .ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
}

.ant-input-number-handler-up-disabled, .ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}

.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner, .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: #00000040;
}

.ant-input-number-borderless {
  box-shadow: none;
}

.ant-input-number-out-of-range input {
  color: #ff4d4f;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  margin-right: -1px;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-last-item).ant-input-number-compact-item-rtl {
  margin-left: -1px;
}

.ant-input-number-compact-item:hover, .ant-input-number-compact-item:focus, .ant-input-number-compact-item:active, .ant-input-number-compact-item.ant-input-number-focused {
  z-index: 2;
}

.ant-input-number-compact-item[disabled] {
  z-index: 0;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-last-item).ant-input-number {
  border-radius: 0;
}

.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-item-rtl), .ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-number-rtl {
  direction: rtl;
}

.ant-input-number-rtl .ant-input-number-handler {
  border-left: 0;
  border-right: 1px solid #d9d9d9;
}

.ant-input-number-rtl .ant-input-number-handler-wrap {
  left: 0;
  right: auto;
}

.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}

.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}

.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}

.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}

.ant-input-affix-wrapper {
  color: #000000d9;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-flex;
  position: relative;
}

.ant-input-affix-wrapper::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input-affix-wrapper:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-rtl .ant-input-affix-wrapper:focus, .ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-affix-wrapper[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-affix-wrapper-borderless, .ant-input-affix-wrapper-borderless:hover, .ant-input-affix-wrapper-borderless:focus, .ant-input-affix-wrapper-borderless-focused, .ant-input-affix-wrapper-borderless-disabled, .ant-input-affix-wrapper-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input-affix-wrapper {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-affix-wrapper-sm {
  padding: 0 7px;
}

.ant-input-affix-wrapper-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 1;
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: #fff0;
}

.ant-input-affix-wrapper > .ant-input {
  font-size: inherit;
  border: none;
  outline: none;
}

.ant-input-affix-wrapper > .ant-input:focus {
  box-shadow: none !important;
}

.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
}

.ant-input-affix-wrapper:before {
  visibility: hidden;
  content: " ";
  width: 0;
  display: inline-block;
}

.ant-input-prefix, .ant-input-suffix {
  flex: none;
  align-items: center;
  display: flex;
}

.ant-input-prefix > :not(:last-child), .ant-input-suffix > :not(:last-child) {
  margin-right: 8px;
}

.ant-input-show-count-suffix {
  color: #00000073;
}

.ant-input-show-count-has-suffix {
  margin-right: 2px;
}

.ant-input-prefix {
  margin-right: 4px;
}

.ant-input-suffix {
  margin-left: 4px;
}

.anticon.ant-input-clear-icon, .ant-input-clear-icon {
  color: #00000040;
  vertical-align: -1px;
  cursor: pointer;
  margin: 0;
  font-size: 12px;
  transition: color .3s;
}

.anticon.ant-input-clear-icon:hover, .ant-input-clear-icon:hover {
  color: #00000073;
}

.anticon.ant-input-clear-icon:active, .ant-input-clear-icon:active {
  color: #000000d9;
}

.anticon.ant-input-clear-icon-hidden, .ant-input-clear-icon-hidden {
  visibility: hidden;
}

.anticon.ant-input-clear-icon-has-suffix, .ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 8px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus, .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input, .ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input, .ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input, .ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}

.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}

.ant-input {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input:focus, .ant-input-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-input-rtl .ant-input:focus, .ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-input-borderless, .ant-input-borderless:hover, .ant-input-borderless:focus, .ant-input-borderless-focused, .ant-input-borderless-disabled, .ant-input-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-input {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-sm {
  padding: 0 7px;
}

.ant-input-rtl {
  direction: rtl;
}

.ant-input-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: table;
  position: relative;
}

.ant-input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.ant-input-group > [class*="col-"] {
  padding-right: 8px;
}

.ant-input-group > [class*="col-"]:last-child {
  padding-right: 0;
}

.ant-input-group-addon, .ant-input-group-wrap, .ant-input-group > .ant-input {
  display: table-cell;
}

.ant-input-group-addon:not(:first-child):not(:last-child), .ant-input-group-wrap:not(:first-child):not(:last-child), .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-addon, .ant-input-group-wrap {
  white-space: nowrap;
  vertical-align: middle;
  width: 1px;
}

.ant-input-group-wrap > * {
  display: block !important;
}

.ant-input-group .ant-input {
  float: left;
  text-align: inherit;
  width: 100%;
  margin-bottom: 0;
}

.ant-input-group .ant-input:focus, .ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}

.ant-input-group-addon {
  color: #000000d9;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0 11px;
  font-size: 14px;
  font-weight: normal;
  transition: all .3s;
  position: relative;
}

.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  box-shadow: none;
  border: 1px solid #0000;
}

.ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #1890ff;
}

.ant-input-group-addon .ant-cascader-picker {
  background-color: #0000;
  margin: -9px -12px;
}

.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  box-shadow: none;
  border: 0;
}

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child, .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector, .ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon:first-child {
  border-right: 0;
}

.ant-input-group-addon:last-child {
  border-left: 0;
}

.ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child, .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector, .ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group-lg .ant-input, .ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-group-sm .ant-input, .ant-input-group-sm > .ant-input-group-addon {
  padding: 0 7px;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child), .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group.ant-input-group-compact {
  display: block;
}

.ant-input-group.ant-input-group-compact:before {
  content: "";
  display: table;
}

.ant-input-group.ant-input-group-compact:after {
  clear: both;
  content: "";
  display: table;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child), .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child), .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover, .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover, .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover, .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus, .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus, .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > * {
  float: none;
  vertical-align: top;
  border-radius: 0;
  display: inline-block;
}

.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper, .ant-input-group.ant-input-group-compact > .ant-input-number-affix-wrapper, .ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact > :not(:last-child) {
  border-right-width: 1px;
  margin-right: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input, .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover, .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover, .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover, .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus, .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus, .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus, .ant-input-group.ant-input-group-compact > .ant-select-focused, .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > :first-child, .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-group.ant-input-group-compact > :last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper, .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}

.ant-input-group > .ant-input-rtl:first-child, .ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-left: 0;
  border-right: 1px solid #d9d9d9;
}

.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-left: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 2px 0 0 2px;
}

.ant-input-group-rtl.ant-input-group > .ant-input:last-child, .ant-input-group-rtl.ant-input-group-addon:last-child, .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :not(:last-child) {
  border-left-width: 1px;
  margin-left: -1px;
  margin-right: 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :first-child, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > :last-child, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-left: 0;
  margin-right: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-wrapper {
  text-align: start;
  vertical-align: top;
  width: 100%;
  display: inline-block;
}

.ant-input-password-icon.anticon {
  color: #00000073;
  cursor: pointer;
  transition: all .3s;
}

.ant-input-password-icon.anticon:hover {
  color: #000000d9;
}

.ant-input[type="color"] {
  height: 32px;
}

.ant-input[type="color"].ant-input-lg {
  height: 40px;
}

.ant-input[type="color"].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}

.ant-input-textarea-show-count:after {
  float: right;
  color: #00000073;
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}

.ant-input-textarea-show-count.ant-input-textarea-in-form-item:after {
  margin-bottom: -22px;
}

.ant-input-textarea-suffix {
  z-index: 1;
  align-items: center;
  margin: auto;
  display: inline-flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
}

.ant-input-compact-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  margin-right: -1px;
}

.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-item-rtl {
  margin-left: -1px;
}

.ant-input-compact-item:hover, .ant-input-compact-item:focus, .ant-input-compact-item:active {
  z-index: 2;
}

.ant-input-compact-item[disabled] {
  z-index: 0;
}

.ant-input-compact-item:not(.ant-input-compact-first-item):not(.ant-input-compact-last-item).ant-input {
  border-radius: 0;
}

.ant-input-compact-item.ant-input.ant-input-compact-first-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-compact-item.ant-input.ant-input-compact-last-item:not(.ant-input-compact-first-item):not(.ant-input-compact-item-rtl), .ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-first-item:not(.ant-input-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-last-item:not(.ant-input-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
  border-color: #40a9ff;
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #40a9ff;
}

.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border: 0;
  padding: 0;
  left: -1px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 2px 2px 0;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: #00000073;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading:before {
  inset: 0;
}

.ant-input-search-button {
  height: 32px;
}

.ant-input-search-button:hover, .ant-input-search-button:focus {
  z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
  height: 40px;
}

.ant-input-search-small .ant-input-search-button {
  height: 24px;
}

.ant-input-search.ant-input-compact-item:not(.ant-input-compact-item-rtl):not(.ant-input-compact-last-item) .ant-input-group-addon .ant-input-search-button {
  border-radius: 0;
  margin-right: -1px;
}

.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input, .ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:hover, .ant-input-search.ant-input-compact-item > .ant-input:hover, .ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:hover, .ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:focus, .ant-input-search.ant-input-compact-item > .ant-input:focus, .ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:focus, .ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:active, .ant-input-search.ant-input-compact-item > .ant-input:active, .ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:active, .ant-input-search.ant-input-compact-item > .ant-input-affix-wrapper-focused {
  z-index: 2;
}

.ant-input-search.ant-input-compact-item-rtl:not(.ant-input-compact-last-item) .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0;
  margin-left: -1px;
}

.ant-input-group-wrapper-rtl, .ant-input-group-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}

.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}

.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}

.ant-input-textarea-rtl {
  direction: rtl;
}

.ant-input-textarea-rtl.ant-input-textarea-show-count:after {
  text-align: left;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-left: 4px;
  margin-right: 0;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  left: 8px;
  right: auto;
}

.ant-input-search-rtl {
  direction: rtl;
}

.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #d9d9d9;
}

.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover, .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover {
  border-left-color: #40a9ff;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover, .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #40a9ff;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child {
  left: auto;
  right: -1px;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }

  .ant-input-lg {
    height: 40px;
  }

  .ant-input-sm {
    height: 24px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}

.ant-layout {
  background: #f0f2f5;
  flex-direction: column;
  flex: auto;
  min-height: 0;
  display: flex;
}

.ant-layout, .ant-layout * {
  box-sizing: border-box;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}

.ant-layout-header, .ant-layout-footer {
  flex: none;
}

.ant-layout-header {
  color: #000000d9;
  background: #001529;
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
}

.ant-layout-footer {
  color: #000000d9;
  background: #f0f2f5;
  padding: 24px 50px;
  font-size: 14px;
}

.ant-layout-content {
  flex: auto;
  min-height: 0;
}

.ant-layout-sider {
  background: #001529;
  min-width: 0;
  transition: all .2s;
  position: relative;
}

.ant-layout-sider-children {
  height: 100%;
  margin-top: -.1px;
  padding-top: .1px;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}

.ant-layout-sider-right {
  order: 1;
}

.ant-layout-sider-trigger {
  z-index: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #002140;
  height: 48px;
  line-height: 48px;
  transition: all .2s;
  position: fixed;
  bottom: 0;
}

.ant-layout-sider-zero-width > * {
  overflow: hidden;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 1;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #001529;
  border-radius: 0 2px 2px 0;
  width: 36px;
  height: 42px;
  font-size: 18px;
  line-height: 42px;
  transition: background .3s;
  position: absolute;
  top: 64px;
  right: -36px;
}

.ant-layout-sider-zero-width-trigger:after {
  content: "";
  background: none;
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.ant-layout-sider-zero-width-trigger:hover:after {
  background: #ffffff1a;
}

.ant-layout-sider-zero-width-trigger-right {
  border-radius: 2px 0 0 2px;
  left: -36px;
}

.ant-layout-sider-light {
  background: #fff;
}

.ant-layout-sider-light .ant-layout-sider-trigger, .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #000000d9;
  background: #fff;
}

.ant-layout-rtl {
  direction: rtl;
}

.ant-list {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-list * {
  outline: none;
}

.ant-list-pagination {
  text-align: right;
  margin-top: 24px;
}

.ant-list-pagination .ant-pagination-options {
  text-align: left;
}

.ant-list-more {
  text-align: center;
  margin-top: 12px;
}

.ant-list-more button {
  padding-left: 32px;
  padding-right: 32px;
}

.ant-list-spin {
  text-align: center;
  min-height: 40px;
}

.ant-list-empty-text {
  color: #00000040;
  text-align: center;
  padding: 16px;
  font-size: 14px;
}

.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-list-item {
  color: #000000d9;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  display: flex;
}

.ant-list-item-meta {
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
  display: flex;
}

.ant-list-item-meta-avatar {
  margin-right: 16px;
}

.ant-list-item-meta-content {
  color: #000000d9;
  flex: 1 0;
  width: 0;
}

.ant-list-item-meta-title {
  color: #000000d9;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-list-item-meta-title > a {
  color: #000000d9;
  transition: all .3s;
}

.ant-list-item-meta-title > a:hover {
  color: #1890ff;
}

.ant-list-item-meta-description {
  color: #00000073;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-list-item-action {
  flex: none;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}

.ant-list-item-action > li {
  color: #00000073;
  text-align: center;
  padding: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  display: inline-block;
  position: relative;
}

.ant-list-item-action > li:first-child {
  padding-left: 0;
}

.ant-list-item-action-split {
  background-color: #f0f0f0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ant-list-header, .ant-list-footer {
  background: none;
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-list-empty {
  color: #00000073;
  text-align: center;
  padding: 16px 0;
  font-size: 12px;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}

.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}

.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}

.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}

.ant-list-vertical .ant-list-item {
  align-items: initial;
}

.ant-list-vertical .ant-list-item-main {
  flex: 1;
  display: block;
}

.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}

.ant-list-vertical .ant-list-item-meta-title {
  color: #000000d9;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}

.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}

.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}

.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}

.ant-list-grid .ant-col > .ant-list-item {
  border-bottom: none;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.ant-list-item-no-flex {
  display: block;
}

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}

.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-list-bordered .ant-list-header, .ant-list-bordered .ant-list-footer, .ant-list-bordered .ant-list-item {
  padding-left: 24px;
  padding-right: 24px;
}

.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}

.ant-list-bordered.ant-list-sm .ant-list-item, .ant-list-bordered.ant-list-sm .ant-list-header, .ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}

.ant-list-bordered.ant-list-lg .ant-list-item, .ant-list-bordered.ant-list-lg .ant-list-header, .ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}

@media screen and (width <= 768px) {
  .ant-list-item-action, .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}

@media screen and (width <= 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }

  .ant-list-item-action {
    margin-left: 12px;
  }

  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }

  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }

  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

.ant-list-rtl {
  direction: rtl;
  text-align: right;
}

.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}

.ant-list-rtl .ant-list-pagination {
  text-align: left;
}

.ant-list-rtl .ant-list-item-meta-avatar {
  margin-left: 16px;
  margin-right: 0;
}

.ant-list-rtl .ant-list-item-action {
  margin-left: 0;
  margin-right: 48px;
}

.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-left: 16px;
  padding-right: 0;
}

.ant-list-rtl .ant-list-item-action-split {
  left: 0;
  right: auto;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-left: 0;
  margin-right: 40px;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}

.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 16px;
  padding-right: 0;
}

.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}

@media screen and (width <= 768px) {
  .ant-list-rtl .ant-list-item-action, .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-left: 0;
    margin-right: 24px;
  }
}

@media screen and (width <= 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-left: 0;
    margin-right: 22px;
  }

  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

.ant-pagination {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-pagination ul, .ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-pagination:after {
  clear: both;
  visibility: hidden;
  content: " ";
  height: 0;
  display: block;
  overflow: hidden;
}

.ant-pagination-total-text {
  vertical-align: middle;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  display: inline-block;
}

.ant-pagination-item {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 30px;
  list-style: none;
  display: inline-block;
}

.ant-pagination-item a {
  color: #000000d9;
  padding: 0 6px;
  transition: none;
  display: block;
}

.ant-pagination-item a:hover {
  text-decoration: none;
}

.ant-pagination-item:hover {
  border-color: #1890ff;
  transition: all .3s;
}

.ant-pagination-item:hover a {
  color: #1890ff;
}

.ant-pagination-item:focus-visible {
  border-color: #1890ff;
  transition: all .3s;
}

.ant-pagination-item:focus-visible a {
  color: #1890ff;
}

.ant-pagination-item-active {
  background: #fff;
  border-color: #1890ff;
  font-weight: 500;
}

.ant-pagination-item-active a {
  color: #1890ff;
}

.ant-pagination-item-active:hover, .ant-pagination-item-active:focus-visible {
  border-color: #40a9ff;
}

.ant-pagination-item-active:hover a, .ant-pagination-item-active:focus-visible a {
  color: #40a9ff;
}

.ant-pagination-jump-prev, .ant-pagination-jump-next {
  outline: 0;
}

.ant-pagination-jump-prev .ant-pagination-item-container, .ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1890ff;
  letter-spacing: -1px;
  opacity: 0;
  font-size: 12px;
  transition: all .2s;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  margin: auto;
  inset: 0;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #00000040;
  letter-spacing: 2px;
  text-align: center;
  text-indent: .13em;
  opacity: 1;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  transition: all .2s;
  display: block;
  position: absolute;
  inset: 0;
}

.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon, .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis, .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}

.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon, .ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis, .ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}

.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  margin-right: 8px;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  color: #000000d9;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  min-width: 32px;
  height: 32px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 32px;
  list-style: none;
  transition: all .3s;
  display: inline-block;
}

.ant-pagination-prev, .ant-pagination-next {
  outline: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.ant-pagination-prev button, .ant-pagination-next button {
  color: #000000d9;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-prev:hover button, .ant-pagination-next:hover button {
  border-color: #40a9ff;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  transition: all .3s;
  display: block;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}

.ant-pagination-disabled, .ant-pagination-disabled:hover {
  cursor: not-allowed;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}

.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.ant-pagination-slash {
  margin: 0 10px 0 5px;
}

.ant-pagination-options {
  vertical-align: middle;
  margin-left: 16px;
  display: inline-block;
}

@media (-ms-high-contrast: none) {
  .ant-pagination-options {
    vertical-align: top;
  }

  .ant-pagination-options ::-ms-backdrop {
    vertical-align: top;
  }
}

.ant-pagination-options-size-changer.ant-select {
  width: auto;
  display: inline-block;
}

.ant-pagination-options-quick-jumper {
  vertical-align: top;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  display: inline-block;
}

.ant-pagination-options-quick-jumper input {
  color: #000000d9;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 50px;
  min-width: 0;
  height: 32px;
  margin: 0 8px;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input:focus, .ant-pagination-options-quick-jumper input-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-pagination-options-quick-jumper input-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-pagination-options-quick-jumper input-borderless, .ant-pagination-options-quick-jumper input-borderless:hover, .ant-pagination-options-quick-jumper input-borderless:focus, .ant-pagination-options-quick-jumper input-borderless-focused, .ant-pagination-options-quick-jumper input-borderless-disabled, .ant-pagination-options-quick-jumper input-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-pagination-options-quick-jumper input {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-pagination-options-quick-jumper input-sm {
  padding: 0 7px;
}

.ant-pagination-simple .ant-pagination-prev, .ant-pagination-simple .ant-pagination-next {
  vertical-align: top;
  height: 24px;
  line-height: 24px;
}

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  background-color: #0000;
  border: 0;
  height: 24px;
}

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after, .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}

.ant-pagination-simple .ant-pagination-simple-pager {
  height: 24px;
  margin-right: 8px;
  display: inline-block;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  transition: border-color .3s;
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination.ant-pagination-mini .ant-pagination-total-text, .ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: none;
  border-color: #0000;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev, .ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: none;
  border-color: #0000;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link:after, .ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev, .ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  width: 44px;
  height: 24px;
  padding: 0 7px;
}

.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: #00000040;
  cursor: not-allowed;
  background: none;
  border: none;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #00000040;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: none;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: #00000040;
}

@media only screen and (width <= 992px) {
  .ant-pagination-item-after-jump-prev, .ant-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (width <= 576px) {
  .ant-pagination-options {
    display: none;
  }
}

.ant-pagination-rtl .ant-pagination-total-text, .ant-pagination-rtl .ant-pagination-item, .ant-pagination-rtl .ant-pagination-prev, .ant-pagination-rtl .ant-pagination-jump-prev, .ant-pagination-rtl .ant-pagination-jump-next {
  margin-left: 8px;
  margin-right: 0;
}

.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}

.ant-pagination-rtl .ant-pagination-options {
  margin-left: 0;
  margin-right: 16px;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-left: 8px;
  margin-right: 0;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}

.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager, .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-left: 8px;
  margin-right: 0;
}

.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-left: 0;
  margin-right: 2px;
}

.ant-spin {
  box-sizing: border-box;
  color: #1890ff;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1.5715;
  list-style: none;
  transition: transform .3s cubic-bezier(.78, .14, .15, .86);
  display: none;
  position: absolute;
}

.ant-spin-spinning {
  opacity: 1;
  display: inline-block;
  position: static;
}

.ant-spin-nested-loading {
  position: relative;
}

.ant-spin-nested-loading > div > .ant-spin {
  z-index: 4;
  width: 100%;
  height: 100%;
  max-height: 400px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  margin: -10px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  text-shadow: 0 1px 2px #fff;
  width: 100%;
  padding-top: 5px;
  font-size: 14px;
  position: absolute;
  top: 50%;
}

.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}

.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}

.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}

.ant-spin-container {
  transition: opacity .3s;
  position: relative;
}

.ant-spin-container:after {
  z-index: 10;
  display: none \9 ;
  opacity: 0;
  content: "";
  pointer-events: none;
  background: #fff;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.ant-spin-blur {
  clear: both;
  opacity: .5;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.ant-spin-blur:after {
  opacity: .4;
  pointer-events: auto;
}

.ant-spin-tip {
  color: #00000073;
}

.ant-spin-dot {
  width: 1em;
  height: 1em;
  font-size: 20px;
  display: inline-block;
  position: relative;
}

.ant-spin-dot-item {
  transform-origin: 50%;
  opacity: .3;
  background-color: #1890ff;
  border-radius: 100%;
  width: 9px;
  height: 9px;
  animation: 1s linear infinite alternate antSpinMove;
  display: block;
  position: absolute;
  transform: scale(.75);
}

.ant-spin-dot-item:first-child {
  top: 0;
  left: 0;
}

.ant-spin-dot-item:nth-child(2) {
  animation-delay: .4s;
  top: 0;
  right: 0;
}

.ant-spin-dot-item:nth-child(3) {
  animation-delay: .8s;
  bottom: 0;
  right: 0;
}

.ant-spin-dot-item:nth-child(4) {
  animation-delay: 1.2s;
  bottom: 0;
  left: 0;
}

.ant-spin-dot-spin {
  animation: 1.2s linear infinite antRotate;
  transform: rotate(0);
}

.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}

.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}

.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ant-spin-blur {
    opacity: .5;
    background: #fff;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}

.ant-spin-rtl {
  direction: rtl;
}

.ant-spin-rtl .ant-spin-dot-spin {
  animation-name: antRotateRtl;
  transform: rotate(-45deg);
}

@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}

.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions, .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #ff4d4f;
}

.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus, .ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ff7875;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #ff4d4f33;
}

.ant-mentions-status-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions, .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #faad14;
}

.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus, .ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ffc53d;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #faad1433;
}

.ant-mentions-status-warning .ant-input-prefix {
  color: #faad14;
}

.ant-mentions {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  color: #000000d9;
  white-space: pre-wrap;
  vertical-align: bottom;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  min-width: 0;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-mentions::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-mentions:focus, .ant-mentions-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-mentions-disabled {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-mentions[disabled] {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-mentions-borderless, .ant-mentions-borderless:hover, .ant-mentions-borderless:focus, .ant-mentions-borderless-focused, .ant-mentions-borderless-disabled, .ant-mentions-borderless[disabled] {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

textarea.ant-mentions {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  transition: all .3s, height;
}

.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-mentions-sm {
  padding: 0 7px;
}

.ant-mentions-disabled > textarea {
  color: #00000040;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}

.ant-mentions-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-mentions > textarea, .ant-mentions-measure {
  overflow: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
  -o-tab-size: inherit;
  tab-size: inherit;
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-mentions > textarea {
  resize: none;
  border: none;
  outline: none;
  width: 100%;
}

.ant-mentions > textarea::-moz-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.ant-mentions > textarea:-moz-placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions > textarea:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions-measure {
  z-index: -1;
  color: #0000;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.ant-mentions-measure > span {
  min-height: 1em;
  display: inline-block;
}

.ant-mentions-dropdown {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1050;
  box-sizing: border-box;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-mentions-dropdown-hidden {
  display: none;
}

.ant-mentions-dropdown-menu {
  outline: none;
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  overflow: auto;
}

.ant-mentions-dropdown-menu-item {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  min-width: 100px;
  padding: 5px 12px;
  font-weight: normal;
  line-height: 1.5715;
  transition: background .3s;
  display: block;
  position: relative;
  overflow: hidden;
}

.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}

.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}

.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}

.ant-mentions-dropdown-menu-item-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-mentions-dropdown-menu-item-disabled:hover {
  color: #00000040;
  cursor: not-allowed;
  background-color: #fff;
}

.ant-mentions-dropdown-menu-item-selected {
  color: #000000d9;
  background-color: #fafafa;
  font-weight: 600;
}

.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}

.ant-mentions-suffix {
  z-index: 1;
  align-items: center;
  margin: auto;
  display: inline-flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
}

.ant-mentions-rtl {
  direction: rtl;
}

.ant-message {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1010;
  pointer-events: none;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: fixed;
  top: 8px;
  left: 0;
}

.ant-message-notice {
  text-align: center;
  padding: 8px;
}

.ant-message-notice-content {
  pointer-events: all;
  background: #fff;
  border-radius: 2px;
  padding: 10px 16px;
  display: inline-block;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon, .ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  margin-right: 8px;
  font-size: 16px;
  position: relative;
  top: 1px;
}

.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: .3s;
}

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px;
  }

  100% {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}

.ant-message-rtl, .ant-message-rtl span {
  direction: rtl;
}

.ant-message-rtl .anticon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-modal {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  pointer-events: none;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 0 0 24px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  top: 100px;
}

.ant-modal.ant-zoom-enter, .ant-modal.ant-zoom-appear {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  animation-duration: .3s;
  transform: none;
}

.ant-modal-mask {
  z-index: 1000;
  background-color: #00000073;
  height: 100%;
  position: fixed;
  inset: 0;
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  z-index: 1000;
  outline: 0;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.ant-modal-title {
  color: #000000d9;
  word-wrap: break-word;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.ant-modal-content {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-modal-close {
  z-index: 10;
  color: #00000073;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  padding: 0;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.ant-modal-close-x {
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  display: block;
}

.ant-modal-close:focus, .ant-modal-close:hover {
  color: #000000bf;
  text-decoration: none;
}

.ant-modal-header {
  color: #000000d9;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  padding: 16px 24px;
}

.ant-modal-body {
  word-wrap: break-word;
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
}

.ant-modal-footer {
  text-align: right;
  background: none;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  padding: 10px 16px;
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered:before {
  vertical-align: middle;
  content: "";
  width: 0;
  height: 100%;
  display: inline-block;
}

.ant-modal-centered .ant-modal {
  text-align: left;
  vertical-align: middle;
  padding-bottom: 0;
  display: inline-block;
  top: 0;
}

@media (width <= 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper:before {
  content: "";
  display: table;
}

.ant-modal-confirm-body-wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #000000d9;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  display: block;
  overflow: hidden;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #000000d9;
  margin-top: 8px;
  font-size: 14px;
}

.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: right;
  margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon, .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

.ant-modal-confirm .ant-zoom-leave .ant-modal-confirm-btns {
  pointer-events: none;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-left: 0;
  margin-right: 8px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0;
  margin-right: 38px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-left: 0;
  margin-right: 8px;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

.ant-notification {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  z-index: 1010;
  margin: 0 24px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: fixed;
}

.ant-notification-close-icon {
  cursor: pointer;
  font-size: 14px;
}

.ant-notification-hook-holder {
  position: relative;
}

.ant-notification-notice {
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  line-height: 1.5715;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-notification-top .ant-notification-notice, .ant-notification-bottom .ant-notification-notice {
  margin-left: auto;
  margin-right: auto;
}

.ant-notification-topLeft .ant-notification-notice, .ant-notification-bottomLeft .ant-notification-notice {
  margin-left: 0;
  margin-right: auto;
}

.ant-notification-notice-message {
  color: #000000d9;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
}

.ant-notification-notice-message-single-line-auto-margin {
  pointer-events: none;
  background-color: #0000;
  width: calc(264px - 100%);
  max-width: 4px;
  display: block;
}

.ant-notification-notice-message-single-line-auto-margin:before {
  content: "";
  display: block;
}

.ant-notification-notice-description {
  font-size: 14px;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}

.ant-notification-notice-icon {
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
  position: absolute;
}

.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}

.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}

.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}

.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}

.ant-notification-notice-close {
  color: #00000073;
  outline: none;
  position: absolute;
  top: 16px;
  right: 22px;
}

.ant-notification-notice-close:hover {
  color: #000000ab;
}

.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}

.ant-notification .notification-fade-effect {
  animation-duration: .24s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: both;
}

.ant-notification-fade-enter, .ant-notification-fade-appear {
  opacity: 0;
  animation-duration: .24s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-notification-fade-leave {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}

@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    left: 384px;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes NotificationFadeOut {
  0% {
    opacity: 1;
    max-height: 150px;
    margin-bottom: 16px;
  }

  100% {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ant-notification-rtl {
  direction: rtl;
}

.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message, .ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 0;
  margin-right: 48px;
}

.ant-notification-rtl .ant-notification-notice-icon {
  margin-left: 0;
  margin-right: 4px;
}

.ant-notification-rtl .ant-notification-notice-close {
  left: 22px;
  right: auto;
}

.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}

.ant-notification-top, .ant-notification-bottom {
  margin-left: 0;
  margin-right: 0;
}

.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}

.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}

.ant-notification-topLeft, .ant-notification-bottomLeft {
  margin-left: 24px;
  margin-right: 0;
}

.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active, .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active, .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}

@keyframes NotificationTopFadeIn {
  0% {
    opacity: 0;
    margin-top: -100%;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes NotificationBottomFadeIn {
  0% {
    opacity: 0;
    margin-bottom: -100%;
  }

  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

@keyframes NotificationLeftFadeIn {
  0% {
    opacity: 0;
    right: 384px;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

.ant-page-header {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fff;
  margin: 0;
  padding: 16px 24px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-page-header-ghost {
  background-color: inherit;
}

.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}

.ant-page-header.has-footer {
  padding-bottom: 0;
}

.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}

.ant-page-header-back-button {
  color: #000;
  cursor: pointer;
  outline: none;
  transition: color .3s;
}

.ant-page-header-back-button:focus-visible, .ant-page-header-back-button:hover {
  color: #40a9ff;
}

.ant-page-header-back-button:active {
  color: #096dd9;
}

.ant-page-header .ant-divider-vertical {
  vertical-align: middle;
  height: 14px;
  margin: 0 12px;
}

.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}

.ant-page-header-heading {
  justify-content: space-between;
  display: flex;
}

.ant-page-header-heading-left {
  align-items: center;
  margin: 4px 0;
  display: flex;
  overflow: hidden;
}

.ant-page-header-heading-title {
  color: #000000d9;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-right: 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  overflow: hidden;
}

.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}

.ant-page-header-heading-sub-title {
  color: #00000073;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 12px;
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
}

.ant-page-header-heading-extra {
  white-space: nowrap;
  margin: 4px 0;
}

.ant-page-header-heading-extra > * {
  white-space: unset;
}

.ant-page-header-content {
  padding-top: 12px;
}

.ant-page-header-footer {
  margin-top: 16px;
}

.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}

.ant-page-header-footer .ant-tabs > .ant-tabs-nav:before {
  border: none;
}

.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}

.ant-page-header-rtl {
  direction: rtl;
}

.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-title, .ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-left: 12px;
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-left: 12px;
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}

.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}

.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-left: 0;
  margin-right: 12px;
}

.ant-page-header-rtl .ant-page-header-heading-extra > :first-child {
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}

.ant-popconfirm {
  z-index: 1060;
}

.ant-progress {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-progress-line {
  width: 100%;
  font-size: 14px;
  position: relative;
}

.ant-progress-steps {
  display: inline-block;
}

.ant-progress-steps-outer {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ant-progress-steps-item {
  background: #f3f3f3;
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  transition: all .3s;
}

.ant-progress-steps-item-active {
  background: #1890ff;
}

.ant-progress-small.ant-progress-line, .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}

.ant-progress-outer {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
  display: inline-block;
}

.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}

.ant-progress-inner {
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-progress-circle-trail {
  stroke: #f5f5f5;
}

.ant-progress-circle-path {
  animation: .3s ant-progress-appear;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}

.ant-progress-success-bg, .ant-progress-bg {
  background-color: #1890ff;
  border-radius: 100px;
  transition: all .4s cubic-bezier(.08, .82, .17, 1);
  position: relative;
}

.ant-progress-success-bg {
  background-color: #52c41a;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-progress-text {
  color: #000000d9;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
  width: 2em;
  margin-left: 8px;
  font-size: 1em;
  line-height: 1;
  display: inline-block;
}

.ant-progress-text .anticon {
  font-size: 14px;
}

.ant-progress-status-active .ant-progress-bg:before {
  opacity: 0;
  content: "";
  background: #fff;
  border-radius: 10px;
  animation: 2.4s cubic-bezier(.23, 1, .32, 1) infinite ant-progress-active;
  position: absolute;
  inset: 0;
}

.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}

.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}

.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}

.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}

.ant-progress-circle .ant-progress-inner {
  background-color: #0000;
  line-height: 1;
  position: relative;
}

.ant-progress-circle .ant-progress-text {
  color: #000000d9;
  white-space: normal;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16667em;
}

.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}

@keyframes ant-progress-active {
  0% {
    opacity: .1;
    transform: translateX(-100%)scaleX(0);
  }

  20% {
    opacity: .5;
    transform: translateX(-100%)scaleX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(0)scaleX(1);
  }
}

.ant-progress-rtl {
  direction: rtl;
}

.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-left: calc(-2em - 8px);
  margin-right: 0;
  padding-left: calc(2em + 8px);
  padding-right: 0;
}

.ant-progress-rtl .ant-progress-success-bg {
  left: auto;
  right: 0;
}

.ant-progress-rtl.ant-progress-line .ant-progress-text, .ant-progress-rtl.ant-progress-steps .ant-progress-text {
  text-align: right;
  margin-left: 0;
  margin-right: 8px;
}

.ant-rate {
  box-sizing: border-box;
  color: #fadb14;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 20px;
  line-height: 1.5715;
  line-height: unset;
  outline: none;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.ant-rate-disabled .ant-rate-star {
  cursor: default;
}

.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}

.ant-rate-star {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}

.ant-rate-star > div {
  transition: all .3s, outline;
}

.ant-rate-star > div:hover {
  transform: scale(1.1);
}

.ant-rate-star > div:focus {
  outline: 0;
}

.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}

.ant-rate-star-first, .ant-rate-star-second {
  color: #f0f0f0;
  -webkit-user-select: none;
  user-select: none;
  transition: all .3s;
}

.ant-rate-star-first .anticon, .ant-rate-star-second .anticon {
  vertical-align: middle;
}

.ant-rate-star-first {
  opacity: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.ant-rate-star-half .ant-rate-star-first, .ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}

.ant-rate-star-half .ant-rate-star-first, .ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}

.ant-rate-text {
  margin: 0 8px;
  font-size: 14px;
  display: inline-block;
}

.ant-rate-rtl {
  direction: rtl;
}

.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-left: 8px;
  margin-right: 0;
}

.ant-rate-rtl .ant-rate-star-first {
  left: auto;
  right: 0;
}

.ant-result {
  padding: 48px 32px;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}

.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}

.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}

.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}

.ant-result-icon {
  text-align: center;
  margin-bottom: 24px;
}

.ant-result-icon > .anticon {
  font-size: 72px;
}

.ant-result-title {
  color: #000000d9;
  text-align: center;
  font-size: 24px;
  line-height: 1.8;
}

.ant-result-subtitle {
  color: #00000073;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;
}

.ant-result-extra {
  text-align: center;
  margin: 24px 0 0;
}

.ant-result-extra > * {
  margin-right: 8px;
}

.ant-result-extra > :last-child {
  margin-right: 0;
}

.ant-result-content {
  background-color: #fafafa;
  margin-top: 24px;
  padding: 24px 40px;
}

.ant-result-rtl {
  direction: rtl;
}

.ant-result-rtl .ant-result-extra > * {
  margin-left: 8px;
  margin-right: 0;
}

.ant-result-rtl .ant-result-extra > :last-child {
  margin-left: 0;
}

.segmented-disabled-item, .segmented-disabled-item:hover, .segmented-disabled-item:focus {
  color: #00000040;
  cursor: not-allowed;
}

.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
}

.segmented-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
}

.ant-segmented {
  box-sizing: border-box;
  color: #000000a6;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #0000000a;
  border-radius: 2px;
  margin: 0;
  padding: 2px;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  display: inline-block;
}

.ant-segmented-group {
  place-items: stretch flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.ant-segmented.ant-segmented-block {
  display: flex;
}

.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}

.ant-segmented:not(.ant-segmented-disabled):hover, .ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: #0000000f;
}

.ant-segmented-item {
  text-align: center;
  cursor: pointer;
  transition: color .3s cubic-bezier(.645, .045, .355, 1);
  position: relative;
}

.ant-segmented-item-selected {
  color: #262626;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
}

.ant-segmented-item:hover, .ant-segmented-item:focus {
  color: #262626;
}

.ant-segmented-item-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  min-height: 28px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
}

.ant-segmented-item-icon + * {
  margin-left: 6px;
}

.ant-segmented-item-input {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}

.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}

.ant-segmented-item-disabled, .ant-segmented-item-disabled:hover, .ant-segmented-item-disabled:focus {
  color: #00000040;
  cursor: not-allowed;
}

.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  width: 0;
  height: 100%;
  padding: 4px 0;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012, 0 0 1px #00000014;
}

.ant-segmented-thumb-motion-appear-active {
  will-change: transform, width;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1), width .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}

.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-left: 6px;
  margin-right: 0;
}

.ant-slider {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  cursor: pointer;
  touch-action: none;
  height: 12px;
  margin: 10px 6px;
  padding: 4px 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}

.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}

.ant-slider-vertical .ant-slider-track {
  width: 4px;
}

.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}

.ant-slider-vertical .ant-slider-mark {
  width: 18px;
  height: 100%;
  top: 0;
  left: 12px;
}

.ant-slider-vertical .ant-slider-mark-text {
  white-space: nowrap;
  left: 4px;
}

.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}

.ant-slider-vertical .ant-slider-dot {
  margin-left: -2px;
  top: auto;
}

.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-left: 0;
  margin-right: -5px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  left: auto;
  right: 12px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  left: auto;
  right: 4px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  left: auto;
  right: 2px;
}

.ant-slider-with-marks {
  margin-bottom: 28px;
}

.ant-slider-rail {
  background-color: #f5f5f5;
  border-radius: 2px;
  width: 100%;
  height: 4px;
  transition: background-color .3s;
  position: absolute;
}

.ant-slider-track {
  background-color: #91d5ff;
  border-radius: 2px;
  height: 4px;
  transition: background-color .3s;
  position: absolute;
}

.ant-slider-handle {
  box-shadow: 0;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #91d5ff;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  transition: border-color .3s, box-shadow .6s, transform .3s cubic-bezier(.18, .89, .32, 1.28);
  position: absolute;
}

.ant-slider-handle-dragging {
  z-index: 1;
}

.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px #1890ff1f;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}

.ant-slider-handle:after {
  content: "";
  position: absolute;
  inset: -6px;
}

.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}

.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}

.ant-slider-mark {
  width: 100%;
  font-size: 14px;
  position: absolute;
  top: 14px;
  left: 0;
}

.ant-slider-mark-text {
  color: #00000073;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  position: absolute;
}

.ant-slider-mark-text-active {
  color: #000000d9;
}

.ant-slider-step {
  pointer-events: none;
  background: none;
  width: 100%;
  height: 4px;
  position: absolute;
}

.ant-slider-dot {
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -2px;
}

.ant-slider-dot-active {
  border-color: #8cc8ff;
}

.ant-slider-disabled {
  cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}

.ant-slider-disabled .ant-slider-track {
  background-color: #00000040 !important;
}

.ant-slider-disabled .ant-slider-handle, .ant-slider-disabled .ant-slider-dot {
  box-shadow: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #00000040 !important;
}

.ant-slider-disabled .ant-slider-mark-text, .ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}

.ant-slider-rtl {
  direction: rtl;
}

.ant-slider-rtl .ant-slider-mark {
  left: auto;
  right: 0;
}

.ant-statistic {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-statistic-title {
  color: #00000073;
  margin-bottom: 4px;
  font-size: 14px;
}

.ant-statistic-skeleton {
  padding-top: 16px;
}

.ant-statistic-content {
  color: #000000d9;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 24px;
}

.ant-statistic-content-value {
  direction: ltr;
  display: inline-block;
}

.ant-statistic-content-prefix, .ant-statistic-content-suffix {
  display: inline-block;
}

.ant-statistic-content-prefix {
  margin-right: 4px;
}

.ant-statistic-content-suffix {
  margin-left: 4px;
}

.ant-statistic-rtl {
  direction: rtl;
}

.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-left: 4px;
  margin-right: 0;
}

.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-left: 0;
  margin-right: 4px;
}

.ant-steps {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  text-align: initial;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1.5715;
  list-style: none;
  display: flex;
}

.ant-steps-item {
  vertical-align: top;
  flex: 1;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ant-steps-item-container {
  outline: none;
}

.ant-steps-item:last-child {
  flex: none;
}

.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail, .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  display: none;
}

.ant-steps-item-icon, .ant-steps-item-content {
  vertical-align: top;
  display: inline-block;
}

.ant-steps-item-icon {
  text-align: center;
  border: 1px solid #00000040;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 16px;
  line-height: 32px;
  transition: background-color .3s, border-color .3s;
}

.ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
  line-height: 1;
  position: relative;
  top: -.5px;
}

.ant-steps-item-tail {
  width: 100%;
  padding: 0 10px;
  position: absolute;
  top: 12px;
  left: 0;
}

.ant-steps-item-tail:after {
  content: "";
  background: #f0f0f0;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  transition: background .3s;
  display: inline-block;
}

.ant-steps-item-title {
  color: #000000d9;
  padding-right: 16px;
  font-size: 16px;
  line-height: 32px;
  display: inline-block;
  position: relative;
}

.ant-steps-item-title:after {
  content: "";
  background: #f0f0f0;
  width: 9999px;
  height: 1px;
  display: block;
  position: absolute;
  top: 16px;
  left: 100%;
}

.ant-steps-item-subtitle {
  color: #00000073;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  display: inline;
}

.ant-steps-item-description {
  color: #00000073;
  font-size: 14px;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: #00000040;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #00000040;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #00000040;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #00000073;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #f0f0f0;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #00000073;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #f0f0f0;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #000000d9;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #f0f0f0;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #000000d9;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #f0f0f0;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #1890ff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #000000d9;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #1890ff;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #00000073;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #1890ff;
}

.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #f0f0f0;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #f0f0f0;
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
  background: #ff4d4f;
}

.ant-steps-item-disabled {
  cursor: not-allowed;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] {
  cursor: pointer;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-description, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] .ant-steps-item-icon .ant-steps-icon {
  transition: color .3s;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
  color: #1890ff;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
  border-color: #1890ff;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  white-space: nowrap;
  padding-left: 16px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  white-space: normal;
  max-width: 140px;
}

.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  background: none;
  border: 0;
  height: auto;
}

.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
  top: 0;
  left: .5px;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  background: none;
  width: auto;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}

.ant-steps-small .ant-steps-item-icon {
  text-align: center;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
}

.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}

.ant-steps-small .ant-steps-item-title:after {
  top: 12px;
}

.ant-steps-small .ant-steps-item-description {
  color: #00000073;
  font-size: 14px;
}

.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}

.ant-steps-vertical {
  flex-direction: column;
  display: flex;
}

.ant-steps-vertical > .ant-steps-item {
  flex: 1 0 auto;
  padding-left: 0;
  display: block;
  overflow: visible;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  min-height: 48px;
  display: block;
  overflow: hidden;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
  position: absolute;
  top: 0;
  left: 15px;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
  width: 1px;
  height: 100%;
}

.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:after {
  display: none;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  padding: 30px 0 6px;
  position: absolute;
  top: 0;
  left: 11px;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}

.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}

.ant-steps-label-vertical .ant-steps-item-content {
  text-align: center;
  width: 116px;
  margin-top: 8px;
  display: block;
}

.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: 42px;
  display: inline-block;
}

.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
  padding-right: 0;
}

.ant-steps-label-vertical .ant-steps-item-title:after {
  display: none;
}

.ant-steps-label-vertical .ant-steps-item-subtitle {
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
  display: block;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}

.ant-steps-dot .ant-steps-item-title, .ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}

.ant-steps-dot .ant-steps-item-tail, .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
  top: 2px;
}

.ant-steps-dot .ant-steps-item-tail:after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}

.ant-steps-dot .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  background: none;
  border: 0;
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: left;
  border-radius: 100px;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: relative;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
  content: "";
  background: none;
  width: 60px;
  height: 32px;
  position: absolute;
  top: -12px;
  left: -26px;
}

.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  background: none;
  width: 10px;
  height: 10px;
  line-height: 10px;
  position: relative;
  top: -1px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  background: none;
  margin-top: 13px;
  margin-left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  margin: 0;
  padding: 22px 0 4px;
  top: 6.5px;
  left: -9px;
}

.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}

.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}

.ant-steps-navigation {
  padding-top: 12px;
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}

.ant-steps-navigation .ant-steps-item {
  text-align: center;
  overflow: visible;
}

.ant-steps-navigation .ant-steps-item-container {
  text-align: left;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  transition: opacity .3s;
  display: inline-block;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title:after {
  display: none;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"] {
  cursor: pointer;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role="button"]:hover {
  opacity: .85;
}

.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}

.ant-steps-navigation .ant-steps-item:last-child:after {
  display: none;
}

.ant-steps-navigation .ant-steps-item:after {
  content: "";
  border-top: 1px solid #00000040;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #00000040;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: rotate(45deg);
}

.ant-steps-navigation .ant-steps-item:before {
  content: "";
  background-color: #1890ff;
  width: 0;
  height: 2px;
  transition: width .3s ease-out, left .3s ease-out;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active:before {
  width: 100%;
  left: 0;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item:before {
  display: none;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active:before {
  top: 0;
  right: 0;
  left: unset;
  width: 3px;
  height: calc(100% - 24px);
  display: block;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item:after {
  text-align: center;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  display: block;
  position: relative;
  top: -2px;
  left: 50%;
  transform: rotate(135deg);
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail, .ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}

.ant-steps-rtl {
  direction: rtl;
}

.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-steps-rtl .ant-steps-item-tail {
  left: auto;
  right: 0;
}

.ant-steps-rtl .ant-steps-item-title {
  padding-left: 16px;
  padding-right: 0;
}

.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-left: 0;
  margin-right: 8px;
}

.ant-steps-rtl .ant-steps-item-title:after {
  left: auto;
  right: 100%;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0;
  padding-right: 16px;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  left: auto;
  right: .5px;
}

.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: 0;
  margin-right: -12px;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  text-align: right;
  margin-left: 0;
  margin-right: -16px;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item:after {
  margin-left: 0;
  margin-right: -2px;
  left: auto;
  right: 100%;
  transform: rotate(225deg);
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0;
  padding-right: 12px;
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-left: 12px;
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: auto;
  right: 16px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  left: auto;
  right: 12px;
}

.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail:after, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
  margin-left: 0;
  margin-right: 12px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: auto;
  right: 2px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-left: 0;
  margin-right: 67px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after, .ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
  left: auto;
  right: -26px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-left: 16px;
  margin-right: 0;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: auto;
  right: -9px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: auto;
  right: 0;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: auto;
  right: -2px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item {
  padding-right: 4px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 19px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-small.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 15px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-left: 0;
  padding-right: 4px;
}

.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}

.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}

.ant-steps-with-progress .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 4px;
  left: 19px;
}

.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child, .ant-steps-with-progress.ant-steps-small.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}

.ant-steps-with-progress.ant-steps-small > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 15px;
}

.ant-steps-with-progress.ant-steps-vertical .ant-steps-item {
  padding-left: 4px;
}

.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 14px !important;
}

.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}

.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  inset: -5px;
}

.ant-switch {
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #00000040;
  border: 0;
  border-radius: 100px;
  min-width: 44px;
  height: 22px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #0000001a;
}

.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #e6f7ff;
}

.ant-switch:focus:hover {
  box-shadow: none;
}

.ant-switch-checked {
  background-color: #1890ff;
}

.ant-switch-loading, .ant-switch-disabled {
  cursor: not-allowed;
  opacity: .4;
}

.ant-switch-loading *, .ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}

.ant-switch-inner {
  color: #fff;
  margin: 0 7px 0 25px;
  font-size: 12px;
  transition: margin .2s;
  display: block;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}

.ant-switch-handle {
  width: 18px;
  height: 18px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 2px;
  left: 2px;
}

.ant-switch-handle:before {
  content: "";
  background-color: #fff;
  border-radius: 9px;
  transition: all .2s ease-in-out;
  position: absolute;
  inset: 0;
  box-shadow: 0 2px 4px #00230b33;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 20px);
}

.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle:before {
  left: 0;
  right: -30%;
}

.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle:before {
  left: -30%;
  right: 0;
}

.ant-switch-loading-icon.anticon {
  color: #000000a6;
  vertical-align: top;
  position: relative;
  top: 2px;
}

.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff;
}

.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}

.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}

.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}

.ant-switch-small .ant-switch-loading-icon {
  font-size: 9px;
  top: 1.5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 14px);
}

.ant-switch-rtl {
  direction: rtl;
}

.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}

.ant-switch-rtl .ant-switch-handle {
  left: auto;
  right: 2px;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle:before {
  left: -30%;
  right: 0;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle:before {
  left: 0;
  right: -30%;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 20px);
}

.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 14px);
}

.ant-table.ant-table-middle {
  font-size: 14px;
}

.ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th, .ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}

.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}

.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 40px;
}

.ant-table.ant-table-middle .ant-table-selection-column {
  padding-inline-start: 2px;
}

.ant-table.ant-table-small {
  font-size: 14px;
}

.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
  padding: 8px;
}

.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}

.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 40px;
}

.ant-table.ant-table-small .ant-table-selection-column {
  padding-inline-start: 2px;
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:before, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:before, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th:before, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th:before {
  background-color: #0000 !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first:after {
  border-right: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed:after, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed:after {
  content: "";
  border-right: 1px solid #f0f0f0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1px;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td, .ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}

.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}

.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed, .ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}

.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}

.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}

.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}

.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}

.ant-table-wrapper:before {
  content: "";
  display: table;
}

.ant-table-wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.ant-table {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  position: relative;
}

.ant-table table {
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 2px 2px 0 0;
  width: 100%;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  overflow-wrap: break-word;
  padding: 16px;
  position: relative;
}

.ant-table-cell-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last, .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content, .ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.ant-table-cell-ellipsis .ant-table-column-title {
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
}

.ant-table-title {
  padding: 16px;
}

.ant-table-footer {
  color: #000000d9;
  background: #fafafa;
  padding: 16px;
}

.ant-table-thead > tr > th {
  color: #000000d9;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  transition: background .3s;
  position: relative;
}

.ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
  text-align: center;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  content: "";
  background-color: #0000000f;
  width: 1px;
  height: 1.6em;
  transition: background-color .3s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background .3s;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 32px;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child, .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6f7ff;
  border-color: #00000008;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #dcf4ff;
}

.ant-table-summary {
  z-index: 2;
  background: #fff;
  position: relative;
}

div.ant-table-summary {
  box-shadow: 0 -1px #f0f0f0;
}

.ant-table-summary > tr > th, .ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}

.ant-table-pagination {
  flex-wrap: wrap;
  row-gap: 8px;
  display: flex;
}

.ant-table-pagination > * {
  flex: none;
}

.ant-table-pagination-left {
  justify-content: flex-start;
}

.ant-table-pagination-center {
  justify-content: center;
}

.ant-table-pagination-right {
  justify-content: flex-end;
}

.ant-table-thead th.ant-table-column-has-sorters {
  cursor: pointer;
  outline: none;
  transition: all .3s;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #0000000a;
}

.ant-table-thead th.ant-table-column-has-sorters:hover:before {
  background-color: #0000 !important;
}

.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #1890ff;
}

.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover, .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover, .ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}

.ant-table-thead th.ant-table-column-sort:before {
  background-color: #0000 !important;
}

td.ant-table-column-sort {
  background: #fafafa;
}

.ant-table-column-title {
  z-index: 1;
  flex: 1;
  position: relative;
}

.ant-table-column-sorters {
  flex: auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ant-table-column-sorters:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-table-column-sorter {
  color: #bfbfbf;
  margin-left: 4px;
  font-size: 0;
  transition: color .3s;
}

.ant-table-column-sorter-inner {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
}

.ant-table-column-sorter-up, .ant-table-column-sorter-down {
  font-size: 11px;
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
  color: #1890ff;
}

.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -.3em;
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}

.ant-table-filter-column {
  justify-content: space-between;
  display: flex;
}

.ant-table-filter-trigger {
  color: #bfbfbf;
  cursor: pointer;
  border-radius: 2px;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  font-size: 12px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.ant-table-filter-trigger:hover {
  color: #00000073;
  background: #0000000a;
}

.ant-table-filter-trigger.active {
  color: #1890ff;
}

.ant-table-filter-dropdown {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background-color: #fff;
  border-radius: 2px;
  min-width: 120px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  box-shadow: none;
  border: 0;
  max-height: 264px;
  overflow-x: hidden;
}

.ant-table-filter-dropdown .ant-dropdown-menu:empty:after {
  color: #00000040;
  text-align: center;
  content: "Not Found";
  padding: 8px 0;
  font-size: 12px;
  display: block;
}

.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}

.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper, .ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #bae7ff;
}

.ant-table-filter-dropdown-search {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px;
}

.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}

.ant-table-filter-dropdown-search-input .anticon {
  color: #00000040;
}

.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}

.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}

.ant-table-filter-dropdown-btns {
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
  justify-content: space-between;
  padding: 7px 8px;
  display: flex;
  overflow: hidden;
}

.ant-table-selection-col {
  width: 32px;
}

.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}

table tr th.ant-table-selection-column, table tr td.ant-table-selection-column {
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
}

table tr th.ant-table-selection-column .ant-radio-wrapper, table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}

table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}

table tr th.ant-table-selection-column:after {
  background-color: #0000 !important;
}

.ant-table-selection {
  flex-direction: column;
  display: inline-flex;
  position: relative;
}

.ant-table-selection-extra {
  z-index: 1;
  cursor: pointer;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
  transition: all .3s;
  position: absolute;
  top: 0;
}

.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}

.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}

.ant-table-expand-icon-col {
  width: 48px;
}

.ant-table-row-expand-icon-cell {
  text-align: center;
}

.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  float: none;
  vertical-align: sub;
  display: inline-flex;
}

.ant-table-row-indent {
  float: left;
  height: 1px;
}

.ant-table-row-expand-icon {
  color: inherit;
  cursor: pointer;
  float: left;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  width: 17px;
  height: 17px;
  padding: 0;
  line-height: 17px;
  transition: all .3s;
  position: relative;
  transform: scale(.941177);
}

.ant-table-row-expand-icon:focus-visible, .ant-table-row-expand-icon:hover {
  color: #40a9ff;
}

.ant-table-row-expand-icon:active {
  color: #096dd9;
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover, .ant-table-row-expand-icon:active {
  border-color: currentColor;
}

.ant-table-row-expand-icon:before, .ant-table-row-expand-icon:after {
  content: "";
  background: currentColor;
  transition: transform .3s ease-out;
  position: absolute;
}

.ant-table-row-expand-icon:before {
  height: 1px;
  top: 7px;
  left: 3px;
  right: 3px;
}

.ant-table-row-expand-icon:after {
  width: 1px;
  top: 3px;
  bottom: 3px;
  left: 7px;
  transform: rotate(90deg);
}

.ant-table-row-expand-icon-collapsed:before {
  transform: rotate(-180deg);
}

.ant-table-row-expand-icon-collapsed:after {
  transform: rotate(0);
}

.ant-table-row-expand-icon-spaced {
  visibility: hidden;
  background: none;
  border: 0;
}

.ant-table-row-expand-icon-spaced:before, .ant-table-row-expand-icon-spaced:after {
  content: none;
  display: none;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}

tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}

tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}

tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}

.ant-table .ant-table-expanded-row-fixed {
  margin: -16px;
  padding: 16px;
  position: relative;
}

.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: #00000040;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}

.ant-table-cell-fix-left, .ant-table-cell-fix-right {
  z-index: 2;
  background: #fff;
  position: sticky !important;
}

.ant-table-cell-fix-left-first:after, .ant-table-cell-fix-left-last:after {
  content: "";
  pointer-events: none;
  width: 30px;
  transition: box-shadow .3s;
  position: absolute;
  top: 0;
  bottom: -1px;
  right: 0;
  transform: translateX(100%);
}

.ant-table-cell-fix-left-all:after {
  display: none;
}

.ant-table-cell-fix-right-first:after, .ant-table-cell-fix-right-last:after {
  content: "";
  pointer-events: none;
  width: 30px;
  transition: box-shadow .3s;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  transform: translateX(-100%);
}

.ant-table .ant-table-container:before, .ant-table .ant-table-container:after {
  z-index: calc(calc(2 + 1)  + 1);
  content: "";
  pointer-events: none;
  width: 30px;
  transition: box-shadow .3s;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ant-table .ant-table-container:before {
  left: 0;
}

.ant-table .ant-table-container:after {
  right: 0;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
  position: relative;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container:before, .ant-table-ping-left .ant-table-cell-fix-left-first:after, .ant-table-ping-left .ant-table-cell-fix-left-last:after {
  box-shadow: inset 10px 0 8px -8px #00000026;
}

.ant-table-ping-left .ant-table-cell-fix-left-last:before {
  background-color: #0000 !important;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container {
  position: relative;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container:after, .ant-table-ping-right .ant-table-cell-fix-right-first:after, .ant-table-ping-right .ant-table-cell-fix-right-last:after {
  box-shadow: inset -10px 0 8px -8px #00000026;
}

.ant-table-sticky-holder {
  z-index: calc(2 + 1);
  background: #fff;
  position: sticky;
}

.ant-table-sticky-scroll {
  z-index: calc(2 + 1);
  opacity: .6;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  align-items: center;
  display: flex;
  position: sticky;
  bottom: 0;
}

.ant-table-sticky-scroll:hover {
  transform-origin: bottom;
}

.ant-table-sticky-scroll-bar {
  background-color: #00000059;
  border-radius: 4px;
  height: 8px;
}

.ant-table-sticky-scroll-bar:hover, .ant-table-sticky-scroll-bar-active {
  background-color: #000c;
}

@media (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last:after, .ant-table-ping-right .ant-table-cell-fix-right-first:after {
    box-shadow: none !important;
  }
}

.ant-table-title {
  border-radius: 2px 2px 0 0;
}

.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ant-table-title + .ant-table-container table, .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child, .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}

.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}

.ant-table-footer {
  border-radius: 0 0 2px 2px;
}

.ant-table-wrapper-rtl, .ant-table-rtl {
  direction: rtl;
}

.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
  text-align: center;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  left: 0;
  right: auto;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}

.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}

.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-left: 0;
  margin-right: 4px;
}

.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}

.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}

.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}

.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span, .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span, .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 0;
  padding-right: 8px;
}

.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}

.ant-table-wrapper-rtl .ant-table-row-indent, .ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}

.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-left: 8px;
  margin-right: 0;
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon:after {
  transform: rotate(-90deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:before {
  transform: rotate(180deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:after {
  transform: rotate(0);
}

@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:before {
  content: "";
  pointer-events: none;
  transition: background-color .3s;
  position: absolute;
  inset: 0 0 4px;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:hover:before {
  background: #f5f5f5;
}

.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color .3s;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: none;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: none;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover:before, .ant-tree.ant-tree-directory .ant-tree-treenode-selected:before {
  background: #1890ff;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: none;
}

.ant-tree-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-tree-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-tree-checkbox:hover:after, .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox:after {
  visibility: visible;
}

.ant-tree-checkbox-inner {
  direction: ltr;
  border-collapse: separate;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-tree-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-tree-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}

.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-tree-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-tree-checkbox-disabled:hover:after, .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled:after {
  visibility: hidden;
}

.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-tree-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}

.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-tree-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-tree-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-tree-checkbox-group-item {
  margin-right: 8px;
}

.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-tree {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: background-color .3s;
}

.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e6f7ff;
}

.ant-tree-list-holder-inner {
  align-items: flex-start;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging:after {
  opacity: 0;
  content: "";
  pointer-events: none;
  border: 1px solid #1890ff;
  animation: .3s forwards ant-tree-node-fx-do-not-use;
  position: absolute;
  inset: 0 0 4px;
}

.ant-tree .ant-tree-treenode {
  outline: none;
  align-items: flex-start;
  padding: 0 0 4px;
  display: flex;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: #00000040;
  cursor: not-allowed;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: none;
}

.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}

.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}

.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  text-align: center;
  visibility: visible;
  opacity: .2;
  width: 24px;
  line-height: 24px;
  transition: opacity .3s;
}

.ant-tree-treenode:hover .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  opacity: .45;
}

.ant-tree .ant-tree-treenode-draggable.ant-tree-treenode-disabled .ant-tree-draggable-icon {
  visibility: hidden;
}

.ant-tree-indent {
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  align-self: stretch;
}

.ant-tree-indent-unit {
  width: 24px;
  display: inline-block;
}

.ant-tree-draggable-icon {
  visibility: hidden;
}

.ant-tree-switcher {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  position: relative;
}

.ant-tree-switcher .ant-tree-switcher-icon, .ant-tree-switcher .ant-select-tree-switcher-icon {
  vertical-align: baseline;
  font-size: 10px;
  display: inline-block;
}

.ant-tree-switcher .ant-tree-switcher-icon svg, .ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform .3s;
}

.ant-tree-switcher-noop {
  cursor: default;
}

.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}

.ant-tree-switcher-loading-icon {
  color: #1890ff;
}

.ant-tree-switcher-leaf-line {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.ant-tree-switcher-leaf-line:before {
  content: " ";
  border-right: 1px solid #d9d9d9;
  margin-left: -1px;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-tree-switcher-leaf-line:after {
  content: " ";
  border-bottom: 1px solid #d9d9d9;
  width: 10px;
  height: 14px;
  position: absolute;
}

.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}

.ant-tree .ant-tree-node-content-wrapper {
  z-index: auto;
  color: inherit;
  cursor: pointer;
  background: none;
  border-radius: 2px;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  line-height: 24px;
  transition: all .3s, border, line-height, box-shadow;
  position: relative;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  text-align: center;
  vertical-align: top;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}

.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: #0000;
}

.ant-tree-node-content-wrapper {
  -webkit-user-select: none;
  user-select: none;
  line-height: 24px;
}

.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  z-index: 1;
  pointer-events: none;
  background-color: #1890ff;
  border-radius: 1px;
  height: 2px;
  position: absolute;
}

.ant-tree-node-content-wrapper .ant-tree-drop-indicator:after {
  content: "";
  background-color: #0000;
  border: 2px solid #1890ff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -3px;
  left: -6px;
}

.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #1890ff;
}

.ant-tree-show-line .ant-tree-indent-unit {
  height: 100%;
  position: relative;
}

.ant-tree-show-line .ant-tree-indent-unit:before {
  content: "";
  border-right: 1px solid #d9d9d9;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-tree-show-line .ant-tree-indent-unit-end:before {
  display: none;
}

.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}

.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -.15em;
}

.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
  height: 14px !important;
  top: auto !important;
  bottom: auto !important;
}

.ant-tree-rtl {
  direction: rtl;
}

.ant-tree-rtl .ant-tree-node-content-wrapper[draggable="true"] .ant-tree-drop-indicator:after {
  right: -6px;
  left: unset;
}

.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}

.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}

.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit:before {
  border-left: 1px solid #d9d9d9;
  border-right: none;
  left: -13px;
  right: auto;
}

.ant-tree-rtl .ant-tree-checkbox, .ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}

.ant-timeline {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-timeline-item {
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
  position: relative;
}

.ant-timeline-item-tail {
  border-left: 2px solid #f0f0f0;
  height: calc(100% - 10px);
  position: absolute;
  top: 10px;
  left: 4px;
}

.ant-timeline-item-pending .ant-timeline-item-head {
  background-color: #0000;
  font-size: 12px;
}

.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}

.ant-timeline-item-head {
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
}

.ant-timeline-item-head-blue {
  color: #1890ff;
  border-color: #1890ff;
}

.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}

.ant-timeline-item-head-gray {
  color: #00000040;
  border-color: #00000040;
}

.ant-timeline-item-head-custom {
  text-align: center;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  position: absolute;
  top: 5.5px;
  left: 5px;
  transform: translate(-50%, -50%);
}

.ant-timeline-item-content {
  word-break: break-word;
  margin: 0 0 0 26px;
  position: relative;
  top: -7.001px;
}

.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  text-align: left;
  width: calc(50% - 14px);
  left: calc(50% - 4px);
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: right;
  width: calc(50% - 12px);
  margin: 0;
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail, .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head, .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 6px);
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}

.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-left: 2px dotted #f0f0f0;
  height: calc(100% - 14px);
  display: block;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-left: 2px dotted #f0f0f0;
  height: calc(100% - 15px);
  display: block;
  top: 15px;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: right;
  width: calc(50% - 12px);
  position: absolute;
  top: -7.001px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  text-align: left;
  width: calc(50% - 14px);
  left: calc(50% + 14px);
}

.ant-timeline-rtl {
  direction: rtl;
}

.ant-timeline-rtl .ant-timeline-item-tail {
  border-left: none;
  border-right: 2px solid #f0f0f0;
  left: auto;
  right: 4px;
}

.ant-timeline-rtl .ant-timeline-item-head-custom {
  left: auto;
  right: 5px;
  transform: translate(50%, -50%);
}

.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: auto;
  right: 50%;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: 0;
  margin-right: -4px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 0;
  margin-right: 1px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  text-align: right;
  left: auto;
  right: calc(50% - 4px);
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head, .ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: auto;
  right: 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  text-align: right;
  width: 100%;
  margin-right: 18px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail, .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-left: none;
  border-right: 2px dotted #f0f0f0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  text-align: right;
  right: calc(50% + 14px);
}

.ant-transfer-customize-list .ant-transfer-list {
  flex: 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}

.ant-transfer-customize-list .ant-input[disabled] {
  background-color: #0000;
}

.ant-transfer-status-error .ant-transfer-list {
  border-color: #ff4d4f;
}

.ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}

.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-transfer-status-warning .ant-transfer-list {
  border-color: #faad14;
}

.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}

.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 2px #1890ff33;
}

.ant-transfer {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  align-items: stretch;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
  position: relative;
}

.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}

.ant-transfer-list {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  flex-direction: column;
  width: 180px;
  height: 200px;
  display: flex;
}

.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}

.ant-transfer-list-search .anticon-search {
  color: #00000040;
}

.ant-transfer-list-header {
  color: #000000d9;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  display: flex;
}

.ant-transfer-list-header > :not(:last-child) {
  margin-right: 4px;
}

.ant-transfer-list-header > * {
  flex: none;
}

.ant-transfer-list-header-title {
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
  flex: auto;
  overflow: hidden;
}

.ant-transfer-list-header-dropdown {
  cursor: pointer;
  font-size: 10px;
  transform: translateY(10%);
}

.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}

.ant-transfer-list-body {
  flex-direction: column;
  flex: auto;
  font-size: 14px;
  display: flex;
  overflow: hidden;
}

.ant-transfer-list-body-search-wrapper {
  flex: none;
  padding: 12px;
  position: relative;
}

.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}

.ant-transfer-list-content-item {
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all .3s;
  display: flex;
}

.ant-transfer-list-content-item > :not(:last-child) {
  margin-right: 8px;
}

.ant-transfer-list-content-item > * {
  flex: none;
}

.ant-transfer-list-content-item-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: auto;
  overflow: hidden;
}

.ant-transfer-list-content-item-remove {
  color: #d9d9d9;
  cursor: pointer;
  transition: all .3s;
  position: relative;
}

.ant-transfer-list-content-item-remove:hover {
  color: #40a9ff;
}

.ant-transfer-list-content-item-remove:after {
  content: "";
  position: absolute;
  inset: -6px -50%;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #dcf4ff;
}

.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  cursor: default;
  background: none;
}

.ant-transfer-list-content-item-checked {
  background-color: #e6f7ff;
}

.ant-transfer-list-content-item-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-transfer-list-pagination {
  text-align: right;
  border-top: 1px solid #f0f0f0;
  padding: 8px 0;
}

.ant-transfer-list-body-not-found {
  color: #00000040;
  text-align: center;
  flex: none;
  width: 100%;
  margin: auto 0;
}

.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-transfer-operation {
  vertical-align: middle;
  flex-direction: column;
  flex: none;
  align-self: center;
  margin: 0 8px;
  display: flex;
}

.ant-transfer-operation .ant-btn {
  display: block;
}

.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}

.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}

.ant-transfer .ant-empty-image {
  max-height: -2px;
}

.ant-transfer-rtl {
  direction: rtl;
}

.ant-transfer-rtl .ant-transfer-list-search {
  padding-left: 24px;
  padding-right: 8px;
}

.ant-transfer-rtl .ant-transfer-list-search-action {
  left: 12px;
  right: auto;
}

.ant-transfer-rtl .ant-transfer-list-header > :not(:last-child) {
  margin-left: 4px;
  margin-right: 0;
}

.ant-transfer-rtl .ant-transfer-list-header {
  left: auto;
  right: 0;
}

.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-content-item > :not(:last-child) {
  margin-left: 8px;
  margin-right: 0;
}

.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-footer {
  left: auto;
  right: 0;
}

.ant-select-tree-checkbox {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  position: relative;
  top: .2em;
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner, .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner, .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}

.ant-select-tree-checkbox-checked:after {
  visibility: hidden;
  content: "";
  border: 1px solid #1890ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  animation: .36s ease-in-out backwards antCheckboxEffect;
  position: absolute;
  top: 0;
  left: 0;
}

.ant-select-tree-checkbox:hover:after, .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox:after {
  visibility: visible;
}

.ant-select-tree-checkbox-inner {
  direction: ltr;
  border-collapse: separate;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.ant-select-tree-checkbox-inner:after {
  opacity: 0;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  width: 5.71429px;
  height: 9.14286px;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  display: table;
  position: absolute;
  top: 50%;
  left: 21.5%;
  transform: rotate(45deg)scale(0)translate(-50%, -50%);
}

.ant-select-tree-checkbox-input {
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  display: table;
  position: absolute;
  transform: rotate(45deg)scale(1)translate(-50%, -50%);
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  border-color: #00000040;
  animation-name: none;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
  border-collapse: separate;
  border-color: #f5f5f5;
  animation-name: none;
}

.ant-select-tree-checkbox-disabled + span {
  color: #00000040;
  cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled:hover:after, .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled:after {
  visibility: hidden;
}

.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  font-size: 14px;
  line-height: 1.5715;
  line-height: unset;
  cursor: pointer;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.ant-select-tree-checkbox-wrapper:after {
  content: " ";
  width: 0;
  display: inline-block;
  overflow: hidden;
}

.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}

.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.ant-select-tree-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
}

.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: inline-block;
}

.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}

.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
  opacity: 1;
  content: " ";
  background-color: #1890ff;
  border: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
  background-color: #00000040;
  border-color: #00000040;
}

.ant-tree-select-dropdown {
  padding: 8px 4px;
}

.ant-tree-select-dropdown-rtl {
  direction: rtl;
}

.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}

.ant-select-tree {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  background: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  transition: background-color .3s;
}

.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #e6f7ff;
}

.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging:after {
  opacity: 0;
  content: "";
  pointer-events: none;
  border: 1px solid #1890ff;
  animation: .3s forwards ant-tree-node-fx-do-not-use;
  position: absolute;
  inset: 0 0 4px;
}

.ant-select-tree .ant-select-tree-treenode {
  outline: none;
  align-items: flex-start;
  padding: 0 0 4px;
  display: flex;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: #00000040;
  cursor: not-allowed;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: none;
}

.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}

.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}

.ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  text-align: center;
  visibility: visible;
  opacity: .2;
  width: 24px;
  line-height: 24px;
  transition: opacity .3s;
}

.ant-select-tree-treenode:hover .ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  opacity: .45;
}

.ant-select-tree .ant-select-tree-treenode-draggable.ant-select-tree-treenode-disabled .ant-select-tree-draggable-icon {
  visibility: hidden;
}

.ant-select-tree-indent {
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  align-self: stretch;
}

.ant-select-tree-indent-unit {
  width: 24px;
  display: inline-block;
}

.ant-select-tree-draggable-icon {
  visibility: hidden;
}

.ant-select-tree-switcher {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  position: relative;
}

.ant-select-tree-switcher .ant-tree-switcher-icon, .ant-select-tree-switcher .ant-select-tree-switcher-icon {
  vertical-align: baseline;
  font-size: 10px;
  display: inline-block;
}

.ant-select-tree-switcher .ant-tree-switcher-icon svg, .ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform .3s;
}

.ant-select-tree-switcher-noop {
  cursor: default;
}

.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}

.ant-select-tree-switcher-loading-icon {
  color: #1890ff;
}

.ant-select-tree-switcher-leaf-line {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.ant-select-tree-switcher-leaf-line:before {
  content: " ";
  border-right: 1px solid #d9d9d9;
  margin-left: -1px;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-select-tree-switcher-leaf-line:after {
  content: " ";
  border-bottom: 1px solid #d9d9d9;
  width: 10px;
  height: 14px;
  position: absolute;
}

.ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  z-index: auto;
  color: inherit;
  cursor: pointer;
  background: none;
  border-radius: 2px;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  line-height: 24px;
  transition: all .3s, border, line-height, box-shadow;
  position: relative;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  text-align: center;
  vertical-align: top;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}

.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: #0000;
}

.ant-select-tree-node-content-wrapper {
  -webkit-user-select: none;
  user-select: none;
  line-height: 24px;
}

.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  z-index: 1;
  pointer-events: none;
  background-color: #1890ff;
  border-radius: 1px;
  height: 2px;
  position: absolute;
}

.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator:after {
  content: "";
  background-color: #0000;
  border: 2px solid #1890ff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -3px;
  left: -6px;
}

.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #1890ff;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit {
  height: 100%;
  position: relative;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit:before {
  content: "";
  border-right: 1px solid #d9d9d9;
  position: absolute;
  top: 0;
  bottom: -4px;
  right: 12px;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit-end:before {
  display: none;
}

.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}

.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -.15em;
}

.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line:before {
  height: 14px !important;
  top: auto !important;
  bottom: auto !important;
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}

.ant-typography {
  color: #000000d9;
  word-break: break-word;
}

.ant-typography.ant-typography-secondary {
  color: #00000073;
}

.ant-typography.ant-typography-success {
  color: #52c41a;
}

.ant-typography.ant-typography-warning {
  color: #faad14;
}

.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}

a.ant-typography.ant-typography-danger:active, a.ant-typography.ant-typography-danger:focus {
  color: #d9363e;
}

a.ant-typography.ant-typography-danger:hover {
  color: #ff7875;
}

.ant-typography.ant-typography-disabled {
  color: #00000040;
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}

div.ant-typography, .ant-typography p {
  margin-bottom: 1em;
}

h1.ant-typography, div.ant-typography-h1, div.ant-typography-h1 > textarea, .ant-typography h1 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.23;
}

h2.ant-typography, div.ant-typography-h2, div.ant-typography-h2 > textarea, .ant-typography h2 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.35;
}

h3.ant-typography, div.ant-typography-h3, div.ant-typography-h3 > textarea, .ant-typography h3 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.35;
}

h4.ant-typography, div.ant-typography-h4, div.ant-typography-h4 > textarea, .ant-typography h4 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}

h5.ant-typography, div.ant-typography-h5, div.ant-typography-h5 > textarea, .ant-typography h5 {
  color: #000000d9;
  margin-bottom: .5em;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.ant-typography + h1.ant-typography, .ant-typography + h2.ant-typography, .ant-typography + h3.ant-typography, .ant-typography + h4.ant-typography, .ant-typography + h5.ant-typography, .ant-typography div + h1, .ant-typography ul + h1, .ant-typography li + h1, .ant-typography p + h1, .ant-typography h1 + h1, .ant-typography h2 + h1, .ant-typography h3 + h1, .ant-typography h4 + h1, .ant-typography h5 + h1, .ant-typography div + h2, .ant-typography ul + h2, .ant-typography li + h2, .ant-typography p + h2, .ant-typography h1 + h2, .ant-typography h2 + h2, .ant-typography h3 + h2, .ant-typography h4 + h2, .ant-typography h5 + h2, .ant-typography div + h3, .ant-typography ul + h3, .ant-typography li + h3, .ant-typography p + h3, .ant-typography h1 + h3, .ant-typography h2 + h3, .ant-typography h3 + h3, .ant-typography h4 + h3, .ant-typography h5 + h3, .ant-typography div + h4, .ant-typography ul + h4, .ant-typography li + h4, .ant-typography p + h4, .ant-typography h1 + h4, .ant-typography h2 + h4, .ant-typography h3 + h4, .ant-typography h4 + h4, .ant-typography h5 + h4, .ant-typography div + h5, .ant-typography ul + h5, .ant-typography li + h5, .ant-typography p + h5, .ant-typography h1 + h5, .ant-typography h2 + h5, .ant-typography h3 + h5, .ant-typography h4 + h5, .ant-typography h5 + h5 {
  margin-top: 1.2em;
}

a.ant-typography-ellipsis, span.ant-typography-ellipsis {
  max-width: 100%;
  display: inline-block;
}

a.ant-typography, .ant-typography a {
  color: #1890ff;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color .3s;
}

a.ant-typography:focus-visible, .ant-typography a:focus-visible, a.ant-typography:hover, .ant-typography a:hover {
  color: #40a9ff;
}

a.ant-typography:active, .ant-typography a:active {
  color: #096dd9;
}

a.ant-typography:active, .ant-typography a:active, a.ant-typography:hover, .ant-typography a:hover {
  text-decoration: none;
}

a.ant-typography[disabled], .ant-typography a[disabled], a.ant-typography.ant-typography-disabled, .ant-typography a.ant-typography-disabled {
  color: #00000040;
  cursor: not-allowed;
}

a.ant-typography[disabled]:active, .ant-typography a[disabled]:active, a.ant-typography.ant-typography-disabled:active, .ant-typography a.ant-typography-disabled:active, a.ant-typography[disabled]:hover, .ant-typography a[disabled]:hover, a.ant-typography.ant-typography-disabled:hover, .ant-typography a.ant-typography-disabled:hover {
  color: #00000040;
}

a.ant-typography[disabled]:active, .ant-typography a[disabled]:active, a.ant-typography.ant-typography-disabled:active, .ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}

.ant-typography code {
  background: #9696961a;
  border: 1px solid #64646433;
  border-radius: 3px;
  margin: 0 .2em;
  padding: .2em .4em .1em;
  font-size: 85%;
}

.ant-typography kbd {
  background: #9696960f;
  border: 1px solid #64646433;
  border-bottom-width: 2px;
  border-radius: 3px;
  margin: 0 .2em;
  padding: .15em .4em .1em;
  font-size: 90%;
}

.ant-typography mark {
  background-color: #ffe58f;
  padding: 0;
}

.ant-typography u, .ant-typography ins {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  text-decoration: underline;
}

.ant-typography s, .ant-typography del {
  text-decoration: line-through;
}

.ant-typography strong {
  font-weight: 600;
}

.ant-typography-expand, .ant-typography-edit, .ant-typography-copy {
  color: #1890ff;
  cursor: pointer;
  outline: none;
  margin-left: 4px;
  transition: color .3s;
}

.ant-typography-expand:focus-visible, .ant-typography-edit:focus-visible, .ant-typography-copy:focus-visible, .ant-typography-expand:hover, .ant-typography-edit:hover, .ant-typography-copy:hover {
  color: #40a9ff;
}

.ant-typography-expand:active, .ant-typography-edit:active, .ant-typography-copy:active {
  color: #096dd9;
}

.ant-typography-copy-success, .ant-typography-copy-success:hover, .ant-typography-copy-success:focus {
  color: #52c41a;
}

.ant-typography-edit-content {
  position: relative;
}

div.ant-typography-edit-content {
  margin-top: -5px;
  margin-bottom: calc(1em - 5px);
  left: -12px;
}

.ant-typography-edit-content-confirm {
  color: #00000073;
  pointer-events: none;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  position: absolute;
  bottom: 8px;
  right: 10px;
}

.ant-typography-edit-content textarea {
  height: 1em;
  -moz-transition: none;
  margin: 0 !important;
}

.ant-typography ul, .ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}

.ant-typography ul li, .ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}

.ant-typography ul {
  list-style-type: circle;
}

.ant-typography ul ul {
  list-style-type: disc;
}

.ant-typography ol {
  list-style-type: decimal;
}

.ant-typography pre, .ant-typography blockquote {
  margin: 1em 0;
}

.ant-typography pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #9696961a;
  border: 1px solid #64646433;
  border-radius: 3px;
  padding: .4em .6em;
}

.ant-typography pre code {
  font-size: inherit;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  display: inline;
}

.ant-typography blockquote {
  opacity: .85;
  border-left: 4px solid #64646433;
  padding: 0 0 0 .6em;
}

.ant-typography-single-line {
  white-space: nowrap;
}

.ant-typography-ellipsis-single-line {
  text-overflow: ellipsis;
  overflow: hidden;
}

a.ant-typography-ellipsis-single-line, span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}

.ant-typography-ellipsis-multiple-line {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.ant-typography-rtl {
  direction: rtl;
}

.ant-typography-rtl .ant-typography-expand, .ant-typography-rtl .ant-typography-edit, .ant-typography-rtl .ant-typography-copy {
  margin-left: 0;
  margin-right: 4px;
}

.ant-typography-rtl .ant-typography-expand {
  float: left;
}

div.ant-typography-edit-content.ant-typography-rtl {
  left: auto;
  right: -12px;
}

.ant-typography-rtl .ant-typography-edit-content-confirm {
  left: 10px;
  right: auto;
}

.ant-typography-rtl.ant-typography ul li, .ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}

.ant-upload {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-upload p {
  margin: 0;
}

.ant-upload-btn {
  outline: none;
  width: 100%;
  display: block;
}

.ant-upload input[type="file"] {
  cursor: pointer;
}

.ant-upload.ant-upload-select {
  display: inline-block;
}

.ant-upload.ant-upload-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-upload.ant-upload-select-picture-card {
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  width: 104px;
  height: 104px;
  margin-bottom: 8px;
  margin-right: 8px;
  transition: border-color .3s;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}

.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}

.ant-upload.ant-upload-drag {
  text-align: center;
  cursor: pointer;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  transition: border-color .3s;
  position: relative;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}

.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  height: 100%;
  display: table;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  vertical-align: middle;
  display: table-cell;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  color: #000000d9;
  margin: 0 0 4px;
  font-size: 16px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #00000073;
  font-size: 14px;
}

.ant-upload.ant-upload-drag .anticon-plus {
  color: #00000040;
  font-size: 30px;
  transition: all .3s;
}

.ant-upload.ant-upload-drag .anticon-plus:hover, .ant-upload.ant-upload-drag:hover .anticon-plus {
  color: #00000073;
}

.ant-upload-picture-card-wrapper {
  width: 100%;
  display: inline-block;
}

.ant-upload-picture-card-wrapper:before {
  content: "";
  display: table;
}

.ant-upload-picture-card-wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.ant-upload-list {
  box-sizing: border-box;
  color: #000000d9;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
}

.ant-upload-list:before {
  content: "";
  display: table;
}

.ant-upload-list:after {
  clear: both;
  content: "";
  display: table;
}

.ant-upload-list-item {
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
  position: relative;
}

.ant-upload-list-item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  padding-left: 22px;
  line-height: 1.5715;
  display: inline-block;
  overflow: hidden;
}

.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  vertical-align: top;
  height: 22.001px;
  line-height: 1;
}

.ant-upload-list-item-card-actions.picture {
  line-height: 0;
  top: 22px;
}

.ant-upload-list-item-card-actions-btn:focus, .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-card-actions .anticon {
  color: #00000073;
  transition: all .3s;
}

.ant-upload-list-item-card-actions:hover .anticon {
  color: #000000d9;
}

.ant-upload-list-item-info {
  height: 100%;
  transition: background-color .3s;
}

.ant-upload-list-item-info > span {
  width: 100%;
  height: 100%;
  display: block;
}

.ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
  color: #00000073;
  font-size: 14px;
  position: absolute;
  top: 5px;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}

.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name, .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-progress {
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
  position: absolute;
  bottom: -12px;
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 66px;
  padding: 8px;
  position: relative;
}

.ant-upload-list-picture .ant-upload-list-item:hover, .ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: none;
}

.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: none;
}

.ant-upload-list-picture .ant-upload-list-item-uploading, .ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  text-align: center;
  opacity: .8;
  width: 48px;
  height: 48px;
  line-height: 60px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"] {
  fill: #fff2f0;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"] {
  fill: #ff4d4f;
}

.ant-upload-list-picture .ant-upload-list-item-icon, .ant-upload-list-picture-card .ant-upload-list-item-icon {
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-upload-list-picture .ant-upload-list-item-icon .anticon, .ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-image, .ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 48px;
  height: 48px;
  display: block;
  overflow: hidden;
}

.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-name {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-left: 48px;
  padding-right: 8px;
  line-height: 44px;
  transition: all .3s;
  display: inline-block;
  overflow: hidden;
}

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}

.ant-upload-list-picture .ant-upload-list-item-progress, .ant-upload-list-picture-card .ant-upload-list-item-progress {
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
  bottom: 14px;
}

.ant-upload-list-picture-card-container {
  vertical-align: top;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  display: inline-block;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before {
  z-index: 1;
  opacity: 0;
  content: " ";
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: absolute;
}

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  color: #ffffffd9;
  cursor: pointer;
  width: 16px;
  margin: 0 4px;
  font-size: 16px;
  transition: all .3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover, .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions, .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
  position: static;
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
  text-align: center;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  display: block;
  position: absolute;
  bottom: 10px;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before, .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye, .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-progress {
  width: calc(100% - 14px);
  padding-left: 0;
  bottom: 32px;
}

.ant-upload-list-text-container, .ant-upload-list-picture-container {
  transition: opacity .3s, height .3s;
}

.ant-upload-list-text-container:before, .ant-upload-list-picture-container:before {
  content: "";
  width: 0;
  height: 0;
  display: table;
}

.ant-upload-list-text-container .ant-upload-span, .ant-upload-list-picture-container .ant-upload-span {
  flex: auto;
  display: block;
}

.ant-upload-list-text .ant-upload-span, .ant-upload-list-picture .ant-upload-span {
  align-items: center;
  display: flex;
}

.ant-upload-list-text .ant-upload-span > *, .ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}

.ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}

.ant-upload-list-text .ant-upload-list-item-card-actions, .ant-upload-list-picture .ant-upload-list-item-card-actions, .ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}

.ant-upload-list .ant-upload-animate-inline-appear, .ant-upload-list .ant-upload-animate-inline-enter, .ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.78, .14, .15, .86);
  animation-fill-mode: forwards;
}

.ant-upload-list .ant-upload-animate-inline-appear, .ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}

.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}

@keyframes uploadAnimateInlineIn {
  from {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}

@keyframes uploadAnimateInlineOut {
  to {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
}

.ant-upload-rtl {
  direction: rtl;
}

.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-left: 8px;
  margin-right: auto;
}

.ant-upload-list-rtl {
  direction: rtl;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
  padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-left: 28px;
  padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-left: 0;
  padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  left: 0;
  right: auto;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-left: 5px;
  padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}

.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-left: 5px;
  padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-left: 0;
  padding-right: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-info, .ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  left: auto;
  right: 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  left: auto;
  right: 50%;
  transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-left: 8px;
  padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-left: 18px;
  padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-left: 36px;
  padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress, .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-left: 0;
  padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  left: auto;
  right: 50%;
  transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}

:root, [data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem #00000026;
  --bs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --bs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #0d6efd40;
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: #dee2e6bf;
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: #dee2e680;
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: #ffffff26;
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*, :after, :before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  .h1, h1 {
    font-size: 2.5rem;
  }
}

.h2, h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  .h2, h2 {
    font-size: 2rem;
  }
}

.h3, h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  .h3, h3 {
    font-size: 1.75rem;
  }
}

.h4, h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  .h4, h4 {
    font-size: 1.5rem;
  }
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

.small, small {
  font-size: .875em;
}

.mark, mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

:is(::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field) {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--bs-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), .05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), .1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), .075);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + var(--bs-border-width));
  padding-bottom: calc(.375rem + var(--bs-border-width));
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + var(--bs-border-width));
  padding-bottom: calc(.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--bs-border-width));
  padding-bottom: calc(.25rem + var(--bs-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--bs-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: .75rem;
  margin: -.375rem -.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  -webkit-margin-end: .75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--bs-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  color: var(--bs-body-color);
  border: solid #0000;
  border-width: var(--bs-border-width) 0;
  background-color: #0000;
  width: 100%;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
  width: 3rem;
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  border-radius: var(--bs-border-radius-sm);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: var(--bs-border-radius-lg);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--bs-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control-plaintext::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control-plaintext::placeholder, .form-floating > .form-control::placeholder {
  color: #0000;
}

:-webkit-any(.form-floating > .form-control-plaintext:not(:placeholder-shown), .form-floating > .form-control:not(:placeholder-shown)), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control-plaintext:-webkit-autofill, .form-floating > .form-control:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

:-moz-any(.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown), .form-floating > .form-control:not(:-moz-placeholder-shown)), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), :-moz-any(.form-floating > .form-control-plaintext:autofill, .form-floating > .form-control:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

:is(.form-floating > .form-control-plaintext:not(:placeholder-shown), .form-floating > .form-control:not(:placeholder-shown)), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), :is(.form-floating > .form-control-plaintext:autofill, .form-floating > .form-control:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > .form-control:disabled ~ label, .form-floating > :disabled ~ label {
  color: #6c757d;
}

.form-floating > .form-control:disabled ~ label:after, .form-floating > :disabled ~ label:after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-floating, .input-group > .form-select {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-floating:focus-within, .input-group > .form-select:focus {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: var(--bs-form-valid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: var(--bs-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: var(--bs-form-valid-border-color);
}

.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.form-control-color.is-valid, .was-validated .form-control-color:valid {
  width: calc(1.5em + 3.75rem);
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: var(--bs-form-valid-border-color);
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: var(--bs-form-valid-color);
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.input-group > .form-control:not(:focus).is-valid, .input-group > .form-floating:not(:focus-within).is-valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .was-validated .input-group > .form-select:not(:focus):valid {
  z-index: 3;
}

.invalid-feedback {
  color: var(--bs-form-invalid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.form-control-color.is-invalid, .was-validated .form-control-color:invalid {
  width: calc(1.5em + 3.75rem);
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.input-group > .form-control:not(:focus).is-invalid, .input-group > .form-floating:not(:focus-within).is-invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .was-validated .input-group > .form-select:not(:focus):invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn:first-child:active:focus-visible, :not(.btn-check) + .btn:active:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-group-lg > .btn, .btn-lg {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-group-sm > .btn, .btn-sm {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius)  - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  width: 100%;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: auto;
  position: relative;
}

.btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group > .btn-group:not(:first-child), .btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:focus, .nav-link:hover {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: .125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:focus, .nav-underline .nav-link:hover {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 700;
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), .8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), .3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), .15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:focus, .navbar-brand:hover {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:focus, .navbar-text a:hover {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  color: var(--bs-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.active > .page-link, .page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.disabled > .page-link, .page-link.disabled {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--bs-alert-link-color);
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress, .progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--bs-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: .5;
  --bs-btn-close-hover-opacity: .75;
  --bs-btn-close-focus-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: .25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--bs-btn-close-opacity);
  border: 0;
  border-radius: .375rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  opacity: var(--bs-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
  outline: 0;
}

.btn-close.disabled, .btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg)  - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  z-index: var(--bs-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  margin: var(--bs-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-footer, .modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-footer, .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-footer, .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-footer, .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-footer, .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-footer, .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before, .bs-tooltip-top .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before, .bs-tooltip-end .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before, .bs-tooltip-bottom .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before, .bs-tooltip-start .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg)  - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:after, .popover .popover-arrow:before {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-top > .popover-arrow:before {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .bs-popover-top > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow, .bs-popover-end > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-end > .popover-arrow:before {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .bs-popover-end > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-bottom > .popover-arrow:before {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .bs-popover-bottom > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow, .bs-popover-start > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-start > .popover-arrow:before {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .bs-popover-start > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-border, .spinner-grow {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform .3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.show:not(.hiding), .offcanvas-sm.showing {
    transform: none;
  }

  .offcanvas-sm.hiding, .offcanvas-sm.show, .offcanvas-sm.showing {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.show:not(.hiding), .offcanvas-md.showing {
    transform: none;
  }

  .offcanvas-md.hiding, .offcanvas-md.show, .offcanvas-md.showing {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.show:not(.hiding), .offcanvas-lg.showing {
    transform: none;
  }

  .offcanvas-lg.hiding, .offcanvas-lg.show, .offcanvas-lg.showing {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.show:not(.hiding), .offcanvas-xl.showing {
    transform: none;
  }

  .offcanvas-xl.hiding, .offcanvas-xl.show, .offcanvas-xl.showing {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.show:not(.hiding), .offcanvas-xxl.showing {
    transform: none;
  }

  .offcanvas-xxl.hiding, .offcanvas-xxl.show, .offcanvas-xxl.showing {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  transition: var(--bs-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.show:not(.hiding), .offcanvas.showing {
  transform: none;
}

.offcanvas.hiding, .offcanvas.show, .offcanvas.showing {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  line-height: var(--bs-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:focus, .link-primary:hover {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:focus, .link-secondary:hover {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:focus, .link-success:hover {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:focus, .link-info:hover {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:focus, .link-warning:hover {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:focus, .link-danger:hover {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:focus, .link-light:hover {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:focus, .link-dark:hover {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:focus, .link-body-emphasis:hover {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:focus-visible > .bi, .icon-link-hover:hover > .bi {
  transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption), .visually-hidden:not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--bs-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: .25rem !important;
  column-gap: .25rem !important;
}

.column-gap-2 {
  -moz-column-gap: .5rem !important;
  column-gap: .5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --bs-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --bs-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --bs-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --bs-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    -moz-column-gap: .25rem !important;
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    -moz-column-gap: .5rem !important;
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

@font-face {
  font-family: equiplight;
  src: url("Hoftype - Equip-Light.77c1f5b5.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipmedium;
  src: url("Hoftype - Equip-Medium.32a4fa0a.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipthin;
  src: url("Hoftype - Equip-Thin.c79192f0.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipextralight;
  src: url("Hoftype - Equip-ExtraLight.fb8d344e.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.41077902.css.map */
